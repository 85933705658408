import React from 'react';
import Icon from 'components/lib/Icon';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Tag from 'components/lib/Tag';
import ListItem from '../../lib/ListItem';
import { useAiSummaryDisabledMessage } from 'lib/hooks/useAI';

function GenerateSummaryButton({ title, onClick, disabled }) {
  const flags = useFlags();
  const [aiEnabled, disabledMessage, disableAllAi] = useAiSummaryDisabledMessage();

  if (!flags.enableAiSpeakerNotes || disableAllAi) {
    return null;
  } else {
    const isDisabled = !aiEnabled || disabled;
    return (
      <button
        className="flex items-center justify-start relative rounded py-1 px-4 hover:bg-grey-100 mb-1 w-full gap-1"
        onClick={onClick}
        disabled={isDisabled}
      >
        <div className="grow">
          <ListItem
            tooltip={aiEnabled ? null : disabledMessage}
            icon={<Icon name="text_grammar_wand" size={20} />}
            title={title}
            size="s"
            status={!isDisabled ? 'default' : 'disabled'}
          ></ListItem>
        </div>
        <Tag label="AI" size="xxs" color="gradient" luminosity="medium" />
      </button>
    );
  }
}

GenerateSummaryButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default GenerateSummaryButton;
