import React from 'react';
import { useNode } from '@craftjs/core';
import PropTypes from 'prop-types';
import PaddingInput from 'components/shared/PaddingInput';
import InputColorSelect from 'components/lib/InputColorSelect';

function EmailBody({ children, style }) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div ref={(dom) => connect(drag(dom))} style={style}>
      {children}
    </div>
  );
}

EmailBody.craft = {
  props: {
    style: {
      backgroundColor: '#FFFFFF',
      width: '100%',
      paddingBottom: '0px',
      paddingTop: '0px',
    },
  },
  rules: {
    canDrag: () => false,
    canDrop: () => false,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  related: {
    settings: EmailBodySettings,
  },
};

EmailBody.propTypes = {
  style: PropTypes.object,
  children: PropTypes.object,
};

function EmailBodySettings() {
  const {
    actions: { setProp },
    backgroundColor,
    paddingBottom,
    paddingTop,
  } = useNode((node) => ({
    backgroundColor: node.data.props.style.backgroundColor,
    paddingBottom: parseInt(node.data.props.style.paddingBottom),
    paddingTop: parseInt(node.data.props.style.paddingTop),
  }));

  const onColorChange = (propName) => (color) => {
    setProp((props) => {
      props.style[propName] = color;
    });
  };

  const onPaddingChange = (num, paddingProp) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[paddingProp] = `${num}px`;
    });
  };

  return (
    <div className="module-settings-container">
      <div className="module-setting">
        <label>Background Color</label>
        <InputColorSelect className="w-[112px]" value={backgroundColor} onChange={onColorChange('backgroundColor')} />
      </div>
      <div className="module-setting">
        <label>Padding</label>
        <div>
          <PaddingInput
            onPaddingChange={onPaddingChange}
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
            topBottomOnly={true}
          />
        </div>
      </div>
    </div>
  );
}

EmailBodySettings.propTypes = {
  style: PropTypes.object,
};

export default EmailBody;
