import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import CheckboxWithLabel from '../../../shared/CheckboxWithLabel';
import SSHTunnelForm from './SSHTunnelForm';

function OracleDatabaseForm({
  skipSslCheck,
  onChange,
  onAuthChange,
  selectedDataSource,
  clearSSHKey,
  database,
  auth,
  onSSHTunnelChange,
}) {
  const [showSSHTunnel, setShowSSHTunnel] = useState(!!auth?.ssh_key);

  const handleSSHTunnelChange = (e) => {
    const value = e.target.checked;
    setShowSSHTunnel(value);
    onSSHTunnelChange(value);
  };

  return (
    <React.Fragment>
      <Form.Field>
        <Form.Label htmlFor="db-database">Connection String</Form.Label>
        <Form.Control>
          <Form.Textarea
            placeholder="Oracle connection string"
            name="database"
            onChange={onChange}
            value={database}
            id="db-database"
            rows={3}
          />
        </Form.Control>
      </Form.Field>
      <SSHTunnelForm
        onAuthChange={onAuthChange}
        onChange={handleSSHTunnelChange}
        showSSHTunnel={showSSHTunnel}
        auth={auth}
        selectedDataSource={selectedDataSource}
        clearSSHKey={clearSSHKey}
      />
      <Form.Field>
        <CheckboxWithLabel
          checked={skipSslCheck}
          id="skip_ssl_check"
          label="Skip SSL Certificate Check"
          name="skipSslCheck"
          onChange={onChange}
        />
      </Form.Field>
    </React.Fragment>
  );
}

OracleDatabaseForm.propTypes = {
  skipSslCheck: PropTypes.bool,
  database: PropTypes.string,
  onChange: PropTypes.func,
  selectedDataSource: PropTypes.object,
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
  onSSHTunnelChange: PropTypes.func,
  clearSSHKey: PropTypes.func,
};

export default OracleDatabaseForm;
