import React from 'react';
import PropTypes from 'prop-types';

const CategoryOptions = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  NONE: 'none',
};

const StatusOptions = {
  DISABLED: 'disabled',
  LOADING: 'loading',
  SELECTED: 'selected',
  DEFAULT: 'default',
};

const SizeOptions = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const WidthOptions = {
  FULL_WIDTH: 'fullWidth',
  HUG: 'hug',
  SQUARE: 'square', // for "Icon = Only"
};

const JustificationOptions = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

function Button({
  size,
  status,
  category,
  children,
  width,
  noPadding,
  isRounded,
  className,
  onClick,
  href,
  buttonRef,
  justification,
  isBoxShadowHidden,
  ...props
}) {
  let customClassString = '';
  let loadingBorderColor = 'border-matik-white text-matik-white';
  if (category === CategoryOptions.PRIMARY) {
    let backgroundColorClass = 'bg-matik-blue hover:bg-grey-700';
    let textClass = 'text-matik-white';
    if (status === StatusOptions.DISABLED) {
      backgroundColorClass = 'bg-grey-300';
      textClass = 'text-matik-white cursor-not-allowed';
    } else if (status === StatusOptions.SELECTED) {
      backgroundColorClass = 'bg-blue-100';
      textClass = 'text-matik-blue';
    } else if (status === StatusOptions.LOADING) {
      textClass = 'text-transparent';
    }
    customClassString = `${backgroundColorClass} ${textClass}`;
  } else if (category === CategoryOptions.SECONDARY) {
    let backgroundColorClass = 'bg-matik-white hover:bg-grey-50';
    let textClass = 'text-matik-black';
    let borderClass = `border border-grey-300 ${isBoxShadowHidden ? '' : 'shadow-s'}`;
    loadingBorderColor = 'border-matik-black text-matik-black';
    if (status === StatusOptions.DISABLED) {
      backgroundColorClass = 'bg-matik-white';
      textClass = 'text-grey-400 cursor-not-allowed';
    } else if (status === StatusOptions.SELECTED) {
      backgroundColorClass = 'bg-blue-100';
      textClass = 'text-matik-blue';
    } else if (status === StatusOptions.LOADING) {
      textClass = 'text-transparent';
    }
    customClassString = `${borderClass} ${backgroundColorClass} ${textClass}`;
  } else if (category === CategoryOptions.TERTIARY) {
    let textClass = 'text-matik-black hover:text-grey-600 focus:text-blue-600';
    if (status === StatusOptions.DISABLED) {
      textClass = 'text-grey-400 cursor-not-allowed';
    }
    customClassString = `bg-transparent ${textClass}`;
  } else if (category === CategoryOptions.NONE) {
    loadingBorderColor = 'border-matik-black text-matik-black';
    let textClass = 'text-matik-black hover:text-grey-600 focus:text-blue-600';
    if (status === StatusOptions.LOADING) {
      textClass = 'text-transparent';
    }
    customClassString = textClass;
  }

  let sizeClass = '';
  let loadingSize = 'h-4 w-4';
  if (size === SizeOptions.SMALL) {
    sizeClass = 'py-1.5 px-3';
  } else if (size === SizeOptions.MEDIUM) {
    noPadding ? (sizeClass = 'py-2') : (sizeClass = 'py-2 px-4');
  } else if (size === SizeOptions.LARGE) {
    sizeClass = 'py-4 px-8 text-2xl';
    loadingSize = 'h-8 w-8';
  }

  let widthClass = '';
  if (width === WidthOptions.FULL_WIDTH) {
    widthClass = 'w-full';
  } else if (width === WidthOptions.SQUARE) {
    sizeClass = '';
    switch (size) {
      case SizeOptions.SMALL:
        widthClass = 'h-8 w-8';
        break;
      case SizeOptions.MEDIUM:
        widthClass = 'h-10 w-10';
        break;
      case SizeOptions.LARGE:
        widthClass = 'h-14 w-14';
        break;
    }
  }

  let justificationClass = '';
  if (justification === JustificationOptions.LEFT) {
    justificationClass = 'justify-start';
  } else if (justification === JustificationOptions.RIGHT) {
    justificationClass = 'justify-end';
  } else {
    justificationClass = 'justify-center';
  }

  // Prevent additional classes from overwriting component library styles
  if (className) {
    // eslint-disable-next-line no-console
    console.warn('className prop is not supported for button library component');
  }

  const buttonClassNames = `flex items-center ${justificationClass} relative ${
    isRounded ? 'rounded-full' : 'rounded'
  } ${sizeClass} ${customClassString} ${widthClass}`;
  const childrenWrapper = (
    <div
      className={`${
        status === StatusOptions.LOADING ? 'invisible' : ''
      } flex items-center ${justificationClass} ${widthClass}`}
    >
      {children}
    </div>
  );
  const loadingComponent =
    status === StatusOptions.LOADING ? (
      <div
        className={`animate-spin absolute border-2 border-t-transparent border-r-transparent rounded-full block inset-0 m-auto ${loadingSize} ${loadingBorderColor}`}
      ></div>
    ) : null;

  if (href && status !== StatusOptions.DISABLED) {
    return (
      <a href={href} className={buttonClassNames} onClick={onClick} ref={buttonRef} {...props}>
        {childrenWrapper}
        {loadingComponent}
      </a>
    );
  } else {
    return (
      <button
        disabled={status === StatusOptions.DISABLED || status === StatusOptions.LOADING}
        className={buttonClassNames}
        onClick={onClick}
        ref={buttonRef}
        {...props}
      >
        {childrenWrapper}
        {loadingComponent}
      </button>
    );
  }
}

Button.propTypes = {
  category: PropTypes.oneOf(Object.values(CategoryOptions)),
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(SizeOptions)),
  status: PropTypes.oneOf(Object.values(StatusOptions)),
  width: PropTypes.oneOf(Object.values(WidthOptions)),
  noPadding: PropTypes.bool,
  isRounded: PropTypes.bool,
  buttonRef: PropTypes.object,
  isBoxShadowHidden: PropTypes.bool,
  justification: PropTypes.oneOf(Object.values(JustificationOptions)),
};

Button.defaultProps = {
  category: CategoryOptions.PRIMARY,
  size: SizeOptions.MEDIUM,
  status: StatusOptions.DEFAULT,
  width: WidthOptions.HUG,
  justification: JustificationOptions.CENTER,
  isBoxShadowHidden: false,
};

export default Button;
