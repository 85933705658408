import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from 'components/Constants';
import API from 'lib/api';
import SmallLoader from 'components/shared/SmallLoader';

class FileViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      embedUrl: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    if (
      this.props.presentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      this.props.presentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
    ) {
      this.setState({ isLoading: true });
      const id = this.props.presentation.id;
      API.get(
        `/presentations/${id}/embed_url/`,
        (response) => {
          const embedUrl = response?.data?.url && response.data.url.length > 0 ? response.data.url : '';
          this.setState({ embedUrl: embedUrl, isLoading: false });
        },
        () => {
          // eslint-disable-next-line no-console
          console.warn('Could not fetch embed url for Microsoft 365 file');
          this.setState({ isLoading: false });
        },
      );
    }
  }

  render() {
    const embedUrl = this.getUrl();
    const html = this.getHtml();

    if (this.state.isLoading) {
      return <SmallLoader />;
    }

    const title = 'template-preview-' + this.props.template.id;
    if (html) {
      return <HtmlViewer className={this.props.frameClass} title={title} html={html} />;
    } else {
      return <EmbedViewer className={this.props.frameClass} title={title} url={embedUrl} />;
    }
  }

  getUrl() {
    if (this.props.presentation) {
      if (
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
      ) {
        return this.props.presentation.presentation_file.embed_url;
      } else if (
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD
      ) {
        return (
          'https://view.officeapps.live.com/op/embed.aspx?src=' +
          encodeURIComponent(this.props.presentation.presentation_file.url)
        );
      } else if (
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        if (this.state.embedUrl.length > 0) {
          return this.state.embedUrl;
        }
        return this.props.presentation.presentation_file.url;
      }
    } else {
      if (
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
        this.props.presentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
        this.props.presentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
      ) {
        return this.props.template.source.embedUrl;
      } else if (this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS) {
        return this.props.template.source.embedUrl + '?chrome=false';
      } else if (
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ||
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD ||
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        return (
          'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.props.template.source.url)
        );
      }
    }
    return null;
  }

  getHtml() {
    if (this.props.presentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      if (this.props.presentation) {
        return this.processHtml(this.props.presentation.presentation_file);
      } else if (this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
        return this.props.template.slides[0].slide_xml;
      }
    }
    return null;
  }

  processHtml(presentationFile) {
    const images = presentationFile['images_by_dc_name'];
    const html = presentationFile['parsed_html'];
    if (typeof images === 'object' && Object.keys(images).length) {
      let html_copy = html.slice();
      for (const [dc_name, image_data] of Object.entries(images)) {
        html_copy = html_copy.replace(`cid:${dc_name}`, image_data);
      }
      return html_copy;
    }
    return html;
  }
}

FileViewer.propTypes = {
  presentation: PropTypes.any,
  template: PropTypes.object,
  frameClass: PropTypes.string,
};

export default FileViewer;

function HtmlViewer({ className, title, html }) {
  return (
    <iframe
      sandbox="allow-same-origin"
      id="template-preview"
      title={title}
      srcDoc={html}
      width="100%"
      height="100%"
      frameBorder="0"
      className={className || ''}
    />
  );
}
HtmlViewer.displayName = 'FileViewer.HtmlViewer';
HtmlViewer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.string,
};

const EmbedViewer = React.memo(
  function EmbedViewer({ className, title, url }) {
    return (
      <iframe
        id="template-preview"
        title={title}
        src={url}
        width="100%"
        height="100%"
        frameBorder="0"
        className={className}
      />
    );
  },
  (prevProps, nextProps) => {
    // S3 embed urls are generated on each backend call with a new signature and Expiration timestamp. We're
    // going to skip re-rendering if that's the only thing that's changed since re-rendering causes the
    // iframe content to re-load.
    const normalizeUrl = (url) => {
      return url?.replace(/%26Signature%3D.*Expires%3D[0-9]+/g, '');
    };
    return (
      prevProps.className === nextProps.className &&
      prevProps.title === nextProps.title &&
      normalizeUrl(prevProps.url) === normalizeUrl(nextProps.url)
    );
  },
);
EmbedViewer.displayName = 'FileViewer.EmbedViewer';
EmbedViewer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};
