import React from 'react';
import PropTypes from 'prop-types';
import GenerationStatusAnimation from './presentations/GenerationStatusAnimation';

function PageLoader({ direction, title, isActive, showLottie }) {
  const activeClass = isActive ? 'is-active' : '';
  return (
    <>
      {showLottie ? (
        <div className={`pageloader lottie ${direction} ${activeClass}`}>
          <div className="titlelottie">
            <div className="loader-wrapper">
              <div className="lottie-loader">
                <GenerationStatusAnimation status={title.toLowerCase()} theme="light" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`pageloader ${direction} ${activeClass}`}>
          <span className="title">{title}</span>
        </div>
      )}
    </>
  );
}

PageLoader.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  showLottie: PropTypes.bool,
};

export default PageLoader;
