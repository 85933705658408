import React, { useEffect } from 'react';
import PopupMenu from '../PopupMenu';
import { Form } from 'react-bulma-components';
import { Select } from 'components/shared/FormSelect';
import { useTemplates } from 'lib/hooks/useTemplate';
import Constants from 'components/Constants';
import { SourceIcon, TypeIcon } from 'components/producer/templates/AttachedTemplatesList';
import TextOverflowWithTooltip from '../TextOverflowWithTooltip';
import ButtonGroup from 'components/lib/ButtonGroup';
import Button from 'components/lib/Button';
import ToggleSwitch from 'components/lib/ToggleSwitch';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';

function LinkPopup({
  popupMenuAnchorRef,
  popupMenuRef,
  setAttachmentTemplate,
  setAttachmentType,
  attachmentType,
  attachmentTemplate,
  url,
  setUrl,
  onUpdateLink,
  editorState,
  linkTarget,
  setLinkTarget,
}) {
  const { elements: templates } = useTemplates(0, Constants.PAGE_SIZE, null);
  const filteredTemplateOptions =
    templates?.filter(
      (template) =>
        template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL &&
        !Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(template.source_type),
    ) || [];
  const attachmentTemplateOptions = filteredTemplateOptions?.map((template) => ({
    label: template.name,
    value: template.id,
    source_type: template.source_type,
  }));

  const attachmentTypeOptions = [
    { label: 'URL', value: 'url' },
    { label: 'Matik Template', value: 'template' },
  ];

  useEffect(() => {
    const descJSON = convertToRaw(editorState.getCurrentContent());
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const offset = selection.focusOffset || 0;
    const anchorOffset = selection.anchorOffset || 0;
    const block = descJSON.blocks.filter((textBlock) => textBlock.key === anchorKey)?.[0];
    const selectedEntityRange =
      block.entityRanges.filter(
        (range) => range.offset <= offset && range.offset + range.length >= anchorOffset,
      )?.[0] || {};
    if (descJSON.entityMap[selectedEntityRange?.key]?.data?.template) {
      setAttachmentType(attachmentTypeOptions[1]);
      setAttachmentTemplate(
        attachmentTemplateOptions.filter(
          (template) => template.value === descJSON.entityMap[selectedEntityRange?.key]?.data?.template,
        )?.[0] || null,
      );
    } else if (descJSON.entityMap[selectedEntityRange.key]?.data?.url) {
      setAttachmentType(attachmentTypeOptions[0]);
      setUrl(descJSON.entityMap[selectedEntityRange?.key]?.data?.url);
      setLinkTarget(descJSON.entityMap[selectedEntityRange?.key]?.data?.targetOption);
    } else {
      setAttachmentTemplate(null);
      setUrl('');
      setLinkTarget('');
    }
  }, [editorState]);

  const formatTemplatesOptionLabel = (option) => {
    if (Object.keys(option).length) {
      return (
        <>
          <div className="flex items-center gap-1">
            <>
              <SourceIcon sourceType={option.source_type} />
              <TypeIcon sourceType={option.source_type} />
            </>
            <TextOverflowWithTooltip className="leading-6 truncate pl-2">{option.label}</TextOverflowWithTooltip>
          </div>
        </>
      );
    }
  };

  const secondaryButtonActive = attachmentType && (url.length || attachmentTemplate);

  return (
    <PopupMenu ref={popupMenuRef} anchorRef={popupMenuAnchorRef} isManualClose={true}>
      <div className="bg-matik-white shadow-s rounded-lg cursor-auto flex flex-col border border-grey-300 w-[520px] p-4">
        <Form.Field>
          <Form.Label>Link to</Form.Label>
          <Form.Control>
            <Select
              classNamePrefix="matik-select"
              onChange={(option) => {
                setAttachmentType(option);
              }}
              value={attachmentType}
              options={attachmentTypeOptions}
              isMulti={false}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>{attachmentType.value === 'template' ? 'Select Template' : 'URL'}</Form.Label>
          <Form.Control>
            {attachmentType.value === 'template' ? (
              <Select
                classNamePrefix="matik-select"
                onChange={(option) => setAttachmentTemplate(option)}
                value={attachmentTemplate}
                options={attachmentTemplateOptions}
                isMulti={false}
                formatOptionLabel={formatTemplatesOptionLabel}
              />
            ) : (
              <Form.Input
                type="text"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            )}
          </Form.Control>
        </Form.Field>
        <Form.Field className="flex justify-between">
          <Form.Label>Open in new Tab</Form.Label>
          <ToggleSwitch
            switchState={linkTarget === '_blank'}
            size="m"
            onClick={() => setLinkTarget(linkTarget === '_blank' ? '_self' : '_blank')}
          />
        </Form.Field>
        <ButtonGroup width="hug" align="right">
          <Button category="tertiary" type="button" onClick={() => popupMenuRef.current.close()}>
            Cancel
          </Button>
          <Button category="secondary" onClick={onUpdateLink} status={secondaryButtonActive ? 'default' : 'disabled'}>
            Save
          </Button>
        </ButtonGroup>
      </div>
    </PopupMenu>
  );
}

LinkPopup.propTypes = {
  popupMenuAnchorRef: PropTypes.object,
  popupMenuRef: PropTypes.object,
  setAttachmentTemplate: PropTypes.func,
  setAttachmentType: PropTypes.func,
  attachmentType: PropTypes.object,
  attachmentTemplate: PropTypes.object,
  url: PropTypes.string,
  setUrl: PropTypes.func,
  onUpdateLink: PropTypes.func,
  editorState: PropTypes.object,
  linkTarget: PropTypes.string,
  setLinkTarget: PropTypes.func,
};

export default LinkPopup;
