import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Form } from 'react-bulma-components';
import API from '../../../lib/api';
import Constants from '../../Constants';
import utils from '../../../lib/utils';
import { UserContext } from '../../UserContext';
import { MAlert, MConfirm, MInsufficientPerms } from '../../shared/Alerts';
import { Prompt, useHistory } from 'react-router-dom';
import CheckboxWithLabel from '../../shared/CheckboxWithLabel';
import { Select } from '../../shared/FormSelect';
import DataSourceFormButtons from './DataSourceFormButtons';
import DataSourceOauthFormButtons from './DataSourceOauthFormButtons';
import TestResult from '../../shared/TestResult';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import GenericOauthForm from './forms/GenericOauthForm';
import FormHeader from 'components/shared/FormHeader';
import Pluralize from 'pluralize';
import { cloneDeep } from 'lodash';
import { components } from 'react-select';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as Checkmark } from '../../../svg/checkmark.svg';
import dataSources from 'lib/dataSources';
import FormChange from '../../../lib/formChange';
import AgentForm from './forms/AgentForm';
import AccessManager from 'lib/AccessManager';
import useAccesses, { useGenerationIssues } from 'lib/hooks/useAccess';
import { useDataSourceConnectedItems } from 'lib/hooks/useDataSource';
import Banner from 'components/lib/Banner';
import Button from 'components/lib/Button';

class DataSourceForm extends Component {
  constructor(props) {
    super(props);
    const auth = this.props.selectedDataSource?.auth_info ?? {};
    const type = this.props.selectedDataSource?.type ?? this.props.initialDataSourceType ?? null;
    let host = this.props.selectedDataSource?.host ?? '';
    if (type === Constants.DATA_SOURCE_TYPES.excel) {
      host = this.props.selectedDataSource?.host ? JSON.parse(this.props.selectedDataSource.host) : {};
    }

    // TODO (zak): Customize per data source
    let port = Constants.DATA_SOURCE_DEFAULT_PORTS[this.props.selectedDataSource?.type] ?? null;
    if (this.props.selectedDataSource?.port) {
      port = this.props.selectedDataSource.port.toString();
    }
    this.initialState = {
      agentId: this.props.selectedDataSource ? this.props.selectedDataSource.agent_id : null,
      auth: auth,
      database: this.props.selectedDataSource ? this.props.selectedDataSource.database : '',
      excelFile: null,
      fileError: '',
      hasAuthToken: false,
      host: host,
      hostError: '',
      isChanged: false,
      isUpdating: false,
      isProduction: this.props.selectedDataSource?.auth_info?.is_production ?? true,
      isAuthenticatedToDataSource: false,
      selectedDataSource: this.props.selectedDataSource,
      name: this.props.selectedDataSource ? this.props.selectedDataSource.name : '',
      nameError: '',
      isOAuth: dataSources.isOAuth(type, auth),
      port: port,
      requiresConsumerLogin: this.props.selectedDataSource?.requires_consumer_login ?? false,
      skipSslCheck: this.props.selectedDataSource ? this.props.selectedDataSource.skip_ssl_check : false,
      testIsLoading: false,
      testResult: null,
      type: type,
      isFullscreen: this.props.isFullscreen,
    };

    this.state = this.initialState;

    this.oauthUpdate = null;

    this.formChange = new FormChange(this._getInitialValues());

    this.ref = React.createRef();
  }

  _getInitialValues() {
    return {
      agentId: this.state.agentId,
      auth: cloneDeep(this.state.auth),
      database: this.state.database,
      excelFile: cloneDeep(this.state.excelFile),
      host: this.state.host,
      isProduction: this.state.isProduction,
      name: this.state.name,
      port: this.state.port,
      requiresConsumerLogin: this.state.requiresConsumerLogin,
      skipSslCheck: this.state.skipSslCheck,
      type: this.state.type,
    };
  }

  componentDidMount() {
    if (this.props.selectedDataSource) {
      this.setState({
        name: this.props.selectedDataSource ? this.props.selectedDataSource.name : '',
        host:
          this.props.selectedDataSource.type === Constants.DATA_SOURCE_TYPES.excel
            ? JSON.parse(this.props.selectedDataSource.host)
            : this.props.selectedDataSource.host ?? '',
        port: this.props.selectedDataSource.port.toString() ?? null,
        type: this.props.selectedDataSource.type,
        requiresConsumerLogin: this.props.selectedDataSource.requires_consumer_login,
        hasAuthToken: this.props.selectedDataSource.has_auth_token,
        database: this.props.selectedDataSource.database,
        skipSslCheck: this.props.selectedDataSource.skip_ssl_check,
        auth: this.props.selectedDataSource?.auth_info ?? {},
        isFullscreen: this.props.isFullscreen,
        isProduction: this.props.selectedDataSource?.auth_info?.is_production ?? true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.type !== this.state.type) {
      if (dataSources.isOAuth(this.state.type, this.state.auth)) {
        this.setState({ isOAuth: true });
      } else {
        this.setState({ isOAuth: false });
      }
    }
    if (
      this.state.auth?.access_token?.length > 0 &&
      (this.state.auth?.username?.length > 0 || this.state.auth?.password?.length > 0)
    ) {
      this.setState({
        auth: {
          ...this.state.auth,
          username: '',
          password: '',
        },
      });
    }
    const isChanged = this.formChange.hasChanged(this.state);

    if (isChanged !== this.state.isChanged) {
      this.setState({ isChanged });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this.props.toggleIsChanged(false);
    this.setState({ isChanged: false });
  }

  render() {
    if (this.state.isChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    const nameColor = this.state.nameError ? 'danger' : null;
    const DataSourceTypeForm = dataSources.getDataSourceTypeForm(this.state.type);
    const DataSourceAuthForm = dataSources.getDataSourceAuthForm(this.state.type);
    const dataSourceTypeDisplay = Constants.DATA_SOURCE_TYPE_DISPLAY[this.state.type] || null;
    const selectType = dataSourceTypeDisplay ? { value: this.state.type, label: dataSourceTypeDisplay.text } : null;
    const dataSourceCommonName = dataSourceTypeDisplay ? dataSourceTypeDisplay.text : '';
    let filteredAndSortedSelectOptions = dataSources.getPublicDataSourceSelectOptions(this.props.flags);
    const groupedOptions = dataSources.categorizeDataSourceSelectOptions(filteredAndSortedSelectOptions);

    const { Option } = components;
    const IconOption = (props) => {
      return (
        <Option {...props}>
          <div className="data-source-dropdown">
            <img src={props.data.icon} alt={props.data.label} />
            <span>{props.data.label}</span>
            {props.isSelected && <Checkmark className="data-source-dropdown check" />}
          </div>
        </Option>
      );
    };

    const IconControl = ({ ...props }) => {
      const icon = Object.values(props.options)
        .flatMap((group) => group.options)
        .find((option) => option.value === props.getValue()?.[0]?.value)?.icon;
      return (
        <components.Control {...props}>
          <div className="data-source-dropdown-select">{icon && <img src={icon} />}</div>
          {props.children}
        </components.Control>
      );
    };

    const consumerLoginDisabled = !this.state.isOAuth && this.state.type === Constants.DATA_SOURCE_TYPES.googlebq;

    let checkboxes = [null, null];
    if (this.context.user?.enterprise?.plan_id === Constants.MATIK_TIERS.matik_enterprise.tier_id) {
      checkboxes = [
        <CheckboxWithLabel
          key="has_auth_token"
          checked={!!this.state.hasAuthToken}
          id="has_auth_token"
          label="Allow end users to enter API Auth token"
          name="hasAuthToken"
          onChange={this.onChange}
        />,
        <CheckboxWithLabel
          key="requires_consumer_login"
          checked={!!this.state.requiresConsumerLogin}
          disabled={consumerLoginDisabled}
          id="requires_consumer_login"
          label="Require end user to log in to access this data source"
          name="requiresConsumerLogin"
          onChange={this.onChange}
        />,
      ];
    }

    let accesses;
    let isReadOnly = false;
    if (this.props.selectedDataSource && this.props.flags.dataSourcePermissions) {
      accesses = new AccessManager(this.props.selectedDataSource.id, this.props.accessesByItemId, this.context.user);
      isReadOnly = !accesses.can('edit');
    }

    // Using a service account to access google bigquery means that consumer login doesn't make sense
    return (
      <form onSubmit={this.handleFormSubmit} className="data-source-form" ref={this.ref}>
        <Box className="data-source-form-main is-shadowless">
          {this.props.isFullscreen ? null : (
            <FormHeader
              connectedItems={this.props.connectedItems}
              generationIssues={this.props.generationIssues}
              dataSourceType={this.state.type}
              entityType={this.props.entityType}
              formType={Constants.ItemTypes.DATA_SOURCE}
              isLoading={this.props.isConnectedLoading}
              isNew={!this.state.selectedDataSource}
              linkDisabled={true}
              name={this.state.name ? this.state.name : 'Connect Data Source'}
              onDelete={(e) => this.deleteDataSource(e, this.props.selectedDataSource)}
              selectedDataSource={this.props.selectedDataSource}
              showDelete={!!this.props.selectedDataSource}
              url={`/data_sources/${this.state.selectedDataSource?.id}`}
              accesses={accesses}
              isReadOnly={isReadOnly}
            />
          )}
          {!this.state.hideAccessWarning && accesses?.isPrivateToMe() && (
            <div className="mb-5">
              <Banner
                theme="info"
                content="This data source is not shared with anyone.
                Grant access if you would like users to be able to generate content from it."
                buttons={[
                  <Button type="button" key="g" category="secondary" onClick={this.handleGrantClick}>
                    Grant Access
                  </Button>,
                ]}
                onClose={() => this.setState({ hideAccessWarning: true })}
              />
            </div>
          )}
          <Box className="data-source-box" style={this.props.centered ? { margin: '0 auto' } : null}>
            <Form.Field>
              <Form.Label>Data Source Type</Form.Label>
              <Form.Control>
                <Select
                  id="data-source-select-dropdown"
                  classNamePrefix="matik-select"
                  onChange={this.onTypeChange}
                  name="type"
                  value={selectType}
                  autoFocus={selectType === null}
                  aria-label="Select Data Source Type"
                  options={groupedOptions}
                  componentsToAdd={{ Option: IconOption, Control: IconControl }}
                  styles={{
                    group: (base) => ({
                      ...base,
                      borderBottom: '1px solid #E0E5EE', // $grey-300
                      marginBottom: '0.6rem',
                    }),
                    groupHeading: (base) => ({
                      ...base,
                      textTransform: 'none',
                      fontSize: '12px',
                      color: '#69778F', // $grey-600
                    }),
                  }}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="db-name">Name</Form.Label>
              <Form.Control>
                <Form.Input
                  placeholder="Data source name"
                  type="text"
                  name="name"
                  color={nameColor}
                  onChange={this.onChange}
                  value={this.state.name}
                  autoComplete="off"
                  id="db-name"
                />
                {this.state.nameError && <Form.Help color="danger">{this.state.nameError}</Form.Help>}
              </Form.Control>
            </Form.Field>
            {this.props.flags.enableMatikAgent && (
              <AgentForm
                onSelectAgent={this.onSelectAgent}
                selectedAgentId={this.state.agentId}
                onDeselectAgent={this.onDeselectAgent}
              />
            )}
            {DataSourceAuthForm === GenericOauthForm && checkboxes[1]}
            {DataSourceTypeForm && (
              <DataSourceTypeForm
                {...this.state}
                selectedDataSource={this.props.selectedDataSource}
                reconnectToDataSource={this.connectToDataSource}
                typeToUpperCase={this.typeToUpperCase}
                onChange={this.onChange}
                onAuthChange={this.onAuthChange}
                testDataSource={this.testDataSource}
                deleteDataSource={this.deleteDataSource}
                connectToDataSource={this.connectToDataSource}
                clearSSHKey={this.clearSSHKey}
                onSSHTunnelChange={this.onSSHTunnelChange}
                skipSslCheck={this.state.skipSslCheck}
                submitForm={this.handleFormSubmit}
                onPasswordAuthChange={this.onAuthChange}
                isOAuth={this.state.isOAuth}
                isProduction={this.state.isProduction}
                onIsProductionChange={this.onIsProductionChange}
                setIsAuthenticatedToDataSource={this.setIsAuthenticatedToDataSource}
                oauthFunction={this.oauthFunction}
                onFileSelected={this.onFileSelected}
                removeFile={this.removeFile}
                setIsOAuth={this.setIsOAuth}
                isNew={!this.state.selectedDataSource}
              />
            )}
          </Box>
          {DataSourceAuthForm && DataSourceAuthForm !== GenericOauthForm && (
            <DataSourceAuthForm
              auth={this.state.auth}
              centered={this.props.centered}
              checkboxes={checkboxes}
              clearSSHKey={this.clearSSHKey}
              isOAuth={this.state.isOAuth}
              onAuthChange={this.onAuthChange}
              selectedDataSource={this.props.selectedDataSource}
              setIsOAuth={this.setIsOAuth}
            />
          )}
          <TestResult testResult={this.state.testResult} />
          {this.state.isOAuth ? (
            <DataSourceOauthFormButtons
              buttonDisabled={isReadOnly || !this.state.isChanged}
              selectedDataSource={this.props.selectedDataSource}
              type={this.state.type}
              connectToDataSource={this.connectToDataSource}
              reconnectToDataSource={this.connectToDataSource}
              isProduction={this.state.isProduction}
              isAuthenticatedToDataSource={this.state.isAuthenticatedToDataSource}
              oauthFunction={this.oauthFunction}
              dataSourceType={dataSourceCommonName}
            />
          ) : (
            <DataSourceFormButtons
              buttonDisabled={isReadOnly || !this.state.isChanged}
              isLoading={this.state.isUpdating}
              testIsLoading={this.state.testIsLoading}
              selectedDataSource={this.props.selectedDataSource}
              testDataSource={this.testDataSource}
              deleteDataSource={this.deleteDataSource}
              auth={this.state.auth}
              type={this.state.type}
              host={
                this.state.type === Constants.DATA_SOURCE_TYPES.excel
                  ? JSON.stringify(this.state.host)
                  : this.state.host
              }
              database={this.state.database}
            />
          )}
          <Prompt
            when={this.state.isChanged}
            message="Are you sure you want to navigate away? There are unsaved changes."
          />
        </Box>
      </form>
    );
  }

  handleGrantClick = () => {
    this.props.openModal('formHeaderAccessModal', {
      id: this.props.selectedDataSource.id,
      type: 'data_source',
      name: this.props.selectedDataSource.name,
    });
  };

  onSelectAgent = (agentId) => {
    this.setState({ agentId });
  };

  onDeselectAgent = () => {
    this.setState({ agentId: null });
  };

  connectToDataSource = (e, dataSourceType, isProduction = true) => {
    e.preventDefault();
    if (!this.state.name) {
      this.setState({ nameError: 'Name is required' });
      return false;
    }

    const onUpdate = (dataSource) => {
      this.setState({ auth: dataSource.auth_info }, () => {
        this.formChange.updateInitialValues(this._getInitialValues());
      });
      if (!this.props.selectedDataSource) {
        this.props.onDataSourceAdd(dataSource);
        API.track('data_source_add');
        this.props.history.push(`/data_sources/${dataSource.id}`);
      }
    };

    if (dataSourceType === Constants.DATA_SOURCE_TYPES.salesforce) {
      utils.openSalesforceOauthPopup(
        this.context.user.nonce,
        onUpdate,
        this.state.name,
        'prompt=consent',
        isProduction,
      );
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.googlebq) {
      utils.openGoogleBQOauthPopup(
        this.context.user.nonce,
        onUpdate,
        this.state.name,
        this.state.database,
        'prompt=consent',
      );
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.hubspot) {
      utils.openHubspotOauthPopup(this.context.user.nonce, onUpdate, this.state.name, 'prompt=consent');
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.snowflake) {
      utils.openSnowflakeOAuthPopup(
        this.context.user.nonce,
        onUpdate,
        this.state.name,
        this.state.host,
        this.state.database,
        this.state.auth,
      );
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.notion) {
      utils.openNotionOAuthPopup(this.context.user.nonce, onUpdate, this.state.name, 'prompt=consent');
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.api) {
      utils.openRestAPIOAuthPopup(
        this.context.user.nonce,
        onUpdate,
        this.state.name,
        this.state.auth,
        `prompt=${this.state.auth.prompt ?? 'consent'}`,
      );
    } else {
      MAlert('Data source type not supported', 'Error', 'error');
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validateFormData()) {
      this.setState({ isUpdating: true });
      const dataSourceData = this._getDataFromState();
      if (this.props.selectedDataSource) {
        API.put(
          `/data_sources/${this.props.selectedDataSource.id}/`,
          dataSourceData,
          (response) => {
            this.setState({
              testResult: null,
              excelFile: null,
              isChanged: false,
            });
            // Update state to handle encryption
            const updatedDataSource = response.data.updated_entity;
            this.setState({
              auth: updatedDataSource.auth_info,
              host:
                updatedDataSource.type === Constants.DATA_SOURCE_TYPES.excel
                  ? JSON.parse(updatedDataSource.host)
                  : updatedDataSource.host ?? '',
            });
            this.props.onDataSourceUpdate(updatedDataSource);
            this.formChange.updateInitialValues(this._getInitialValues());
            API.track('data_source_update');
          },
          this.submitError,
          this.state.excelFile?.file ?? this.state.excelFile ?? undefined,
        ).finally(() => this.setState({ isUpdating: false }));
      } else {
        API.post(
          '/data_sources/',
          dataSourceData,
          (response) => {
            this.setState({
              name: '',
              host: '',
              database: '',
              port: Constants.DATA_SOURCE_DEFAULT_PORTS[this.state.type] || '',
              testResult: null,
              auth: {},
              skipSslCheck: false,
              requiresConsumerLogin: false,
              hasAuthToken: false,
              isChanged: false,
            });
            API.track('data_source_add');
            this.props.onDataSourceAdd(response.data.new_entity);
            this.props.history.push(`/data_sources/${response.data.new_entity.id}`);
          },
          this.submitError,
          this.state.excelFile?.file ?? this.state.excelFile ?? undefined,
        ).finally(() => this.setState({ isUpdating: false }));
      }
    }
  };

  testDataSource = (e) => {
    e.preventDefault();
    if (this.validateFormData()) {
      this.setState({ testIsLoading: true });
      const dataSourceData = this._getDataFromState();
      API.post(
        '/data_sources/test/',
        dataSourceData,
        (response) => {
          this.setState({
            testResult: response.data,
          });
        },
        this.testError,
      ).finally(() => this.setState({ testIsLoading: false }));
    }
  };

  _getDataFromState = () => {
    let requiresConsumerLogin = this.state.requiresConsumerLogin;
    if (this.state.auth && this.state.auth.service_account_info) {
      requiresConsumerLogin = false;
    }
    const data = {
      agent_id: this.state.agentId,
      data_source_id: this.props?.selectedDataSource?.id,
      type: this.state.type,
      name: this.state.name,
      host: this.state.host,
      port: this.state.port,
      database: this.state.database,
      auth: this.state.auth,
      requires_consumer_login: requiresConsumerLogin,
      has_auth_token: this.state.hasAuthToken,
    };
    if (!this.state.isOAuth) {
      data['auth']['skip_ssl_check'] = this.state.skipSslCheck;
    }

    return data;
  };

  testError = (err) => {
    const errorResult = { status: 'error', message: 'Something went wrong' };
    if (err.response.status === 504) {
      errorResult.message = 'No data source found on host and port.';
    }
    if (err?.response?.data) {
      errorResult.message = err.response.data?.message;
    }
    this.setState({ testResult: errorResult });
  };

  submitError = (err) => {
    this.setState({ testResult: null });
    API.defaultError(err);
  };

  validateFormData = () => {
    if (!this.state.name) {
      this.ref.current.scrollIntoView({ behavior: 'smooth' });
      this.setState({ nameError: 'Name is required' });
      return false;
    }

    if (
      !this.state.isOAuth &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.looker &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.tableau &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.api &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.snowflake &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.productboard &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.gainsight &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.databricks &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.excel &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.googlebq &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.power_bi &&
      this.state.type !== Constants.DATA_SOURCE_TYPES.oracle &&
      !this.state.host
    ) {
      this.setState({ hostError: 'Host is required' });
      return false;
    }

    if (this.state.type === Constants.DATA_SOURCE_TYPES.athena) {
      if (this.state.host.length === 0) {
        this.setState({ hostError: 'AWS Datasource name is required' });
        return false;
      }
    }
    if (this.state.type === Constants.DATA_SOURCE_TYPES.excel) {
      if (!(this.state.excelFile || (this.props.selectedDataSource && this.state.host))) {
        this.setState({ fileError: 'File not found' });
        return false;
      }

      const fileExtension =
        this.props.selectedDataSource && !this.state.excelFile
          ? this.state.host?.name.split('.').pop()
          : this.state.excelFile?.name.split('.').pop();
      //validate if file is excel type
      if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
        MAlert('Please select an Excel file.');
        return false;
      }
    }
    return true;
  };

  onChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const targetErrorState = [e.target.name] + 'Error';
    this.setState({
      [e.target.name]: value,
      [targetErrorState]: '',
      submitError: '',
    });
  };

  onFileSelected = (e) => {
    if (e.target.files[0]) {
      this.setState({
        excelFile: e.target.files[0],
        fileError: '',
      });
    }
  };

  removeFile = (e) => {
    e.preventDefault();
    this.setState({
      excelFile: null,
      host: null,
      selectedDataSource: null,
    });
  };
  onIsProductionChange = (val) => {
    this.setState({ isProduction: val === '1' });
  };

  setIsOAuth = (val) => {
    this.setState({ isOAuth: val });
  };

  onAuthChange = (e) => {
    const currentAuth = Object.assign({}, this.state.auth);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const updatedAuth = {
      ...currentAuth,
      [e.target.name]: value,
    };
    this.setState({
      auth: updatedAuth,
    });
  };

  clearSSHKey = () => {
    const updatedAuth = Object.assign({}, this.state.auth);
    updatedAuth.ssh_key = '';
    this.setState({ auth: updatedAuth });
  };

  onSSHTunnelChange = () => {
    const updatedAuth = Object.assign({}, this.state.auth);
    updatedAuth.ssh_key = '';
    updatedAuth.ssh_host = '';
    updatedAuth.ssh_username = '';
    this.setState({ auth: updatedAuth });
  };

  onTypeChange = (obj) => {
    const value = obj.value;
    this.setState({
      ...this.initialState,
      port: Constants.DATA_SOURCE_DEFAULT_PORTS[value],
      type: value,
    });
  };

  setIsAuthenticatedToDataSource = (isAuthenticated, selectedSheet) => {
    if (isAuthenticated && !!selectedSheet) {
      this.setState({ isAuthenticatedToDataSource: true });
    } else {
      this.setState({ isAuthenticatedToDataSource: false });
    }
  };

  typeToUpperCase = (type) => {
    return type.substr(0, 1).toUpperCase() + type.substr(1);
  };

  deleteDataSource = (e, dataSource) => {
    e.preventDefault();
    let message = 'Are you sure you want to delete this data source?';
    if (
      this.props.connectedItems.dynamic_content?.count ||
      this.props.connectedItems.input?.count ||
      this.props.connectedItems.template?.count
    ) {
      message += ' This may affect';
      if (this.props.connectedItems.dynamic_content?.count) {
        message += ` ${Pluralize('piece', this.props.connectedItems.dynamic_content.count, true)} of dynamic content`;
      }
      if (this.props.connectedItems.input?.count) {
        if (this.props.connectedItems.dynamic_content?.count) {
          message += ',';
        }
        message += ` ${Pluralize('input', this.props.connectedItems.input.count, true)}`;
      }
      if (this.props.connectedItems.template?.count) {
        if (this.props.connectedItems.dynamic_content?.count || this.props.connectedItems.input?.count) {
          message += ' and';
        }
        message += ` ${Pluralize('template', this.props.connectedItems.template.count, true)}`;
      }
      message += '.';
    }

    MConfirm('Delete', message, 'error', (confirmed) => {
      if (confirmed) {
        API.delete(
          `/data_sources/${dataSource.id}/`,
          () => {
            this.props.deleteDataSource(dataSource);
            this.props.history.push('/data_sources');
          },
          this.submitError,
        );
      }
    });
  };

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message = 'In order to add your data source, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectGoogle);
  };

  oauthFunction = (onUpdate) => {
    if (typeof onUpdate !== 'function') {
      onUpdate = () => this.setState({ isAuthenticatedToDataSource: true });
    }
    this.oauthUpdate = onUpdate;
    utils.connectGoogle(this.context.user, this.context.updateUser, onUpdate, this.showPermsAlert);
  };

  reconnectGoogle = () => {
    utils.reconnectGoogle(this.context.user, this.context.updateUser, this.oauthUpdate, this.showPermsAlert);
  };
}

DataSourceForm.propTypes = {
  onDataSourceAdd: PropTypes.func,
  onDataSourceUpdate: PropTypes.func,
  selectedDataSource: PropTypes.object,
  initialDataSourceType: PropTypes.string,
  deleteDataSource: PropTypes.func,
  flags: PropTypes.object,
  centered: PropTypes.bool,
  history: PropTypes.object,
  toggleIsChanged: PropTypes.func,
  entityType: PropTypes.string,
  isFullscreen: PropTypes.bool,
  accessesByItemId: PropTypes.object,
  openModal: PropTypes.func,
  connectedItems: PropTypes.object,
  isConnectedLoading: PropTypes.bool,
  generationIssues: PropTypes.object,
};

DataSourceForm.contextType = UserContext;

const DataSourceFormWrapper = (props) => {
  const { data: accessesByItemId, invalidate: invalidateAccesses } = useAccesses('data_source');
  const { data: connectedItems, isPending: isConnectedLoading } = useDataSourceConnectedItems(
    props.selectedDataSource?.id,
  );
  const generationIssues = useGenerationIssues(
    connectedItems.template.items ? connectedItems.template.items?.map((template) => template.id) : [],
  );
  const flags = useFlags();
  const dispatch = useDispatch();
  const history = useHistory();

  const { openModal, toggleIsChanged } = mapDispatchToProps(dispatch);

  const handleDataSourceAdd = (...args) => {
    invalidateAccesses();
    props.onDataSourceAdd(...args);
  };
  return (
    <DataSourceForm
      {...props}
      history={history}
      flags={flags}
      accessesByItemId={accessesByItemId}
      onDataSourceAdd={handleDataSourceAdd}
      openModal={openModal}
      toggleIsChanged={toggleIsChanged}
      connectedItems={connectedItems}
      isConnectedLoading={isConnectedLoading}
      generationIssues={generationIssues}
    />
  );
};
DataSourceFormWrapper.propTypes = {
  onDataSourceAdd: PropTypes.func,
  selectedDataSource: PropTypes.object,
};

export default DataSourceFormWrapper;
