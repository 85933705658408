import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AsyncLoadImage from '../shared/AsyncLoadImage';
import SlideGridSlide from '../../lib/slideGridSlide';
import { EndUserCreateContext } from './EndUserCreateContext';
import InsertSlide from '../shared/templates/InsertSlide';
import InsertTemplateSlidePopup from './InsertTemplateSlidePopup';
import { ReactComponent as Close } from '../../svg/close.svg';
import { MConfirm } from '../shared/Alerts';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { getLoopedSlideInfo } from 'lib/looping.js';

import InputDisplayName from 'components/shared/templates/InputDisplayName';
import IconPill from '../lib/IconPill';

const LoopIcon = ({ slide, loops, inputs }) => {
  const loop = getLoopedSlideInfo(slide, loops);
  if (!loop) {
    return null;
  }
  const loopedInput = Object.values(inputs).find((i) => loop.loop.parameter === i.id);
  return (
    <div
      data-tooltip-id="matik-tooltip"
      data-tooltip-content={`A customized version of this slide will be generated for each provided value of ${
        loopedInput ? `"${InputDisplayName.text(loopedInput)}"` : 'the input'
      }.`}
      className="pan loop-icon"
    >
      <IconPill iconName="loop" color={loop.color} size="m" theme="circle" />
    </div>
  );
};
LoopIcon.propTypes = {
  slide: PropTypes.object,
  loops: PropTypes.array,
  inputs: PropTypes.object,
  onClick: PropTypes.func,
};

class TemplateSlide extends Component {
  render() {
    const slideGridSlide = new SlideGridSlide(
      this.props.slide,
      this.props.currentTemplate,
      this.props.status,
      this.props.flags,
    );
    const checkId = `${this.props.slideNum}_check`;
    const checked = this.context.selectedSlidesBySlideNum[this.props.slideNum];

    return (
      <div
        className={`slide-wrapper ${checked ? 'selected' : ''} ${slideGridSlide.opaqueClass(
          this.context.highlightedSlideNums,
          this.props.slideNum,
        )}`}
        key={`thumbnail-${this.props.slide.id}`}
      >
        <InsertSlide
          currentTemplate={this.props.currentTemplate}
          ignoreLibraries={true}
          insertLibrarySlidesIntoTemplate={this.insertSlideIntoTemplate}
          isDisabled={false}
          PopUpComponent={InsertTemplateSlidePopup}
          slideNum={this.props.slideNum}
        />
        <div className="slide-view">
          {this.props.slide.isAdded && (
            <button
              className="slide-close-icon"
              onClick={this.removeAddedSlide}
              data-tooltip-id="matik-tooltip"
              data-tooltip-content="Remove slide"
            >
              <Close />
            </button>
          )}
          <div className="flex absolute items-center gap-2 m-2 right-0">
            {slideGridSlide.conditionsExist() && (
              <button
                className="conditions-icon"
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="This slide will be conditionally included based on inputs."
              >
                <IconPill iconName="condition_arrow" size="m" theme="circle" />
              </button>
            )}

            <LoopIcon
              slide={this.props.slide}
              loops={this.props.currentTemplate.slide_loops}
              inputs={this.props.inputs}
            />

            {this.props.slide.isAdded && (
              <button
                className="libraries-icon"
                data-tooltip-id="matik-tooltip"
                data-tooltip-content={this.props.slide.template.name}
              >
                <IconPill iconName="library" color="purple" size="m" theme="circle" />
              </button>
            )}
          </div>

          <AsyncLoadImage
            fetchUrl={'unused'}
            fetchStatus={this.props.slideFetchStatus}
            isBulk={true}
            imageClass="async-rounded"
            height="152px"
            isLibrary={this.props.slide.isAdded}
          />
          {!this.props.slide.isAdded && (
            <div className="absolute shadow-md rounded bottom-[1px] right-0 px-2 py-1 bg-matik-white">
              <input id={checkId} type="checkbox" checked={checked} onChange={this.onSelectChange} />
              <label htmlFor={checkId}></label>
            </div>
          )}
        </div>
        {this.props.isLast && (
          <InsertSlide
            currentTemplate={this.props.currentTemplate}
            ignoreLibraries={true}
            insertLibrarySlidesIntoTemplate={this.insertSlideIntoTemplate}
            isDisabled={false}
            isLast={this.props.isLast}
            PopUpComponent={InsertTemplateSlidePopup}
            slideNum={this.props.slideNum + 1}
          />
        )}
      </div>
    );
  }

  insertSlideIntoTemplate = (slidesToInsert, currentTemplateId, insertionIdx) => {
    const slideObjsToInsert = Object.keys(slidesToInsert).map((slideKey) => {
      const { templateId, slideId } = slidesToInsert[slideKey];
      const template = {
        id: templateId,
      };
      const slide = {
        id: slideId,
        template: template,
      };
      return { template, slide };
    });
    this.context.addSlideFromTemplateToCurrentTemplate(slideObjsToInsert, insertionIdx);
    return Promise.resolve();
  };

  removeAddedSlide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MConfirm(
      'Delete Slide?',
      'Are you sure you want to remove this slide from the template',
      'warning',
      (confirmed) => {
        if (confirmed) {
          this.context.removeSlideFromTemplate(this.props.slide.id, this.props.slide.template.id);
        }
      },
    );
  };

  onSelectChange = (e) => {
    const value = e.target.checked;
    this.context.toggleSlideNumSelect(this.props.slideNum, value);
  };
}

TemplateSlide.propTypes = {
  currentTemplate: PropTypes.object,
  isLast: PropTypes.bool,
  slide: PropTypes.object,
  slideFetchStatus: PropTypes.object,
  slideNum: PropTypes.number,
  status: PropTypes.object,
  flags: PropTypes.object,
  inputs: PropTypes.object,
};

TemplateSlide.contextType = EndUserCreateContext;

export default withLDConsumer()(TemplateSlide);
