import React, { useEffect } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import PropTypes from 'prop-types';
import PaddingInput from 'components/shared/PaddingInput';
import FormInput from 'components/shared/FormInput';
import Indicator from './Indicator';
import InputColorSelect from 'components/lib/InputColorSelect';

function Divider({ paddingBottom, paddingLeft, paddingRight, paddingTop, style, isNewNode }) {
  const { id } = useNode((node) => ({
    id: node.id,
  }));

  const {
    actions: { selectNode },
    isActive,
    isHover,
  } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
    isHover: query.getEvent('hovered').contains(id),
  }));

  const {
    actions: { setProp },
    connectors: { connect, drag },
  } = useNode((state) => ({
    hasDraggedNode: state.events.dragged,
    hasSelectedNode: state.events.selected,
  }));

  const divContainerStyle = {
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    paddingTop: paddingTop,
  };

  useEffect(() => {
    if (isNewNode) {
      selectNode(id);
      setProp((props) => {
        props.isNewNode = false;
      });
    }
  }, []);

  return (
    <div ref={(dom) => connect(dom)} className="is-relative" style={divContainerStyle}>
      {(isActive || isHover) && (
        <Indicator borderWidth="0px" borderRadius="4px" tabText="Divider" isActive={isActive} nodeId={id} drag={drag} />
      )}
      <div style={style} />
    </div>
  );
}

Divider.craft = {
  props: {
    style: {
      background: '#E0E5EE', // $grey-300
      height: '2px',
      margin: '0px',
      width: '100%',
    },
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '10px',
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  related: {
    settings: DividerSettings,
  },
};

Divider.propTypes = {
  color: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  style: PropTypes.object,
  isNewNode: PropTypes.bool,
};

function DividerSettings() {
  const {
    actions: { setProp },
    background,
    height,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = useNode((node) => ({
    background: node.data.props.style.background,
    height: node.data.props.style.height,
    paddingBottom: parseInt(node.data.props.paddingBottom),
    paddingLeft: parseInt(node.data.props.paddingLeft),
    paddingRight: parseInt(node.data.props.paddingRight),
    paddingTop: parseInt(node.data.props.paddingTop),
  }));

  const heightMin = 0;
  const heightMax = 100;

  const onColorChange = (color) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style.background = color;
    });
  };

  const onHeightChange = (num) => {
    num = Math.min(Math.max(num, heightMin), heightMax);
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style.height = `${num}px`;
    });
  };

  const onPaddingChange = (num, paddingProp) => {
    setProp((props) => {
      props[paddingProp] = `${num}px`;
    });
  };

  return (
    <div className="module-settings-container">
      <div className="module-setting">
        <label>Line color</label>
        <InputColorSelect className="w-[112px]" onChange={onColorChange} value={background} />
      </div>
      <div className="module-setting">
        <label>Height</label>
        <div>
          <FormInput
            inputClass="module-settings-input pixel-input"
            max={heightMax}
            min={heightMin}
            onChange={(e) => {
              onHeightChange(e.target.value);
            }}
            suffix={<span>px</span>}
            type="number"
            value={parseInt(height)}
          />
        </div>
      </div>
      <div className="module-setting">
        <label>Padding</label>
        <div>
          <PaddingInput
            onPaddingChange={onPaddingChange}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingTop={paddingTop}
          />
        </div>
      </div>
    </div>
  );
}

export default Divider;
