import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import PopupMenu from '../PopupMenu';
import TabItem from 'components/lib/TabItem';
import Tabs from 'components/lib/Tabs';
import { usePresentationSummary, usePresentationSummaryMutator } from 'lib/hooks/usePresentation';
import Constants from 'components/Constants';
import API from 'lib/api';
import utils from 'lib/utils';
import useIsAiEnabled, { useAiSummaryDisabledMessage } from 'lib/hooks/useAI';
import { useCanEditPresentation } from 'lib/presentations';

function NarrativeSummaryPopup({ className, presentationId }) {
  const { data: summary } = usePresentationSummary(presentationId);
  const { generateSummary } = usePresentationSummaryMutator();

  const { isPending: isAccessPending, canEdit } = useCanEditPresentation(presentationId);
  const canRegenerate = !isAccessPending && canEdit;

  const { disableAllAi } = useIsAiEnabled();
  if (disableAllAi) {
    return null;
  }

  const handleRegenerate = (type) => {
    generateSummary(presentationId, type).catch(API.defaultError);
  };

  return (
    <div className={className}>
      <PopupMenu
        trigger={
          <div
            data-tooltip-content="Presentation Insights"
            data-tooltip-id="matik-tooltip"
            className="h-8 w-8 bg-matik-white hover:bg-grey-50 
              flex justify-center items-center 
              border border-grey-300 rounded shadow-s"
          >
            <Icon name="text_grammar_wand" size={16} theme="regular" />
          </div>
        }
        position="x"
      >
        <Form summary={summary} canRegenerate={canRegenerate} onRegenerate={handleRegenerate} />
      </PopupMenu>
    </div>
  );
}
NarrativeSummaryPopup.propTypes = {
  className: PropTypes.string,
  presentationId: PropTypes.number.isRequired,
};

export default NarrativeSummaryPopup;

const { EXECUTIVE_SUMMARY, TALK_TRACKS } = Constants.PresentationSummaryType;

function Form({ summary, canRegenerate, onRegenerate }) {
  const [activeTab, setActiveTab] = useState(EXECUTIVE_SUMMARY);
  const [aiEnabled, disabledMessage] = useAiSummaryDisabledMessage();

  let summaryLabel;
  let summaryContent = '';
  const isGenerating = [summary?.executive_summary?.status, summary?.talk_tracks?.status].includes(
    Constants.PresentationSummaryStatus.PENDING,
  );

  if (activeTab === EXECUTIVE_SUMMARY) {
    summaryLabel = 'Executive Summary';
    summaryContent = summary?.executive_summary?.value || '';
  } else {
    summaryLabel = 'Talk Track';
    summaryContent = (summary?.talk_tracks?.values || [])
      .map((value, index) => `Slide ${index + 1}:\n${value}`)
      .join('\n\n');
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(summaryContent);
    utils.notify('Copied to clipboard');
  };

  return (
    <div className="bg-matik-white border-grey-300 rounded-lg border">
      <div className="flex flex-col !p-6 gap-6">
        <div className="flex-nowrap">
          <Tabs>
            <TabItem
              onClick={() => setActiveTab(EXECUTIVE_SUMMARY)}
              isActive={activeTab == EXECUTIVE_SUMMARY}
              label="Executive Summary"
            ></TabItem>
            <TabItem
              onClick={() => setActiveTab(TALK_TRACKS)}
              isActive={activeTab == TALK_TRACKS}
              label="Talk Track"
            ></TabItem>
          </Tabs>
        </div>
        <div className="w-[640px] flex flex-col gap-1">
          <div>{summaryLabel}</div>
          <div className="border border-grey-300 rounded-[4px] flex flex-col">
            <textarea readOnly className="rounded-[4px] h-[150px] w-full resize-y py-3 px-4" value={summaryContent} />
            <div className="px-4">
              <div className="border-t border-grey-300 flex justify-end">
                <button onClick={handleCopy} className="pt-2 pb-3 px-4 -mr-4">
                  <div className="flex gap-1.5 items-center text-grey-600">
                    <Icon name="copy" size={16} /> Copy
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-tooltip-id="matik-tooltip"
        data-tooltip-content={!aiEnabled ? disabledMessage : null}
        className="border-t border-grey-300 py-4 !px-6 flex justify-end"
      >
        <Button
          status={isGenerating || !aiEnabled || !canRegenerate ? 'disabled' : 'default'}
          onClick={() => onRegenerate(activeTab)}
          category="secondary"
        >
          Regenerate {summaryLabel}
        </Button>
      </div>
    </div>
  );
}
Form.displayName = 'NarrativeSummaryPopup.Form';
Form.propTypes = {
  summary: PropTypes.shape({
    executive_summary: PropTypes.shape({
      status: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    talk_tracks: PropTypes.shape({
      status: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  canRegenerate: PropTypes.bool,
  onRegenerate: PropTypes.func,
};
