import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ColorPicker, toColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import InputText from 'components/lib/InputText';
import PopupMenu from 'components/shared/PopupMenu';

const isValidColor = (text) => {
  return !text || /^#(?:[0-9a-fA-F]{3}){1,2}$/i.test(text);
};

function InputColorSelect({
  placeholder = 'Select Color',
  status = 'default',
  value,
  onChange,
  isReadOnly,
  className,
}) {
  const inputRef = useRef();
  const popupRef = useRef();

  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleColorChange = (newColor) => {
    onChange(newColor.hex);
  };
  const handleTextChange = (newValue) => {
    setInputValue(newValue);

    if (/^#(?:[0-9a-fA-F]{3}){1,2}$/i.test(newValue)) {
      onChange(newValue);
    }
  };
  const handleTextKeyUp = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      popupRef.current.close();
    }
  };
  const togglePicker = () => {
    if (!isReadOnly) {
      popupRef.current.toggle();
    }
  };

  if (status === 'default' && !isValidColor(inputValue)) {
    status = 'error';
  }
  const color = toColor('hex', value || '#000000');

  const swatch =
    value && isValidColor(value) ? (
      <div className="w-4 h-4 inline-block rounded-full border border-grey-300" style={{ backgroundColor: value }} />
    ) : (
      <div className="w-4 h-4 inline-block" />
    );
  return (
    <div className={className}>
      <InputText
        ref={inputRef}
        icon={swatch}
        placeholder={placeholder}
        status={status}
        value={inputValue}
        onChange={handleTextChange}
        isReadOnly={isReadOnly}
        onClick={togglePicker}
        onBlur={() => setInputValue(value)}
        onKeyUp={handleTextKeyUp}
      />
      <PopupMenu ref={popupRef} anchorRef={inputRef} allowClickishPropagation>
        <div className="border border-grey-300 rounded-[10px] inline-block shadow-m">
          <ColorPicker width={320} height={150} color={color} onChange={handleColorChange} hideRGB hideHSV light />
        </div>
      </PopupMenu>
    </div>
  );
}
InputColorSelect.propTypes = {
  placeholder: PropTypes.string,
  status: InputText.propTypes.status,
  isReadOnly: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default InputColorSelect;
