import { Level } from 'react-bulma-components';
import PhotoWithUpload from '../PhotoWithUpload';
import React, { useContext } from 'react';
import { UserContext } from 'components/UserContext';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { cloneDeep } from 'lodash';
import utils from '../../../lib/utils';
import InputColorSelect from 'components/lib/InputColorSelect';

const BrandingForm = (props) => {
  const userContext = useContext(UserContext);

  const onPhotoChange = (updatedBrandingInfo) => {
    props.onBrandingInfoChange(updatedBrandingInfo);
    utils.notify('Logo updated');
  };

  return (
    <div className="branding-container">
      <Level>
        <div className="has-text-black align-center ptm">
          {' '}
          Logo
          {props.isLoading ? (
            <div>
              <Skeleton count={1} width="80px" height="80px" />
            </div>
          ) : (
            <PhotoWithUpload
              uploadText={props.brandingInfo?.logo_url ? 'Change your logo' : 'Add logo'}
              uploadUrl={`/enterprises/${userContext?.user?.enterprise.id}/upload_branding_logo/`}
              onUpload={(data) => onPhotoChange(data.enterprise_settings.branding_info)}
              photoUrl={props.brandingInfo?.logo_url}
            />
          )}
        </div>
      </Level>
      <Level>
        <Level.Item>
          <div className="App container color-name">
            Primary Dark Color
            <div className="description-text">
              {props.darkColorDescription}
              <ColorPicker
                brandingInfo={props.brandingInfo}
                colorName="dark_color"
                onBrandingInfoChange={props.onBrandingInfoChange}
                isLoading={props.isLoading}
              />
            </div>
          </div>
        </Level.Item>
      </Level>

      <Level>
        <Level.Item>
          <div className="App container color-name">
            Accent Color
            <div className="description-text">
              {props.accentColorDescription}
              <ColorPicker
                brandingInfo={props.brandingInfo}
                colorName="light_color"
                onBrandingInfoChange={props.onBrandingInfoChange}
                isLoading={props.isLoading}
              />
            </div>
          </div>
        </Level.Item>
      </Level>

      <Level>
        <Level.Item>
          <div className="App container color-name">
            Link Color
            <div className="description-text">
              <ColorPicker
                brandingInfo={props.brandingInfo}
                colorName="link_color"
                onBrandingInfoChange={props.onBrandingInfoChange}
                isLoading={props.isLoading}
              />
            </div>
          </div>
        </Level.Item>
      </Level>
    </div>
  );
};

BrandingForm.propTypes = {
  darkColorDescription: PropTypes.string,
  accentColorDescription: PropTypes.string,
  brandingInfo: PropTypes.object,
  onBrandingInfoChange: PropTypes.func,
  isLoading: PropTypes.bool,
};
export default BrandingForm;

function ColorPicker({ brandingInfo, onBrandingInfoChange, colorName, isLoading }) {
  if (isLoading) {
    return <Skeleton width="526px" height="40px" />;
  }

  const handleColorChange = (newColor) => {
    let updatedBrandingInfo = cloneDeep(brandingInfo);
    if (!updatedBrandingInfo['theme']) {
      updatedBrandingInfo['theme'] = {};
    }
    updatedBrandingInfo['theme'][colorName] = newColor;
    onBrandingInfoChange(updatedBrandingInfo);
  };

  return <InputColorSelect value={brandingInfo?.theme?.[colorName]} onChange={handleColorChange} />;
}
ColorPicker.displayName = 'BrandingForm.ColorPicker';
ColorPicker.propTypes = {
  brandingInfo: PropTypes.shape({
    theme: PropTypes.object,
  }),
  onBrandingInfoChange: PropTypes.func,
  colorName: PropTypes.string,
  isLoading: PropTypes.bool,
};
