import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bulma-components';
import SmallLoader from '../../../shared/SmallLoader';
import MatikSelect from '../../../shared/MatikSelect';
import ApiReturnFields from './ApiReturnFields';
import ApiSorts from './ApiSorts';
import NotionFilterComponent from './NotionFilterComponent';
import { DynamicContentContext } from '../DynamicContentContext';
import { fetchApiInfoIfNeeded } from '../../../../redux/dataSources/action';
import { parseSortString } from '../../../../lib/apiSorts';

const NotionForm = ({ dataSourceId }) => {
  const dynamicContentContext = useContext(DynamicContentContext);
  const queryObj = dynamicContentContext.query ? JSON.parse(dynamicContentContext.query) : null;
  const { info: apiInfo, isFetching } = useSelector((state) => state.apiInfoByDataSourceId[dataSourceId] || {});
  const dispatch = useDispatch();

  // Initialize query object if it doesn't exist
  const initializedQueryObj = queryObj || {
    database_id: null,
    properties: [],
    filter: null,
    sorts: [],
    page_size: 100,
  };

  // Load database properties on component mount
  useEffect(() => {
    if (initializedQueryObj.database_id) {
      dispatch(fetchApiInfoIfNeeded(dataSourceId, 'database', initializedQueryObj.database_id));
    }
  }, []);
  // Load databases on component mount
  useEffect(() => {
    dispatch(fetchApiInfoIfNeeded(dataSourceId, 'databases'));
  }, [dataSourceId]);

  const handleDatabaseChange = (selected) => {
    if (selected?.id) {
      if (selected.id !== initializedQueryObj.database_id) {
        dispatch(fetchApiInfoIfNeeded(dataSourceId, 'database', selected.id));
      }
      // Update query object with new database and reset other fields
      updateQueryObj({
        database_id: selected.id,
        properties: [],
        filter: null,
        sorts: [],
        page_size: initializedQueryObj.page_size || 100,
      });
    }
  };

  const handleReturnFieldAdd = (field) => {
    const updatedProperties = [...initializedQueryObj.properties, field.value];
    updateQueryObj({
      ...initializedQueryObj,
      properties: updatedProperties,
    });
  };

  const handleReturnFieldRemove = (field) => {
    const updatedProperties = initializedQueryObj.properties.filter((prop) => prop !== field.value);
    updateQueryObj({
      ...initializedQueryObj,
      properties: updatedProperties,
    });
  };

  const handleReturnFieldClear = () => {
    updateQueryObj({
      ...initializedQueryObj,
      properties: [],
    });
  };

  const handleReturnFieldSelectAll = (fields) => {
    const propertyNames = fields.map((field) => field.value);
    updateQueryObj({
      ...initializedQueryObj,
      properties: propertyNames,
    });
  };

  const handleSortsUpdate = (sortStrings) => {
    // Convert sort strings to Notion API format
    const notionSorts = sortStrings.map((sortString) => {
      const parts = parseSortString(sortString);
      const property = parts.field;
      const direction = parts.direction === 'desc' ? 'descending' : 'ascending';

      return {
        property,
        direction,
      };
    });

    updateQueryObj({
      ...initializedQueryObj,
      sorts: notionSorts,
    });
  };

  const handleLimitChange = (e) => {
    const value = parseInt(e.target.value, 10) || 100;
    updateQueryObj({
      ...initializedQueryObj,
      page_size: value,
    });
  };

  const updateQueryObj = (newQueryObj) => {
    dynamicContentContext.onQueryObjectUpdate(newQueryObj);
  };

  if (isFetching) {
    return <SmallLoader />;
  }

  const databases =
    apiInfo?.databases?.results?.map((db) => ({
      id: db.id,
      label: db.title[0]?.text?.content || 'Untitled Database',
      value: db.id,
    })) || [];

  const selectedDatabaseValue = initializedQueryObj.database_id;
  const databaseProperties = Object.entries(apiInfo?.database?.[initializedQueryObj.database_id]?.properties || {}).map(
    ([key, value]) => ({
      id: key,
      name: key,
      type: value.type,
      value: key,
    }),
  );
  const fieldOptions = databaseProperties.map((prop) => ({
    name: prop.name,
    type: prop.type,
    selected: initializedQueryObj.properties.includes(prop.name),
    hideable: false,
    hidden: false,
  }));

  return (
    <div>
      <Form.Field>
        <Form.Label>Select Database</Form.Label>
        <Form.Control>
          <MatikSelect
            options={databases}
            value={selectedDatabaseValue}
            onChange={handleDatabaseChange}
            placeholderText="Select a Notion database"
          />
        </Form.Control>
      </Form.Field>

      {initializedQueryObj.database_id && (
        <>
          <ApiReturnFields
            fieldOptions={fieldOptions}
            onReturnFieldAdd={handleReturnFieldAdd}
            onReturnFieldRemove={handleReturnFieldRemove}
            onReturnFieldClear={handleReturnFieldClear}
            onReturnFieldSelectAll={handleReturnFieldSelectAll}
            returnFields={initializedQueryObj.properties}
            canCreateReturnField={false}
          />

          <NotionFilterComponent
            properties={databaseProperties}
            filter={initializedQueryObj.filter}
            onChange={(newFilter) => {
              updateQueryObj({
                ...initializedQueryObj,
                filter: newFilter,
              });
            }}
          />

          <ApiSorts
            sortsArray={initializedQueryObj.sorts.map(
              (sort) => `${sort.property} ${sort.direction === 'descending' ? 'desc' : 'asc'}`,
            )}
            allFields={databaseProperties.map((prop) => prop.name)}
            onSortsUpdate={handleSortsUpdate}
            isReadOnly={dynamicContentContext.isReadOnly}
          />

          <Form.Field>
            <Form.Label>Limit Results</Form.Label>
            <Form.Control>
              <Form.Input
                type="number"
                value={initializedQueryObj.page_size || 100}
                onChange={handleLimitChange}
                min="1"
                max="100"
              />
            </Form.Control>
            <Form.Help>Maximum: 100</Form.Help>
          </Form.Field>
        </>
      )}
    </div>
  );
};

NotionForm.propTypes = {
  dataSourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  apiInfo: PropTypes.object,
  isFetching: PropTypes.bool,
  fetchApiInfoIfNeeded: PropTypes.func,
};

export default NotionForm;
