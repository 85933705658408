import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import { Section, Level } from 'react-bulma-components';
import unknown_icon from '../../../images/unknown.svg';
import success_check from '../../../images/success_check.svg';
import Button from 'components/lib/Button';
import { openFullScreenModal, closeFullScreenModal } from 'redux/ui/action';
import recipient_icon_small from '../../../images/dynamic_content_contact_small.svg';
import EmailSubjectModal from 'components/producer/email/EmailSubjectModal';
import EmailFromModal from 'components/producer/email/EmailFromModal';
import EmailRecipientModal from 'components/producer/email/EmailRecipientModal';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { FullScreenModalFooter } from 'components/shared/modal/FullScreenModalFooter';
import EmailSubjectFormField from 'components/producer/email/EmailSubjectFormField';
import EmailRecipientFormField from './EmailRecipientFormField';
import EmailSenderFormField from './EmailSenderFormField';
import { cloneDeep } from 'lodash';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import { useOneDynamicContent } from '../../../lib/hooks/useDynamicContent';
import dynamicContentLib from 'lib/dynamicContent';
function EmailFieldsSetup({
  currentTemplate,
  allDynamicContent,
  canEdit,
  inModal,
  onTemplateUpdateWithServerCall,
  emailHtml,
  sendgridInfo,
  openAuthModal,
  enterprise,
  onContentClick,
  onInputClick,
  dynamicRecipientsName,
  setHasUnsavedChanges,
}) {
  const [subject, setSubject] = useState(currentTemplate.source.subject);
  const [recipients, setRecipients] = useState({
    bcc: currentTemplate.source.recipients.bcc || [],
    bccType: currentTemplate.source.recipients.bccType || Constants.RecipientFieldTypes.STATIC,
    cc: currentTemplate.source.recipients.cc || [],
    ccType: currentTemplate.source.recipients.ccType || Constants.RecipientFieldTypes.STATIC,
    dynamicRecipients: currentTemplate.source.recipients.dynamicRecipients || '',
    bulkedInput: currentTemplate.source.recipients.bulkedInput || '',
  });
  const [from, setFrom] = useState({
    name: currentTemplate.source.from.name,
    fromEmail: currentTemplate.source.from.fromEmail,
    replyEmail: currentTemplate.source.from.replyEmail,
    senderType: currentTemplate.source.from.senderType || Constants.RecipientFieldTypes.STATIC,
    senderContent: currentTemplate.source.from.senderContent,
    fallbackEmail: currentTemplate.source.from.fallbackEmail || '',
  });
  const [currentDynamicRecipientsName, setCurrentDynamicRecipientsName] = useState(dynamicRecipientsName || '');
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [formHasError, setFormHasError] = useState(false);
  const [fromEmailErrorMsg, setFromEmailErrorMsg] = useState('');
  const [replyEmailErrorMsg, setReplyEmailErrorMsg] = useState('');
  const [fallbackEmailErrorMsg, setFallbackEmailErrorMsg] = useState('');
  const [inputErrorMsg, setInputErrorMsg] = useState('');
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const ldClient = useLDClient();
  const enableDynamicSenderFeatures = ldClient?.variation('enable-dynamic-sender-features', false);
  const disablePrimaryButton = !isChanged || formHasError;

  useEffect(() => {
    setHasUnsavedChanges?.(!disablePrimaryButton);
  }, [disablePrimaryButton]);

  useEffect(() => {
    setCurrentDynamicRecipientsName(dynamicRecipientsName);
  }, [dynamicRecipientsName]);

  const { dynamicContent } = useOneDynamicContent(recipients.dynamicRecipients);

  const allRecipientInputs = Object.values(dynamicContent?.parameters || {});
  const validRecipientInputs = allRecipientInputs.filter((content) => content.source_type !== 'matik_user');
  const inputRequired = validRecipientInputs.length > 0;

  const renderRecipientField = () => {
    let ccList = [];
    if (Array.isArray(recipients.cc)) {
      ccList = recipients.cc.map((email) => (
        <span className="recipient-pill" key={email}>
          cc:&nbsp;{email}
        </span>
      ));
    }

    return (
      <div className="recipient-input-field">
        <span className="recipient-pill dynamic">
          <img src={recipient_icon_small} />
          &nbsp;{`{{${currentDynamicRecipientsName}}}`}
        </span>
        {ccList}
      </div>
    );
  };

  const onFromFieldChange = (fieldName, fieldValue, fieldType = '') => {
    let fromObject = { ...from };
    fromObject[fieldName] = fieldValue;
    if (fieldType === Constants.RecipientFieldTypes.STATIC) {
      fromObject.senderContent = null;
      fromObject.fallbackEmail = '';
    } else if (fieldType === 'dynamic') {
      fromObject.name = '';
      fromObject.fromEmail = '';
      fromObject.replyEmail = '';
    }
    setFrom(fromObject);
    setIsChanged(true);
  };

  const validateFromField = () => {
    if (
      from.senderType === Constants.RecipientFieldTypes.STATIC &&
      from.fromEmail.length &&
      !utils.isEmailValid(from.fromEmail)
    ) {
      setFromEmailErrorMsg(`${from.fromEmail} is not a valid email address.`);
      setIsLoading(false);
      return false;
    }
    setFromEmailErrorMsg('');
    if (
      from.senderType === Constants.RecipientFieldTypes.STATIC &&
      from.replyEmail.length &&
      !utils.isEmailValid(from.replyEmail)
    ) {
      setReplyEmailErrorMsg(`${from.replyEmail} is not a valid email address.`);
      setIsLoading(false);
      return false;
    }
    setReplyEmailErrorMsg('');
    if (
      from.senderType !== Constants.RecipientFieldTypes.STATIC &&
      from.fallbackEmail.length &&
      !utils.isEmailValid(from.fallbackEmail)
    ) {
      setFallbackEmailErrorMsg(`${from.fallbackEmail} is not a valid email address.`);
      setIsLoading(false);
      return false;
    }
    setFallbackEmailErrorMsg('');
    return true;
  };

  const validateRecipientsField = () => {
    if (recipients.dynamicRecipients && inputRequired && !recipients.bulkedInput) {
      setInputErrorMsg('Please select an input option.');
      setIsLoading(false);
      return false;
    }
    setInputErrorMsg('');
    return true;
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (!validateFromField() || !validateRecipientsField()) {
      return;
    }
    const updatedTemplate = { ...currentTemplate };

    const tagsArray = updateTagsArray();

    const subjectText = subject;
    const subjectDynamicContentTags = scanForDcTags(subjectText);

    const dedupedArray = [...new Set(tagsArray.concat(subjectDynamicContentTags))];

    updatedTemplate.newTags = dedupedArray;
    updatedTemplate.source.subject = subject;
    updatedTemplate.source.recipients = recipients;
    updatedTemplate.source.from = from;
    await onTemplateUpdateWithServerCall(updatedTemplate);
    setIsLoading(false);
    setIsChanged(false);
  };

  const scanForDcTags = (text) => {
    const dynamicContentTags = text
      .split('{{')
      .filter((substringWithDcTag) => substringWithDcTag.indexOf('}}') > -1 && substringWithDcTag.indexOf('{') !== 0)
      .map((bracketedDynamicContent) => bracketedDynamicContent.split('}}')[0]);

    const filteredDynamicContentTags = dynamicContentTags.filter((tag) => tag !== '');
    return filteredDynamicContentTags;
  };

  const updateTagsArray = () => {
    const tagsArray = cloneDeep(currentTemplate.slides[0].dynamic_content_tags) || [];

    const existingDynamicRecipientsName = dynamicContentLib.getDynamicContentNameById(
      parseInt(currentTemplate.source.recipients.dynamicRecipients),
      allDynamicContent,
    );
    const currentDynamicRecipientIndex = tagsArray.indexOf(existingDynamicRecipientsName);
    if (currentDynamicRecipientIndex > -1) {
      tagsArray.splice(currentDynamicRecipientIndex, 1);
    }
    const newDynamicRecipientsName = dynamicContentLib.getDynamicContentNameById(
      parseInt(recipients.dynamicRecipients),
      allDynamicContent,
    );
    // it's easiest for input sorting if dynamic recipients is first in the list
    tagsArray.unshift(newDynamicRecipientsName);

    const existingSenderContentName = dynamicContentLib.getDynamicContentNameById(
      currentTemplate.source.from.senderContent,
      allDynamicContent,
    );
    const currentSenderContentIndex = tagsArray.indexOf(existingSenderContentName);
    const newDynamicSenderName = dynamicContentLib.getDynamicContentNameById(from.senderContent, allDynamicContent);
    if (currentSenderContentIndex > -1) {
      tagsArray.splice(currentSenderContentIndex, 1);
    }

    if (newDynamicSenderName) {
      tagsArray.push(newDynamicSenderName);
    }

    let existingCcContentName;
    if (
      currentTemplate.source.recipients.ccType !== Constants.RecipientFieldTypes.STATIC &&
      typeof currentTemplate.source.recipients.cc === 'number'
    ) {
      existingCcContentName = dynamicContentLib.getDynamicContentNameById(
        currentTemplate.source.recipients.cc,
        allDynamicContent,
      );
    }
    if (existingCcContentName && existingCcContentName !== newDynamicRecipientsName) {
      const currentDynamicCcContentIndex = tagsArray.indexOf(existingCcContentName);
      if (currentDynamicCcContentIndex > -1) {
        tagsArray.splice(currentDynamicCcContentIndex, 1);
      }
    }
    let newDynamicCcContentName = '';
    if (recipients.ccType !== Constants.RecipientFieldTypes.STATIC) {
      newDynamicCcContentName = dynamicContentLib.getDynamicContentNameById(recipients.cc, allDynamicContent);
      if (newDynamicCcContentName) {
        tagsArray.push(newDynamicCcContentName);
      }
    }

    let existingBccContentName;
    if (
      currentTemplate.source.recipients.bccType !== Constants.RecipientFieldTypes.STATIC &&
      typeof currentTemplate.source.recipients.bcc === 'number'
    ) {
      existingBccContentName = dynamicContentLib.getDynamicContentNameById(
        currentTemplate.source.recipients.bcc,
        allDynamicContent,
      );
    }
    if (
      existingBccContentName &&
      existingBccContentName !== newDynamicRecipientsName &&
      existingBccContentName !== newDynamicCcContentName
    ) {
      const currentDynamicBccContentIndex = tagsArray.indexOf(existingBccContentName);
      if (currentDynamicBccContentIndex > -1) {
        tagsArray.splice(currentDynamicBccContentIndex, 1);
      }
    }
    if (recipients.bccType !== Constants.RecipientFieldTypes.STATIC) {
      const newDynamicBccContentName = dynamicContentLib.getDynamicContentNameById(recipients.bcc, allDynamicContent);
      if (newDynamicBccContentName) {
        tagsArray.push(newDynamicBccContentName);
      }
    }

    return tagsArray;
  };

  const onRecipientFieldChange = (field, data, dynamicRecipientsName = '') => {
    let recipientObject = { ...recipients };
    recipientObject[field] = data;
    if (dynamicRecipientsName) {
      setCurrentDynamicRecipientsName(dynamicRecipientsName);
      recipientObject['bulkedInput'] = '';
    }
    if (field === 'bulkedInput' && data) {
      setInputErrorMsg('');
      // if there's no bulk input then cc/bcc/sender can't be dynamically bulked so we have to reset those
      if (recipients.dynamicRecipients && data === 'None') {
        if (recipients.ccType === Constants.RecipientFieldTypes.DYNAMIC_BULKED) {
          recipientObject.ccType = Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED;
          recipientObject.cc = [];
        }
        if (recipients.bccType === Constants.RecipientFieldTypes.DYNAMIC_BULKED) {
          recipientObject.bccType = Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED;
          recipientObject.bcc = [];
        }
        if (from.senderType === Constants.RecipientFieldTypes.DYNAMIC_BULKED) {
          let fromObject = { ...from };
          fromObject.senderType = Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED;
          fromObject.senderContent = null;
          setFrom(fromObject);
        }
      }
    }
    setRecipients(recipientObject);
    setIsChanged(true);
  };

  const onClose = () => {
    resetFormValues();
    dispatch(closeFullScreenModal());
  };

  const resetFormValues = () => {
    setSubject(currentTemplate.source.subject);
    setFrom(currentTemplate.source.from);
    setRecipients(currentTemplate.source.recipients);
  };

  const onSubjectFieldChange = (value) => {
    setSubject(value);
    setIsChanged(true);
  };

  const subjectImage = subject.length > 0 ? success_check : unknown_icon;
  const recipientImage = recipients.dynamicRecipients ? success_check : unknown_icon;

  const recipientList = renderRecipientField();
  const fromImage = from.fromEmail !== '' && from.replyEmail !== '' && from.name !== '' ? success_check : unknown_icon;
  const fromPartiallyComplete = [from.fromEmail !== '', from.replyEmail !== '', from.name !== ''].some(
    (ele) => ele === true,
  );
  const fromName = from.name ? `${from.name} · ` : '';
  const fromEmail = from.fromEmail ? `${from.fromEmail}` : '';
  const replyEmail = from.replyEmail ? ` · Reply to ${from.replyEmail}` : '';
  const showButtons = canEdit && !inModal;

  const oldSetupFields = (
    <Section className="email-form-container">
      <Level className="email-form-field">
        <Level.Side align="left" className="email-form-label">
          <img src={subjectImage}></img>
          <p>Subject</p>
        </Level.Side>
        <Level.Item
          className="email-send-field"
          onClick={showButtons ? () => dispatch(openFullScreenModal('emailSubjectModal')) : null}
        >
          {subjectImage === success_check ? (
            <TextOverflowWithTooltip className="email-form-input text-overflow-ellipsis" text={subject} />
          ) : (
            <div className="email-form-input">
              <span>Add subject</span>
            </div>
          )}
        </Level.Item>
        <Level.Side align="right">
          {showButtons && (
            <Button category="secondary" onClick={() => dispatch(openFullScreenModal('emailSubjectModal'))}>
              {subjectImage === success_check ? 'Edit Subject' : 'Add Subject'}
            </Button>
          )}
        </Level.Side>
      </Level>
      <Level className="email-form-field">
        <Level.Side align="left" className="email-form-label">
          <img src={recipientImage}></img>
          <p>To</p>
        </Level.Side>
        <Level.Item
          className="email-send-field"
          onClick={showButtons ? () => dispatch(openFullScreenModal('emailRecipientModal')) : null}
        >
          {recipientImage === success_check ? (
            recipientList
          ) : (
            <div className="email-form-input">
              <span>Add recipients</span>
            </div>
          )}
        </Level.Item>
        <Level.Side align="right">
          {showButtons && (
            <Button category="secondary" onClick={() => dispatch(openFullScreenModal('emailRecipientModal'))}>
              {recipientImage === success_check ? 'Edit Recipients' : 'Add Recipients'}
            </Button>
          )}
        </Level.Side>
      </Level>
      <Level className="email-form-field">
        <Level.Side align="left" className="email-form-label">
          <img src={fromImage}></img>
          <p>From</p>
        </Level.Side>
        <Level.Item
          className="email-send-field"
          onClick={showButtons ? () => dispatch(openFullScreenModal('emailFromModal')) : null}
        >
          {fromPartiallyComplete ? (
            <TextOverflowWithTooltip
              className="email-form-input text-overflow-ellipsis"
              text={fromName + fromEmail + replyEmail}
            />
          ) : (
            <div className="email-form-input">
              <span>Add from</span>
            </div>
          )}
        </Level.Item>
        <Level.Side align="right">
          {showButtons && (
            <Button category="secondary" onClick={() => dispatch(openFullScreenModal('emailFromModal'))}>
              {fromPartiallyComplete ? 'Edit From' : 'Add From'}
            </Button>
          )}
        </Level.Side>
      </Level>
      <EmailSubjectModal
        show={ui.fullScreenModal?.name === 'emailSubjectModal'}
        onClose={onClose}
        currentTemplate={currentTemplate}
        subject={subject}
        onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
        onChange={onSubjectFieldChange}
        dynamicRecipientsName={dynamicRecipientsName}
        emailHtml={emailHtml}
      />
      <EmailRecipientModal
        show={ui.fullScreenModal?.name === 'emailRecipientModal'}
        currentTemplate={currentTemplate}
        onClose={onClose}
        onRecipientFieldChange={onRecipientFieldChange}
        dynamicRecipientsId={recipients.dynamicRecipients}
        ccRecipients={recipients.cc}
        onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
        allDynamicContent={allDynamicContent}
        entityType="dynamic_content"
      />
      <EmailFromModal
        show={ui.fullScreenModal?.name === 'emailFromModal'}
        onClose={onClose}
        onChange={onFromFieldChange}
        currentTemplate={currentTemplate}
        name={from.name}
        fromEmail={from.fromEmail}
        replyEmail={from.replyEmail}
        onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
        sendgridInfo={sendgridInfo}
        openAuthModal={openAuthModal}
        enterprise={enterprise}
      />
    </Section>
  );

  const newSetupFields = (
    <div className="email-settings-tab">
      <div className="email-settings-tab-content">
        <div className="email-settings-center">
          <EmailRecipientFormField
            recipients={recipients}
            onDynamicRecipientsChange={onRecipientFieldChange}
            dynamicRecipientsId={recipients.dynamicRecipients}
            allDynamicContent={allDynamicContent}
            entityType="dynamic_content"
            dynamicRecipientsName={currentDynamicRecipientsName}
            onContentClick={onContentClick}
            onInputClick={onInputClick}
            setFormHasError={setFormHasError}
            inputErrorMsg={inputErrorMsg}
            startingCcType={currentTemplate.source.recipients.ccType}
            startingBccType={currentTemplate.source.recipients.bccType}
          />
          <EmailSubjectFormField subject={subject} onSubjectFieldChange={onSubjectFieldChange} />
          <EmailSenderFormField
            onFromFieldChange={onFromFieldChange}
            from={from}
            setFormHasError={setFormHasError}
            sendgridInfo={sendgridInfo}
            fromEmailErrorMsg={fromEmailErrorMsg}
            replyEmailErrorMsg={replyEmailErrorMsg}
            openAuthModal={openAuthModal}
            enterprise={enterprise}
            bulkedInput={recipients.bulkedInput}
            allDynamicContent={allDynamicContent}
            onContentClick={onContentClick}
            startingSenderType={currentTemplate.source.from.senderType}
            fallbackEmailErrorMsg={fallbackEmailErrorMsg}
            dynamicSenderDisabled={
              !recipients.dynamicRecipients || (recipients.dynamicRecipients && !recipients.bulkedInput)
            }
          />
        </div>
        <FullScreenModalFooter
          primaryButtonText="Save"
          primaryButtonOnClick={onSubmit}
          primaryButtonDisabled={disablePrimaryButton}
          primaryButtonLoading={isLoading}
        />
      </div>
    </div>
  );

  return <>{enableDynamicSenderFeatures ? newSetupFields : oldSetupFields}</>;
}

EmailFieldsSetup.propTypes = {
  currentTemplate: PropTypes.object,
  allDynamicContent: PropTypes.object,
  canEdit: PropTypes.bool,
  inModal: PropTypes.bool,
  onDynamicRecipientsChange: PropTypes.func,
  onTemplateUpdateWithServerCall: PropTypes.func,
  emailHtml: PropTypes.string,
  sendgridInfo: PropTypes.object,
  openAuthModal: PropTypes.func,
  enterprise: PropTypes.object,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
  dynamicRecipientsName: PropTypes.string,
  setHasUnsavedChanges: PropTypes.func,
};

export default EmailFieldsSetup;
