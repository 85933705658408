import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../lib/Icon';
import Button from '../lib/Button';
import ButtonGroup from '../lib/ButtonGroup';

function AddRemoveButtons(props) {
  return (
    <React.Fragment>
      {props.children}
      <div className="max-h-9">
        <ButtonGroup width="hug">
          <Button
            type="button"
            onClick={(e) => props.removeEntity(e, props.idx)}
            category="secondary"
            size="small"
            aria-label="Remove Entity"
            disabled={props.isReadOnly}
          >
            <Icon name="trash_can" size={20} />
          </Button>
          {props.includeAdd ? (
            <Button
              type="button"
              onClick={props.addEntity}
              category="secondary"
              size="small"
              aria-label="Add Entity"
              disabled={props.isReadOnly}
            >
              <Icon name="plus" size={20} />
            </Button>
          ) : (
            <div className="w-12"></div>
          )}
        </ButtonGroup>
      </div>
    </React.Fragment>
  );
}

AddRemoveButtons.propTypes = {
  children: PropTypes.any,
  includeAdd: PropTypes.bool,
  idx: PropTypes.number,
  isReadOnly: PropTypes.bool,
  removeEntity: PropTypes.func,
  addEntity: PropTypes.func,
};

export default AddRemoveButtons;
