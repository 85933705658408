import ApiReturnFields from './ApiReturnFields';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function SimpleReturnFields({
  allFields,
  returnFields,
  returnFieldMapping,
  onReturnFieldAdd,
  onReturnFieldRemove,
  onReturnFieldSelectAll,
  onReturnFieldClear,
  onReturnFieldMappingUpdate,
  dataSourceName,
  removeToolTip,
  noOptionsMessage,
  canCreateReturnField = false,
  errorMessage = '',
}) {
  const [manuallyCreatedFields, setManuallyCreatedFields] = useState(new Set());

  let inErrorState = !!errorMessage;

  const allFieldsConcatReturn = [...allFields, ...returnFields];
  const allFieldsUnionReturn = [...new Set(allFieldsConcatReturn)];

  const fieldOptions = allFieldsUnionReturn.map((fieldName) => {
    const selected = returnFields.includes(fieldName);
    const fieldInErrorState =
      !allFields.includes(fieldName) && returnFields.includes(fieldName) && !manuallyCreatedFields.has(fieldName);

    if (fieldInErrorState) {
      inErrorState = true;
    }

    const isManuallyCreated = manuallyCreatedFields.has(fieldName);

    return {
      name: fieldName,
      selected: selected,
      inErrorState: fieldInErrorState,
      errorMessage: fieldInErrorState
        ? `This field cannot be found in ${dataSourceName ?? 'Data Source'}. Remove it or replace it with a valid field`
        : '',
      hidden: false,
      hideable: false,
      hideToolTip: '',
      removable: true,
      removeToolTip: isManuallyCreated ? 'This is a manually created field. Remove if not needed.' : removeToolTip,
      iconName: isManuallyCreated ? 'pencil' : null,
      iconToolTip: isManuallyCreated ? 'Manually created field - will be validated when querying Tableau' : '',
      alias: returnFieldMapping[fieldName] || '',
    };
  });

  const handleReturnFieldAdd = (option) => {
    if (option.action === 'create-option') {
      const fieldValue = option.option.value;
      const newSet = new Set([...manuallyCreatedFields, fieldValue]);
      setManuallyCreatedFields(newSet);
      onReturnFieldAdd(option.option);
    } else {
      onReturnFieldAdd(option);
    }
  };

  const handleReturnFieldRemove = (option) => {
    if (manuallyCreatedFields.has(option.value)) {
      const updatedFields = new Set([...manuallyCreatedFields]);
      updatedFields.delete(option.value);
      setManuallyCreatedFields(updatedFields);
    }
    onReturnFieldRemove(option);
  };

  const handleReturnFieldClear = () => {
    setManuallyCreatedFields(new Set());
    onReturnFieldClear();
  };

  return (
    <ApiReturnFields
      returnFields={returnFields}
      inErrorState={inErrorState}
      errorMessage={errorMessage || (inErrorState ? `Contains fields that cannot be found in ${dataSourceName}` : '')}
      fieldOptions={fieldOptions}
      canHide={false}
      onReturnFieldAdd={handleReturnFieldAdd}
      onReturnFieldRemove={handleReturnFieldRemove}
      onReturnFieldSelectAll={onReturnFieldSelectAll}
      onReturnFieldClear={handleReturnFieldClear}
      onReturnFieldHide={null}
      onReturnFieldShow={null}
      onReturnFieldsMappingUpdate={onReturnFieldMappingUpdate}
      isReadOnly={false}
      noOptionsMessage={noOptionsMessage}
      canCreateReturnField={canCreateReturnField}
    />
  );
}

SimpleReturnFields.propTypes = {
  allFields: PropTypes.array,
  returnFields: PropTypes.array,
  returnFieldMapping: PropTypes.object,
  onReturnFieldAdd: PropTypes.func,
  onReturnFieldRemove: PropTypes.func,
  onReturnFieldSelectAll: PropTypes.func,
  onReturnFieldClear: PropTypes.func,
  onReturnFieldMappingUpdate: PropTypes.func,
  dataSourceName: PropTypes.string,
  removeToolTip: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  canCreateReturnField: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default SimpleReturnFields;
