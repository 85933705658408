import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, map } from 'lodash';
import API from '../../lib/api';
import { MAlert } from './Alerts';
import TestInputsModal from './TestInputsModal';
import TestInputsForm from './TestInputsForm';
import LongRequest from '../../lib/longRequest';
import Constants from '../Constants';
import { isEmpty } from 'lodash';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import { connect } from 'react-redux';
import { compose } from 'redux';

const WithTestQuery = (WrappedComponent) => {
  return class Inner extends Component {
    static propTypes = {
      validateFormData: PropTypes.func,
      inputsInQueryString: PropTypes.object,
      dataSources: PropTypes.array,
      dataSource: PropTypes.object,
      dynamicContent: PropTypes.object,
      database: PropTypes.string,
      query: PropTypes.string,
      schema: PropTypes.string,
      dynamicContentType: PropTypes.string,
      entityType: PropTypes.string,
      entityId: PropTypes.any,
      updateTestResult: PropTypes.func,
      method: PropTypes.string,
      dynamicContentById: PropTypes.object,
      loadDynamicContentFromNames: PropTypes.func,
      ui: PropTypes.object,
      openModal: PropTypes.func,
      closeModal: PropTypes.func,
      forwardedRef: PropTypes.any,
    };
    static displayName = 'WithTestQuery';

    constructor(props) {
      super(props);

      this.state = {
        testIsLoading: false,
        showTestInputsModal: false,
        testQueryString: '',
      };
    }

    componentDidMount() {
      document.addEventListener('keydown', (e) => this.executeShortcut(e));
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.executeShortcut);
    }

    render() {
      let testButtonText = '';
      if (this.props.dynamicContentType === Constants.DynamicContentTypes.CONDITIONAL) {
        testButtonText = 'Test Condition';
      } else if (this.props.method === Constants.DynamicContentMethods.QUERY) {
        testButtonText = 'Test Query';
      } else if (this.props.method === Constants.DynamicContentMethods.INSIGHTS) {
        testButtonText = 'Test Insights';
      } else if (this.props.method === Constants.DynamicContentMethods.API) {
        testButtonText = 'Test API';
      }

      const { forwardedRef, ...componentProps } = this.props;
      return (
        <React.Fragment>
          <WrappedComponent
            ref={forwardedRef}
            {...componentProps}
            {...this.state}
            testQuery={this.testQuery}
            setTestIsLoadingToVal={this.setTestIsLoadingToVal}
            onKeyDown={this.executeShortcut}
          />
          {this.props.dataSource && (
            <TestInputsModal
              show={this.props.ui.modal?.name === 'queryTestInputsModal'}
              onClose={this.closeTestInputsModal}
              title={testButtonText}
            >
              <TestInputsForm
                inputs={this.props.inputsInQueryString}
                onCancel={this.closeTestInputsModal}
                queryString={this.state.testQueryString}
                onFormSubmit={this.onTestInputsSubmit}
                dataSource={this.props.dataSource}
                entityType={this.props.entityType}
                submitButtonText={testButtonText}
              />
            </TestInputsModal>
          )}
        </React.Fragment>
      );
    }

    testQuery = (e, queryString, dataSource, dynamicContent, authToken) => {
      e.preventDefault();
      if (!dataSource) {
        dataSource = this.props.dataSource;
      }
      if (!dynamicContent) {
        dynamicContent = this.props.dynamicContent;
      }
      if (!this.props.validateFormData || this.props.validateFormData(true, false, queryString)) {
        if (this.shouldShowTestInputsModal()) {
          this.setState({ testQueryString: queryString });
          this.props.openModal('queryTestInputsModal');
        } else {
          this.setState({ testIsLoading: true });
          this.submitTestQuery(queryString, {}, dataSource, dynamicContent, authToken);
        }
      }
    };

    shouldShowTestInputsModal = () => {
      return this.props.inputsInQueryString && Object.values(this.props.inputsInQueryString).length > 0;
    };

    onTestInputsSubmit = (queryString, inputValues) => {
      const updatedInputValues = cloneDeep(inputValues);
      for (const paramName in updatedInputValues) {
        if (this.props.inputsInQueryString[paramName]?.type) {
          updatedInputValues[paramName].type = this.props.inputsInQueryString[paramName].type;
        }
      }
      this.props.closeModal();
      this.setState({ testIsLoading: true });
      this.submitTestQuery(queryString, updatedInputValues, this.props.dataSource, this.props.dynamicContent);
    };

    closeTestInputsModal = () => {
      this.props.closeModal();
    };

    setTestIsLoadingToVal = (val) => {
      this.setState({ testIsLoading: val });
    };

    submitTestQuery = (queryString, inputValues, dataSource, dynamicContent, authToken) => {
      if (!dataSource) {
        if (this.props.dataSource) {
          dataSource = this.props.dataSource;
        } else {
          MAlert('Unrecognized data source', 'Error', 'error');
          this.setState({ testIsLoading: false });
          return;
        }
      }

      const updatedInputValues = cloneDeep(inputValues);
      for (const key in updatedInputValues) {
        const inputValue = updatedInputValues[key];
        if (inputValue.mappedOptions && !isEmpty(inputValue.mappedOptions)) {
          if (Array.isArray(inputValue.value)) {
            const newInputValue = inputValue.value.map((val) => inputValue.mappedOptions[val]);
            inputValue.value = newInputValue;
          } else {
            inputValue.value = inputValue.mappedOptions[inputValue.value];
          }
        }
      }
      const queryData = {
        data_source: { id: dataSource.id },
        database: this.props.database,
        schema: this.props.schema,
        dynamic_content_type: dynamicContent ? dynamicContent.dynamic_content_method : this.props.dynamicContentType,
        query_string: queryString,
        values_by_param_name: updatedInputValues,
        auth_token: authToken || '',
      };
      const startTime = Date.now();
      const onResponse = (response, onComplete) => {
        if (response.data.status === 'success') {
          this.setState({
            testIsLoading: false,
          });
          const timeElapsed = Date.now() - startTime;

          const testResult = response.data;
          let originalQuery;

          // If data source is salesforce, and query is JSON check for aliases
          if (dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce) {
            try {
              originalQuery = JSON.parse(this.props.query);
            } catch {
              originalQuery = null;
            }
            if (originalQuery) {
              const returnFieldMapping = originalQuery.return_field_mapping;
              const renamedColumns = map(testResult.result[0], (columnName) => {
                if (returnFieldMapping && Object.keys(returnFieldMapping).includes(columnName)) {
                  return returnFieldMapping[columnName];
                } else {
                  return columnName;
                }
              });
              if (Array.isArray(testResult.result)) {
                const firstRow = testResult.result.shift();
                testResult.result.unshift(renamedColumns);
                if (
                  originalQuery.source === 'reports' &&
                  originalQuery.selectedReportMetadata &&
                  originalQuery.selectedReportMetadata.reportFormat === 'MATRIX'
                ) {
                  testResult.result.unshift(firstRow);
                  testResult['removeHeader'] = true;
                }
              }
            }
          }
          testResult['timeElapsed'] = timeElapsed;
          this.props.updateTestResult(testResult);
          const testResultMsg = document.getElementById('test-result-msg');
          if (testResultMsg) {
            testResultMsg.scrollIntoView({ behavior: 'smooth' });
          }

          API.track('test_query_success', {
            elapsed_ms: timeElapsed,
            entity_type: this.props.entityType,
            entity_id: this.props.entityId,
          });
          onComplete();
        } else if (response.data.status === 'error') {
          this.setState({
            testIsLoading: false,
          });
          this.props.updateTestResult(response.data);
          onComplete();
        }
      };
      const longRequest = new LongRequest('/queries');
      longRequest.post(queryData, onResponse, this.submitError, undefined, undefined, `test/${dataSource.type}`);
    };

    executeShortcut = (e) => {
      if (e.shiftKey && e.key == 'Enter') {
        if (this.props.dataSource && this.props.query) {
          this.testQuery(e, this.props.query, this.props.dataSource);
        }
      }
    };

    submitError = () => {
      // TODO (zak): Probably want to have some sort of error messaging here.
      this.setState({ testIsLoading: false });
    };
  };
};

export default compose(connect(mapUiStateToProps, mapDispatchToProps), WithTestQuery);
