import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ConsumerNav from './ConsumerNav';
import API from 'lib/api';
import Constants from 'components/Constants';
import UserProfile from '../shared/UserProfile';
import PropTypes from 'prop-types';
import PresentationsTab from '../shared/presentations/PresentationsTab';
import FlowsTab from '../shared/flows/FlowsTab';
import CreateTab from './CreateTab';
import { UserContext } from '../UserContext';
import WithFetchUser from '../shared/WithFetchUser';
import WithPresentationContextProvider from '../shared/presentations/WithPresentationContextProvider';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

class ConsumerMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    API.get(
      '/users/me/',
      (response) => {
        const user = response.data;
        this.setState({ user: user, isLoading: false });
      },
      API.defaultError,
    );
  }

  render() {
    let body = null;
    let tab = this.props.match.params.tab || Constants.Tabs.TEMPLATES;
    if (tab === Constants.Tabs.TEMPLATES) {
      body = <CreateTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.GENERATED) {
      body = <PresentationsTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.FLOWS) {
      body = <FlowsTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.USER_PROFILE) {
      body = (
        <UserProfile
          id={this.props.user ? this.props.user.id : 0}
          user={this.props.user}
          updateUser={this.props.updateUser}
          consumerProfile={true}
          match={this.props.match}
        />
      );
    } else {
      return <Redirect to="/create" />;
    }

    return (
      <>
        <UserContext.Provider value={{ user: this.props.user, updateUser: this.props.updateUser, isEndUserView: true }}>
          <ConsumerNav activeTab={tab} />
          {body}
        </UserContext.Provider>
      </>
    );
  }
}

ConsumerMain.propTypes = {
  match: PropTypes.any,
  updateUser: PropTypes.func,
  user: PropTypes.object,
  flags: PropTypes.object,
};

export default WithFetchUser(WithPresentationContextProvider(withLDConsumer()(ConsumerMain)));
