import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as GridViewIcon } from 'svg/grid_view.svg';
import { ReactComponent as SingleSlideViewIcon } from 'svg/single_slide_view.svg';

export default function SlideViewToggle({ view, onChange }) {
  return (
    <span className="view-buttons">
      <span className={`view-button top ${view === 'grid' ? 'active' : ''}`} onClick={() => onChange('grid')}>
        <GridViewIcon className="grid-view-icon" />
      </span>
      <span className={`view-button bottom ${view === 'single' ? 'active' : ''}`} onClick={() => onChange('single')}>
        <SingleSlideViewIcon className="single-slide-view-icon disabled-fill-rect" />
      </span>
    </span>
  );
}
SlideViewToggle.propTypes = {
  view: PropTypes.oneOf(['grid', 'single']),
  onChange: PropTypes.func,
};
