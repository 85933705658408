import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { find } from 'lodash';
import utils from 'lib/utils';
import InputWithError from 'components/shared/InputWithError';
import { CreatableInputOnly, Select } from 'components/shared/FormSelect';

function OAuthAuth({ auth, onAuthChange, checkboxes, showPromptControl = false }) {
  const promptOptions = [
    { label: 'Consent', value: 'consent' },
    { label: 'Login', value: 'login' },
    { label: 'None', value: 'none' },
    { label: 'Select Account', value: 'select_account' },
  ];

  const clientId = auth.client_id ?? '';
  const clientSecret = auth.client_secret ?? '';
  const scope = auth.scope ?? '';
  const tokenUrl = auth.token_url ?? '';
  const loginUrl = auth.login_url ?? '';
  const responseType = auth.response_type ?? '';
  const prompt = promptOptions.find((option) => option.value === auth.prompt) ?? promptOptions[0];
  let selectScope = [];
  if (scope) {
    selectScope = scope.split(' ').map((s) => ({ label: s, value: s }));
  }
  const redirectUri = utils.getOAuthRedirectUri();

  const onScopeChange = (obj, action) => {
    if (action.action === 'select-option') {
      selectScope.push(action.option);
      onAuthChange({ target: { type: 'select', name: 'scope', value: selectScope.join(' ') } });
    } else if (action.action === 'remove-value') {
      let updatedSelectScope = selectScope.filter((s) => s.value !== action.removedValue.value).map((s) => s.value);
      onAuthChange({ target: { type: 'select', name: 'scope', value: updatedSelectScope.join(' ') } });
    } else if (action.action === 'clear') {
      onAuthChange({ target: { type: 'select', name: 'scope', value: '' } });
    } else if (action.action === 'create-option') {
      const newTag = find(obj, (el) => !!el.__isNew__);
      let updatedSelectScope = selectScope.map((s) => s.value);
      updatedSelectScope.push(newTag.value);
      onAuthChange({ target: { type: 'select', name: 'scope', value: updatedSelectScope.join(' ') } });
    }
  };

  const onPromptChange = (obj) => {
    onAuthChange({ target: { type: 'select', name: 'prompt', value: obj.value } });
  };

  return (
    <div className="box data-source-box">
      {checkboxes?.[1]}
      <Form.Field>
        <Form.Label>OAuth Client ID</Form.Label>
        <Form.Help>Copy this value from the API Setup</Form.Help>
        <Form.Control>
          <InputWithError value={clientId} name="client_id" onChange={onAuthChange} />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>OAuth Client Secret</Form.Label>
        <Form.Help>Copy this value from the API Setup</Form.Help>
        <Form.Control>
          <InputWithError value={clientSecret} name="client_secret" onChange={onAuthChange} type="password" />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>OAuth Scopes (Optional)</Form.Label>
        <Form.Help>Scopes this client should use when connecting.</Form.Help>
        <Form.Control>
          <CreatableInputOnly
            onChange={onScopeChange}
            value={selectScope}
            placeholder="Enter values separated by tab"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Response Type (Optional)</Form.Label>
        <Form.Help>The response type we are expecting for the callback.</Form.Help>
        <Form.Control>
          <InputWithError value={responseType} name="response_type" onChange={onAuthChange} />
        </Form.Control>
      </Form.Field>
      {showPromptControl && (
        <Form.Field>
          <Form.Label>Authorization Server Prompt</Form.Label>
          <Form.Help>How the authorization server will prompt the user for authentication and consent.</Form.Help>
          <Form.Control>
            <Select classNamePrefix="matik-select" onChange={onPromptChange} options={promptOptions} value={prompt} />
          </Form.Control>
        </Form.Field>
      )}
      <Form.Field>
        <Form.Label>Login URL</Form.Label>
        <Form.Help>The URL we will use to authenticate the user.</Form.Help>
        <Form.Control>
          <InputWithError value={loginUrl} name="login_url" onChange={onAuthChange} />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Token URL</Form.Label>
        <Form.Help>The URL we will use to get our access and refresh tokens.</Form.Help>
        <Form.Control>
          <InputWithError value={tokenUrl} name="token_url" onChange={onAuthChange} />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Enter &quot;{redirectUri}&quot; as the redirect_uri when setting up the API Auth</Form.Label>
      </Form.Field>
    </div>
  );
}

OAuthAuth.propTypes = {
  auth: PropTypes.object,
  checkboxes: PropTypes.arrayOf(PropTypes.object),
  onAuthChange: PropTypes.func,
  showPromptControl: PropTypes.bool,
};

export default OAuthAuth;
