import React, { useState, useRef, useEffect } from 'react';
import PopupMenu from 'components/shared/PopupMenu';
import InputText from 'components/lib/InputText';
import Icon from 'components/lib/Icon';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/*
 *  We’ve decided to replace the react-select library in our codebase due to it being buggy and overloaded.
 *  In order to do so, we will replicate its functionality and match the dropdown mocks in the design system.
 *  We will initially implement the single-select dropdown and implement the multi-select at a later date.
 *  Specs: https://docs.google.com/document/d/1b0OqYItxb8erlJqiytZ8jpX3Ycfaw1fnRb_XGeWY_Zk/edit
 *  System design Figma:
 *  https://www.figma.com/design/2gIchNNqB7uCs7oT51pL7v/Design-System?node-id=2173-1612&node-type=CANVAS&t=M0x85admSBDRoN7k-0
 */

/*
 *  At a very high level, no parent component should be directly accessing this component.
 *  If we need to support additional types of select, a new component should be created that wraps this component
 *  and provides the necessary logic to handle the new type of select.
 *  Parent of this component, eg MatikGroupSelect, needs to handle option.value eg
 *  <MatikSelect {...args} onChange={(option) => onChange(option.value)} />
 */

export const optionPropType = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.node,
  filter: PropTypes.func,
  equals: PropTypes.func,
});

const MatikSelectValue = React.forwardRef(({ selectedOption, onClick, isEnabled, placeholderText }, ref) => {
  return (
    <div
      ref={ref}
      onClick={isEnabled ? onClick : null}
      tabIndex={0}
      className="border border-grey-300 rounded py-2 px-4 cursor-pointer bg-matik-white focus:border-matik-green w-full flex items-center justify-between space-x-2"
    >
      <div className="flex items-center space-x-2">
        <span className={`${isEnabled ? '' : 'text-grey-500'}`}>
          {selectedOption ? selectedOption.label : placeholderText}
        </span>
      </div>
      <span className={`flex justify-end ${isEnabled ? '' : 'text-grey-500'}`}>
        <Icon name="chevron_down" size={16} />
      </span>
    </div>
  );
});

MatikSelectValue.propTypes = {
  selectedOption: optionPropType,
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  placeholderText: PropTypes.string,
};
MatikSelectValue.displayName = 'MatikSelect.MatikSelectValue';

const MatikSelectOptions = React.forwardRef(({ options, onSelectOption, onMount, isSearchBoxShown }, ref) => {
  const [searchQuery, setSearchQuery] = useState('');
  const searchBoxRef = useRef(null);

  const handleSearchChange = (searchString) => {
    setSearchQuery(searchString);
  };

  const filteredOptions =
    searchQuery?.length > 0
      ? options?.filter((option) => {
          // remove non-alphanumeric characters and convert to lowercase
          const sanitize = (str) => str.replace(/[^a-z0-9]/gi, '').toLowerCase();

          const sanitizedQuery = sanitize(searchQuery);

          if (typeof option.filter === 'function') {
            return option.filter(searchQuery, option.value);
          } else if (typeof option.label.props?.children === 'string') {
            const sanitizedLabel = sanitize(option.label.props.children);
            return sanitizedLabel.includes(sanitizedQuery);
          } else if (typeof option.label === 'string') {
            const sanitizedLabel = sanitize(option.label);
            return sanitizedLabel.includes(sanitizedQuery);
          }
          return false;
        })
      : options;

  // ensure that the width of Value and Options components are matching
  useEffect(() => {
    onMount();
    setTimeout(() => {
      searchBoxRef.current?.focus();
    }, 0);
  }, [ref]);

  return (
    <div className="border border-grey-300 rounded bg-matik-white py-5 overflow-auto max-h-screen" ref={ref}>
      {isSearchBoxShown && (
        <div className="sticky top-0 bg-matik-white py-2 px-4 mb-3">
          <InputText
            iconName="search"
            ref={searchBoxRef}
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search..."
          />
        </div>
      )}

      {filteredOptions.length > 0 ? (
        <div className="space-y-3">
          {filteredOptions.map((option, idx) => (
            <button
              key={idx}
              className="hover:bg-blue-100 cursor-pointer w-full text-left flex items-center px-4"
              onClick={() => onSelectOption(option)}
              role="option"
              type="button"
            >
              {option.label}
            </button>
          ))}
        </div>
      ) : (
        <div className="py-2 px-3 text-grey-500">No results found</div>
      )}
    </div>
  );
});

MatikSelectOptions.propTypes = {
  options: PropTypes.arrayOf(optionPropType),
  onSelectOption: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  isSearchBoxShown: PropTypes.bool,
  isEnabled: PropTypes.bool,
};
MatikSelectOptions.displayName = 'MatikSelect.MatikSelectOptions';

/*
 * MatikSelect component that combines MatikSelectValue and MatikSelectOptions.
 * It is spatially organized -- the MatikSelectValue is the value initially rendered
 * and the MatikSelectOptions is the dropdown that renders when users click on the value.
 */
const MatikSelect = ({
  options = [],
  value,
  onChange,
  isSearchBoxShown = true,
  isEnabled = true,
  isFocusedOnMount = false,
  placeholderText = 'Select an Option',
}) => {
  const triggerRef = useRef(null);
  const popupMenuRef = useRef(null);
  const optionsRef = useRef(null);

  const handleSelectOption = (option) => {
    if (isEnabled) {
      onChange(option);
      popupMenuRef.current?.close();
    }
  };

  const handleToggleDropdown = () => {
    if (isEnabled) {
      popupMenuRef?.current?.toggle();
    }
  };

  const updateOptionsWidth = () => {
    if (optionsRef.current && triggerRef.current) {
      optionsRef.current.style.width = `${triggerRef.current.offsetWidth}px`;
    }
  };

  const getSelectedOption = () => {
    return options.find((opt) => {
      if (typeof opt.equals === 'function') {
        return opt.equals(value, opt.value);
      } else {
        return opt.value === value;
      }
    });
  };

  useEffect(() => {
    if (isEnabled && isFocusedOnMount && isEmpty(value)) {
      popupMenuRef.current?.open();
    }
  }, []);

  return (
    <div className="relative inline-block w-full">
      <MatikSelectValue
        ref={triggerRef}
        selectedOption={getSelectedOption()}
        onClick={handleToggleDropdown}
        isEnabled={isEnabled}
        placeholderText={placeholderText}
      />
      {isEnabled && (
        <PopupMenu ref={popupMenuRef} anchorRef={triggerRef} position="y">
          <MatikSelectOptions
            ref={optionsRef}
            options={options}
            onSelectOption={handleSelectOption}
            onMount={updateOptionsWidth}
            isSearchBoxShown={isSearchBoxShown}
          />
        </PopupMenu>
      )}
    </div>
  );
};

MatikSelect.propTypes = {
  options: PropTypes.arrayOf(optionPropType),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isSearchBoxShown: PropTypes.bool,
  isEnabled: PropTypes.bool,
  isFocusedOnMount: PropTypes.bool, // Sometimes for UX, the dropdown should be immediately opened when this component is mounted
  placeholderText: PropTypes.string,
};

export default MatikSelect;
