import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Hero, Heading } from 'react-bulma-components';

import dynamic_content_illustration from 'images/dynamic_content_illustration_180x120.png';
import no_templates_illustration from 'images/no_templates_illustration.png';

import DynamicContentList from 'components/producer/dynamicContent/DynamicContentList';
import WithInstantSearch from 'components/shared/search/WithInstantSearch';
import TabHeader from 'components/producer/templates/TabHeader';
import TemplateContentList from 'components/producer/templates/TemplateContentList';
import TemplateInputsList from 'components/producer/templates/TemplateInputsList';
import TemplateModules from 'components/producer/templates/TemplateModules';
import AttachedTemplatesList from 'components/producer/templates/AttachedTemplatesList';
import HtmlBuilderSettings from 'components/shared/htmlBuilder/HtmlBuilderSettings';
import { useDynamicContent } from '../../../lib/hooks/useDynamicContent';
import Constants from '../../Constants';
import IconPill from 'components/lib/IconPill';

const TABS = { INPUTS: 'inputs', CONTENT: 'content', MODULES: 'modules', TEMPLATES: 'templates' };

function EmailTemplateSidebar({
  dynamicContentTags,
  isContentFetching,
  inputsInMatchingContent,
  attachmentInputsInMatchingContent,
  currentTemplate,
  attachedTemplate,
  isBuilderSettingsVisible,
  onContentClick,
  onInputClick,
}) {
  const [currentTab, setCurrentTab] = useState(
    currentTemplate?.source?.editor_type === 'visual' ? TABS.MODULES : TABS.CONTENT,
  );

  const flags = useFlags() || {};

  const combinedInputs = { ...inputsInMatchingContent, ...attachmentInputsInMatchingContent };
  const inputsLength = Object.values(combinedInputs).length;

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const tabs = [
    {
      label: `Content ${dynamicContentTags ? dynamicContentTags.length : 0}`,
      selected: currentTab === TABS.CONTENT,
      onClick: (e) => handleTabClick(e, TABS.CONTENT),
    },
    {
      label: `Inputs ${inputsInMatchingContent ? inputsLength : 0}`,
      selected: currentTab === TABS.INPUTS,
      onClick: (e) => handleTabClick(e, TABS.INPUTS),
    },
  ];

  if (
    flags.enableEmailBuilder &&
    currentTemplate?.source_type === 'email' &&
    currentTemplate?.source?.editor_type === 'visual'
  ) {
    tabs.unshift({
      label: 'Modules',
      selected: currentTab === TABS.MODULES,
      onClick: (e) => handleTabClick(e, TABS.MODULES),
    });
  }

  if (flags.enableAttachmentLinks) {
    tabs.push({
      label: 'Templates',
      selected: currentTab === TABS.TEMPLATES,
      onClick: (e) => handleTabClick(e, TABS.TEMPLATES),
    });
  }

  return (
    <div className="components-wrapper">
      <React.Fragment>
        {isBuilderSettingsVisible ? (
          <HtmlBuilderSettings />
        ) : (
          <React.Fragment>
            <TabHeader tabs={tabs} />
            {currentTab === TABS.CONTENT ? (
              <TemplateContentList
                currentTemplate={currentTemplate}
                dynamicContentTags={dynamicContentTags}
                isContentFetching={isContentFetching}
                entityType="dynamic_content"
                filters={`templates:"${currentTemplate.name}"`}
                handleClick={onContentClick}
                emptyContent={<EmptyContent currentTemplate={currentTemplate} />}
              />
            ) : currentTab === TABS.INPUTS ? (
              <TemplateInputsList
                inputsInMatchingContent={inputsInMatchingContent}
                attachmentInputsInMatchingContent={attachmentInputsInMatchingContent}
                currentTemplate={currentTemplate}
                attachedTemplate={attachedTemplate}
                entityType="input"
                filters={`templates:"${currentTemplate.name}"`}
                handleClick={onInputClick}
              />
            ) : currentTab === TABS.TEMPLATES ? (
              <AttachedTemplatesList
                currentTemplate={currentTemplate}
                attachedTemplate={attachedTemplate}
                emptyContent={<TemplatesEmptyContent currentTemplate={currentTemplate} />}
              />
            ) : (
              <TemplateModules />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
}

EmailTemplateSidebar.propTypes = {
  dynamicContentTags: PropTypes.array,
  isContentFetching: PropTypes.bool,
  inputsInMatchingContent: PropTypes.object,
  attachmentInputsInMatchingContent: PropTypes.object,
  currentTemplate: PropTypes.object,
  attachedTemplate: PropTypes.object,
  isBuilderSettingsVisible: PropTypes.bool,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
};

export default EmailTemplateSidebar;

const EmptyContent = ({ currentTemplate }) => {
  const [pagination, setPagination] = useState({ offset: 0, limit: Constants.PAGE_SIZE, sort: ['updated_by', 'desc'] });

  const {
    dynamicContent,
    isFetching,
    isPaginating,
    pagination: paginationObj,
  } = useDynamicContent(pagination.offset, pagination.limit, pagination.sort);

  const SearchContentList = WithInstantSearch(DynamicContentList);

  const fetchItems = (offset, limit, sort) => {
    setPagination({ offset, limit, sort });
  };

  return (
    <>
      <Hero>
        <Hero.Body className="has-text-centered no-dynamic-content-body">
          <Heading size={5} subtitle>
            <img src={dynamic_content_illustration} alt="No Content Pieces Connected" />
            <p className="no-dynamic-content-title">No Dynamic Content Added Yet</p>
          </Heading>
          <p className="no-dynamic-content-text">
            {
              'To add dynamic content to your email, type in the name of the dynamic content within {{}}. For example, {{account_name}}.'
            }
          </p>
          <p className="mtl">
            <a
              href="https://help.matik.io/hc/en-us/articles/19331802474779-Creating-Matik-Mail-Templates"
              target="_blank"
              rel="noreferrer"
              className="how-to-text link"
            >
              Learn more
            </a>
          </p>
        </Hero.Body>
      </Hero>
      {!isFetching && dynamicContent?.length > 0 && (
        <SearchContentList
          dynamicContent={dynamicContent}
          isPaginating={isPaginating || isFetching}
          pagination={paginationObj}
          entityType="dynamic_content"
          emailEditorType={currentTemplate?.source?.editor_type || ''}
          listContext="template"
          fetchItems={fetchItems}
        />
      )}
    </>
  );
};

EmptyContent.propTypes = {
  currentTemplate: PropTypes.object.isRequired,
};

const TemplatesEmptyContent = ({ currentTemplate }) => {
  return (
    <>
      <Hero>
        <Hero.Body className="has-text-centered no-dynamic-content-body">
          <Heading size={5} subtitle>
            <img src={no_templates_illustration} alt="No Content Pieces Connected" />
            <p className="no-dynamic-content-title">No Templates Added</p>
          </Heading>
          <p className="no-dynamic-content-text">
            {'Add a Matik template. The generated content will be linked in or attached to your emails.'}
          </p>
          <a
            href="https://help.matik.io/hc/en-us/articles/19331802474779-Creating-Matik-Mail-Templates#h_01HBYPVAXCH71SMH8RWQCD1Z5D"
            target="_blank"
            rel="noreferrer"
            className="how-to-text link"
          >
            Learn more
          </a>
          <div className="flex flex-col items-center m-4">
            <div className="instruction-card">
              <div className="p-4px">
                <IconPill iconName="link" size="xs" theme="circle" color="white" />
              </div>
              <span>
                <span className="font-bold text-sm text-grey-600">Link a template </span>
                <span className="font-normal text-sm text-grey-600">
                  {currentTemplate?.source?.editor_type === 'visual'
                    ? 'by adding a link to a Text, Image, or Button module'
                    : 'by using <template templateId=1234>Link Text</template> syntax'}
                </span>
              </span>
            </div>
            <div className="instruction-card">
              <div className="p-4px">
                <IconPill iconName="attach" size="xs" theme="circle" color="white" />
              </div>
              <span>
                <span className="font-bold text-sm text-grey-600">Attach a template </span>
                <span className="font-normal text-sm text-grey-600">
                  to add the generated content as a file attachment on the email
                </span>
              </span>
            </div>
          </div>
        </Hero.Body>
      </Hero>
    </>
  );
};

TemplatesEmptyContent.propTypes = {
  currentTemplate: PropTypes.object.isRequired,
};
