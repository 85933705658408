import Constants from 'components/Constants';
import { UserContext } from 'components/UserContext';
import { Auth } from 'lib/auth';
import { useContext } from 'react';

export default function useIsAiEnabled() {
  const context = useContext(UserContext);
  const enterpriseSettings = context.user?.enterprise?.enterprise_settings;
  return {
    aiEnabled: enterpriseSettings?.ai_enabled || false,
    disableAllAi: enterpriseSettings?.disable_all_ai || false,
  };
}

/** Return [isAiEnabled, isAllAiDisabled, disabledAiMessage] for the current user. */
export function useAiSummaryDisabledMessage() {
  const { aiEnabled, disableAllAi } = useIsAiEnabled();
  const isAdmin = Auth.hasRole(Constants.PRODUCER_ROLE) || Auth.hasRole(Constants.PRODUCER_ADMIN_ROLE);
  const disabledAiMessage = isAdmin
    ? 'This feature is not available. Contact your Technical Account Manager to learn how Matik AI ' +
      'can enhance your content.'
    : 'This feature is not available in your account. Reach out to your admin to learn more about ' +
      'AI-powered content generation.';

  return [aiEnabled, disabledAiMessage, disableAllAi];
}
