import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'react-bulma-components';
import Lottie from 'react-lottie';
import { createColumnHelper } from '@tanstack/react-table';

import { ReactComponent as ScheduledFlowIcon } from 'images/icons/arrow_repeat_all-16x16-regular.svg';
import { ReactComponent as ManualFlowIcon } from 'images/icons/arrow_repeat_all_off-16x16-regular.svg';
import { ReactComponent as CalendarClock } from 'svg/calendar_clock.svg';
import { ReactComponent as CheckMark } from 'svg/check_mark_circle.svg';
import { ReactComponent as Error } from 'svg/error_circle_filled.svg';
import { ReactComponent as Search } from 'svg/search_filled.svg';
import { ReactComponent as Warning } from 'svg/warning_triangle_solid.svg';
import pulsing from 'lottie/pulsing-indicator.json';

import Constants from 'components/Constants';
import API from 'lib/api';
import localeDateFormatterUtils from 'lib/localeDate';
import scheduler from 'lib/scheduler';
import utils from 'lib/utils';

import { closeModal, openModal, closeSidepane, openSidepane } from 'redux/ui/action';

import Icon from 'components/lib/Icon';
import { MConfirm } from 'components/shared/Alerts';
import FullPageList from 'components/shared/FullPageList';
import { NameCell } from 'components/shared/FullPageListCells';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import WithLoadingAndEmpty from 'components/shared/WithLoadingAndEmpty';
import { flowStatus, flowTypes, runStatus } from './Constants';
import FlowsSidepane from './FlowsSidepane';
import FlowActionDropdown from './FlowActionDropdown';
import ScheduledFlowModal from 'components/shared/flows/ScheduledFlowModal';
import TemplateIcon from 'components/shared/TemplateIcon';

function FlowsList({ flows, onFlowDeactivate, onFlowDelete, pagination, paginateContent }) {
  const [filterTerm, setFilterTerm] = useState('');
  const [currentFlow, setCurrentFlow] = useState(null);
  const [includePdf, setPdfToggle] = useState(false);
  const [customFolder, onCustomFolderUpdate] = useState({});

  const history = useHistory();
  const searchParams = history?.location?.search;
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const onPdfToggle = (e) => setPdfToggle(e?.target?.checked);

  useEffect(() => {
    return () => {
      dispatch(closeModal());
      dispatch(closeSidepane());
    };
  }, []);

  useEffect(() => {
    if (searchParams) {
      const [flowType, flowId] = getSearchParamFlowTypeAndId();
      const flow = flows.find((flow) => flow.flow_type === flowType && flow.id === flowId);
      if (flow) {
        setCurrentFlow(flow);
        if (!ui.sidepane && !ui.modal) {
          dispatch(openSidepane({ entityType: 'flows' }, 'flows'));
        }
      }
    } else {
      if (currentFlow) {
        setCurrentFlow(null);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentFlow) {
      setPdfToggle(currentFlow?.params?.include_pdf ?? false);
      onCustomFolderUpdate(currentFlow?.params?.custom_folder ?? {});
    }
  }, [currentFlow]);

  const getSearchParamFlowTypeAndId = () => {
    const params = new URLSearchParams(searchParams);
    let flowType, flowId;
    if (params.size) {
      if (params.has(flowTypes.SCHEDULED)) {
        flowType = flowTypes.SCHEDULED;
      } else if (params.has(flowTypes.MANUAL)) {
        flowType = flowTypes.MANUAL;
      }
      if (flowType) {
        flowId = parseInt(params.get(flowType));
      }
    }
    return [flowType, flowId];
  };

  const onFilter = (e) => {
    setFilterTerm(e.target.value);
  };

  const onRowClick = (flowType, flowId) => {
    history.replace(`?${flowType}=${flowId}`);
    dispatch(openSidepane({ entityType: 'flows' }, 'flows'));
  };

  const onUpdateClick = (flow) => {
    if (flow.flow_type === flowTypes.SCHEDULED) {
      if (searchParams !== `?${flow.flow_type}=${flow.id}`) {
        history.replace(`?${flow.flow_type}=${flow.id}`);
      }
      dispatch(openModal('scheduledFlowModal'));
    }
  };

  const onUpdateScheduleSubmit = (schedule) => {
    const updateData = {
      ...schedule,
      update_type: 'schedule',
      params: {
        custom_folder: customFolder,
        include_pdf: includePdf,
      },
    };

    API.put(
      `/scheduled_tasks/${currentFlow.id}/`,
      updateData,
      () => {
        MConfirm('Schedule Update', 'Scheduled flow updated successfully.', 'confirm', () => window.location.reload());
        dispatch(closeModal());
      },
      API.defaultError,
    );
  };

  const fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    paginateContent(offset, Constants.PAGE_SIZE, sort);
  };

  const flowNameCell = (cellProps) => {
    return (
      <span className="text-overflow-ellipsis mrm">
        <TextOverflowWithTooltip className="text-overflow-ellipsis max-w-3xl" text={cellProps.row.original.name} />
        <div className="recurrence">
          {cellProps.row.original.flow_type === flowTypes.SCHEDULED ? (
            <>
              <ScheduledFlowIcon /> {scheduler.getSentenceFromSchedule(cellProps.row.original)}
            </>
          ) : (
            <>
              <ManualFlowIcon /> Runs one time
            </>
          )}
        </div>
      </span>
    );
  };

  const templateCell = (cellProps) => {
    const templateType =
      cellProps.row.original.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
        ? 'email'
        : Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(cellProps.row.original.template.source_type)
        ? 'document'
        : Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(cellProps.row.original.template.source_type)
        ? 'spreadsheet'
        : 'presentation';
    return (
      <span className="template-cell text-overflow-ellipsis mrm">
        <TemplateIcon templateType={templateType} className="mrs" />
        <TextOverflowWithTooltip
          className="text-overflow-ellipsis max-w-3xl"
          text={cellProps.row.original.template.name}
        />
      </span>
    );
  };

  const statusCell = (cellProps) => {
    const style = { height: 20, width: 20, marginLeft: -3, marginRight: 3 };
    const lottieDefaultOptions = {
      loop: true,
      autoplay: true,
      animationData: pulsing,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMin',
        runExpressions: false,
      },
    };
    let indicator = <div className={'status-indicator green-status'}></div>;
    let text = 'Completed';
    if (![runStatus.DONE, flowStatus.COMPLETED].includes(cellProps.row.original.status)) {
      text = utils.toTitleCase(cellProps.row.original.status);
    }
    if ([runStatus.PROCESSING, flowStatus.PROCESSING].includes(cellProps.row.original.status)) {
      indicator = <Lottie options={lottieDefaultOptions} style={style} />;
    } else if (
      [runStatus.CANCELED, runStatus.NOT_STARTED, flowStatus.DEACTIVATED].includes(cellProps.row.original.status)
    ) {
      indicator = <div className={'status-indicator grey-status'}></div>;
    } else if ([runStatus.ERROR].includes(cellProps.row.original.status)) {
      indicator = <div className={'status-indicator red-status'}></div>;
    }
    return (
      <div className="status-cell">
        {indicator} {text}
      </div>
    );
  };

  const performanceCell = (cellProps) => {
    let icon = <CheckMark className="performance-icon green-checkmark" />;
    let text = 'All smooth';
    if (cellProps.row.original.status_message === flowStatus.QUEUED) {
      icon = <CalendarClock className="performance-icon warning" />;
      text = "Hasn't run yet";
    } else if (cellProps.row.original.status_message === 'warning') {
      icon = <Warning className="performance-icon warning" />;
      text = 'Warning occurred';
    } else if (cellProps.row.original.status_message === 'error' || cellProps.row.original.status === runStatus.ERROR) {
      icon = <Error className="performance-icon error" />;
      text = 'Error occurred';
    }
    return (
      <div className="performance-cell">
        {icon} {text}
      </div>
    );
  };

  const dropdownTrigger = <Icon name="more" size={16} theme="regular" />;
  const actionsCell = (cellProps) => (
    <span className="is-flex overflow-visible is-paddingless">
      <FlowActionDropdown
        flow={cellProps.row.original}
        onFlowDeactivate={onFlowDeactivate}
        onFlowDelete={onFlowDelete}
        dropdownTrigger={dropdownTrigger}
        onUpdateClick={onUpdateClick}
      />
    </span>
  );

  const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
    localeDateFormatterUtils.getUserLocale(),
  );
  const calendarFormat = {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: function (now) {
      if (now.week() === this.week()) {
        return 'dddd [at] LT';
      }
      return `${localeDateFormat}`;
    },
    sameElse: localeDateFormat,
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: 'name',
      header: utils.tableHeader('Name'),
      cell: flowNameCell,
    }),
    columnHelper.display({
      id: 'template',
      header: utils.tableHeader('Template'),
      cell: templateCell,
    }),
    columnHelper.display({
      id: 'status',
      header: utils.tableHeader('Status'),
      cell: statusCell,
      meta: { width: '150px' },
    }),
    columnHelper.display({
      id: 'status_message',
      header: utils.tableHeader('Performance'),
      cell: performanceCell,
      meta: { width: '150px' },
    }),
    columnHelper.accessor(
      (row) => {
        if (row.flow_type === flowTypes.MANUAL || row.runs.length) {
          return moment
            .utc(row.completed_on ?? row.created_on)
            .local()
            .calendar(calendarFormat);
        } else {
          return (
            <span
              data-tooltip-id="matik-tooltip"
              data-tooltip-content={moment.utc(row.completed_on).local().calendar(calendarFormat)}
            >
              Scheduled
            </span>
          );
        }
      },
      {
        id: 'completed_on',
        header: utils.tableHeader('Last Run'),
        cell: (info) => info.getValue(),
        meta: { width: '150px' },
      },
    ),
    columnHelper.accessor((row) => row.user.name || row.user.email, {
      id: 'user',
      header: utils.tableHeader('Created by'),
      cell: (info) => NameCell(info),
      meta: { width: '225px' },
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: (info) => actionsCell(info),
      meta: {
        width: '50px',
        tdStyles: {
          overflow: 'visible',
        },
      },
    }),
  ];

  let flowsToDisplay = flows.filter((flow) => !!flow.template);

  if (flowsToDisplay.length === 0) {
    return null;
  }

  if (filterTerm) {
    const lowerCaseFilter = filterTerm.toLowerCase();
    flowsToDisplay = flowsToDisplay.filter((scheduledFlow) => {
      return scheduledFlow.name.toLowerCase().indexOf(lowerCaseFilter) >= 0;
    });
  }

  return (
    <div className="flows main phn">
      <Form.Field className="search">
        <Form.Control className="has-icons-left">
          <Form.Input type="text" size="medium" placeholder="Filter" value={filterTerm} onChange={onFilter} />
          <span className="icon is-small is-left">
            <Search />
          </span>
        </Form.Control>
      </Form.Field>
      <FullPageList
        columns={columns}
        fetchItems={fetchItems}
        entitiesToRender={flowsToDisplay}
        hideTopPagination={true}
        onRowClick={onRowClick}
        pagination={pagination}
      />
      {currentFlow && currentFlow.flow_type === flowTypes.SCHEDULED && (
        <ScheduledFlowModal
          canRefreshSelectAllValues={currentFlow.params?.should_refresh_select_all_values ?? false}
          schedule={currentFlow}
          template={currentFlow.template}
          onScheduleSubmit={onUpdateScheduleSubmit}
          type="update"
          includePdf={includePdf}
          onPdfToggle={onPdfToggle}
          customFolder={customFolder}
          onCustomFolderUpdate={onCustomFolderUpdate}
        />
      )}
      <FlowsSidepane
        flow={currentFlow}
        onFlowDeactivate={onFlowDeactivate}
        onFlowDelete={onFlowDelete}
        onUpdateClick={onUpdateClick}
      />
    </div>
  );
}

FlowsList.propTypes = {
  flows: PropTypes.array,
  onFlowDeactivate: PropTypes.func,
  onFlowDelete: PropTypes.func,
  pagination: PropTypes.object,
  paginateContent: PropTypes.func,
};

export default WithLoadingAndEmpty(FlowsList);
