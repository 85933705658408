import React from 'react';
import PropTypes from 'prop-types';
import FileViewer from '../../consumer/FileViewer';
import Constants from 'components/Constants';
import GenerationStatusViewer from './GenerationStatusViewer';
import { useFlags } from 'launchdarkly-react-client-sdk';

function PresentationViewer({ presentation, template }) {
  const templateType =
    presentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? 'email' : 'presentation';

  const { longrunnerCreatePresentation } = useFlags();
  return (
    <React.Fragment>
      {!presentation &&
          template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS && (
        <div className="bottom-border">
          <p className="paxl mll">{template.description}</p>
        </div>
      )}
      {!longrunnerCreatePresentation || presentation?.status === 'done' || presentation?.status === 'error' ? (
        <FileViewer presentation={presentation} template={template} frameClass={templateType} />
      ) : (
        presentation && <GenerationStatusViewer presentation={presentation} />
      )}
    </React.Fragment>
  );
}

PresentationViewer.propTypes = {
  presentation: PropTypes.object,
  template: PropTypes.object,
};

export default PresentationViewer;
