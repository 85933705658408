import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import SharedBanner, { toastBanner } from 'components/shared/Banner';
import Banner from 'components/lib/Banner';
import Constants from 'components/Constants';
import wand from 'images/wand-02-white-bg.gif';
import { usePresentationSummary } from 'lib/hooks/usePresentation';

function SummaryGeneratingBanner({ presentationId }) {
  const { data: summary } = usePresentationSummary(presentationId);

  const isExecSummaryGenerating = summary?.executive_summary?.status === Constants.PresentationSummaryStatus.PENDING;
  const isTalkTrackGenerating = summary?.talk_tracks?.status === Constants.PresentationSummaryStatus.PENDING;

  const isGenerating = isExecSummaryGenerating || isTalkTrackGenerating;

  // If we start summary generation before or during this component's life, that should trigger a banner
  // when generation completes.
  const wasGeneratingRef = useRef([isExecSummaryGenerating, isTalkTrackGenerating]);
  useEffect(() => {
    const [wasExecSummaryGenerating, wasTalkTrackGenerating] = wasGeneratingRef.current;
    if ((wasExecSummaryGenerating || wasTalkTrackGenerating) && !isGenerating) {
      // We were generating before, but now are done.
      const wasExecSummaryFailure =
        wasExecSummaryGenerating && summary?.executive_summary?.status === Constants.PresentationSummaryStatus.FAILURE;
      const wasTalkTrackFailure =
        wasTalkTrackGenerating && summary?.talk_tracks?.status === Constants.PresentationSummaryStatus.FAILURE;
      if (wasExecSummaryFailure || wasTalkTrackFailure) {
        let text;
        if (wasExecSummaryFailure && wasTalkTrackFailure) {
          text = 'Problem generating Executive Summary and Talk Tracks';
        } else if (wasExecSummaryFailure) {
          text = 'Problem generating Executive Summary';
        } else if (wasTalkTrackFailure) {
          text = 'Problem generating Talk Tracks';
        }
        toastBanner(<SharedBanner bannerType="error" text={text} />);
      } else {
        let text;
        let sublineText;
        if (wasExecSummaryGenerating && wasTalkTrackGenerating) {
          text = 'The Executive Summary and Talk Tracks have successfully generated.';
          sublineText = 'You can view the executive summary and talk tracks in the speaker notes of your presentation.';
        } else if (wasExecSummaryGenerating) {
          text = 'The Executive Summary has successfully generated.';
          sublineText = 'You can view the executive summary in the speaker notes of your presentation.';
        } else if (wasTalkTrackGenerating) {
          text = 'The Talk Tracks has successfully generated.';
          sublineText = 'You can view the talk tracks in the speaker notes of your presentation.';
        }
        toastBanner(<SharedBanner bannerType="success" text={text} sublineText={sublineText} />);
      }
    }

    wasGeneratingRef.current = [isExecSummaryGenerating, isTalkTrackGenerating];
  }, [isExecSummaryGenerating, isTalkTrackGenerating]);

  if (!isGenerating) {
    return null;
  }

  let message;
  if (isExecSummaryGenerating && isTalkTrackGenerating) {
    message = 'Matik AI is generating the executive summary and talk tracks...';
  } else if (isExecSummaryGenerating) {
    message = 'Matik AI is generating the executive summary...';
  } else if (isTalkTrackGenerating) {
    message = 'Matik AI is generating the talk tracks...';
  }

  return (
    <Banner
      content={message}
      icon={
        <div className="flex w-6 h-6 pr-0.5 pb-[3px]">
          <img src={wand}></img>
        </div>
      }
    />
  );
}
SummaryGeneratingBanner.propTypes = {
  presentationId: PropTypes.number.isRequired,
};

export default SummaryGeneratingBanner;
