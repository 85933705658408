import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import MatikSelect from '../../../shared/MatikSelect';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import InputWithOptionalInputs from '../../../shared/InputWithOptionalInputs';
import { DynamicContentContext } from '../DynamicContentContext';

// Map of property types to their available operators
const peopleOperators = [
  { value: 'contains', label: 'Contains' },
  { value: 'does_not_contain', label: 'Does not contain' },
  { value: 'is_empty', label: 'Is empty' },
  { value: 'is_not_empty', label: 'Is not empty' },
];
const operatorsByType = {
  title: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'contains', label: 'Contains' },
    { value: 'does_not_contain', label: 'Does not contain' },
    { value: 'starts_with', label: 'Starts with' },
    { value: 'ends_with', label: 'Ends with' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  rich_text: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'contains', label: 'Contains' },
    { value: 'does_not_contain', label: 'Does not contain' },
    { value: 'starts_with', label: 'Starts with' },
    { value: 'ends_with', label: 'Ends with' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  number: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'greater_than', label: 'Greater than' },
    { value: 'less_than', label: 'Less than' },
    { value: 'greater_than_or_equal_to', label: 'Greater than or equal to' },
    { value: 'less_than_or_equal_to', label: 'Less than or equal to' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  checkbox: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
  ],
  select: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  multi_select: [
    { value: 'contains', label: 'Contains' },
    { value: 'does_not_contain', label: 'Does not contain' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  date: [
    { value: 'equals', label: 'Equals' },
    { value: 'before', label: 'Before' },
    { value: 'after', label: 'After' },
    { value: 'on_or_before', label: 'On or before' },
    { value: 'on_or_after', label: 'On or after' },
    { value: 'past_week', label: 'Past week' },
    { value: 'past_month', label: 'Past month' },
    { value: 'past_year', label: 'Past year' },
    { value: 'next_week', label: 'Next week' },
    { value: 'next_month', label: 'Next month' },
    { value: 'next_year', label: 'Next year' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  people: peopleOperators,
  created_by: peopleOperators,
  last_edited_by: peopleOperators,
  files: [
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  url: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'contains', label: 'Contains' },
    { value: 'does_not_contain', label: 'Does not contain' },
    { value: 'starts_with', label: 'Starts with' },
    { value: 'ends_with', label: 'Ends with' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  email: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'contains', label: 'Contains' },
    { value: 'does_not_contain', label: 'Does not contain' },
    { value: 'starts_with', label: 'Starts with' },
    { value: 'ends_with', label: 'Ends with' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  phone_number: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'contains', label: 'Contains' },
    { value: 'does_not_contain', label: 'Does not contain' },
    { value: 'starts_with', label: 'Starts with' },
    { value: 'ends_with', label: 'Ends with' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  relation: [
    { value: 'contains', label: 'Contains' },
    { value: 'does_not_contain', label: 'Does not contain' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  status: [
    { value: 'equals', label: 'Equals' },
    { value: 'does_not_equal', label: 'Does not equal' },
    { value: 'is_empty', label: 'Is empty' },
    { value: 'is_not_empty', label: 'Is not empty' },
  ],
  created_time: [
    { value: 'equals', label: 'Equals' },
    { value: 'before', label: 'Before' },
    { value: 'after', label: 'After' },
    { value: 'on_or_before', label: 'On or before' },
    { value: 'on_or_after', label: 'On or after' },
    { value: 'past_week', label: 'Past week' },
    { value: 'past_month', label: 'Past month' },
    { value: 'past_year', label: 'Past year' },
    { value: 'next_week', label: 'Next week' },
    { value: 'next_month', label: 'Next month' },
    { value: 'next_year', label: 'Next year' },
  ],
  last_edited_time: [
    { value: 'equals', label: 'Equals' },
    { value: 'before', label: 'Before' },
    { value: 'after', label: 'After' },
    { value: 'on_or_before', label: 'On or before' },
    { value: 'on_or_after', label: 'On or after' },
    { value: 'past_week', label: 'Past week' },
    { value: 'past_month', label: 'Past month' },
    { value: 'past_year', label: 'Past year' },
    { value: 'next_week', label: 'Next week' },
    { value: 'next_month', label: 'Next month' },
    { value: 'next_year', label: 'Next year' },
  ],
};

// Default to text operators if type is unknown
const getOperatorsForType = (type) => {
  return operatorsByType[type] || operatorsByType.rich_text;
};

// Helper to determine if an operator needs a value
const operatorNeedsValue = (operator) => {
  return ![
    'is_empty',
    'is_not_empty',
    'past_week',
    'past_month',
    'past_year',
    'next_week',
    'next_month',
    'next_year',
  ].includes(operator);
};

const unaryOperatorValue = (operator) => {
  if (['is_empty', 'is_not_empty'].includes(operator)) {
    return true;
  }
  if (['past_week', 'past_month', 'past_year', 'next_week', 'next_month', 'next_year'].includes(operator)) {
    return {};
  }
};

const NotionFilterComponent = ({ properties, filter, onChange }) => {
  const dynamicContentContext = useContext(DynamicContentContext);
  // Extract clauses from the filter prop
  const getClausesFromFilter = () => {
    if (!filter) {
      return [];
    }

    if (filter.and || filter.or) {
      // Handle compound filters
      const clauseArray = filter.and || filter.or;
      return clauseArray.map((clause) => {
        const property = clause.property;
        const propertyType = Object.keys(clause).find((key) => key !== 'property');
        const operatorObj = clause[propertyType];
        const operator = Object.keys(operatorObj)[0];
        const value = operatorObj[operator];

        return {
          property,
          type: propertyType,
          operator,
          value: value !== undefined ? value : '',
        };
      });
    } else if (filter.property) {
      // Handle single filter
      const property = filter.property;
      const propertyType = Object.keys(filter).find((key) => key !== 'property');
      const operatorObj = filter[propertyType];
      const operator = Object.keys(operatorObj)[0];
      const value = operatorObj[operator];

      return [
        {
          property,
          type: propertyType,
          operator,
          value: value !== undefined ? value : '',
        },
      ];
    }

    return [
      {
        property: properties[0]?.name || '',
        type: properties[0]?.type || 'rich_text',
        operator: 'contains',
        value: '',
      },
    ];
  };

  // Current clauses and filter type
  const clauses = getClausesFromFilter();
  // Determine filter type from the filter prop
  const filterType = filter && filter.or ? 'or' : 'and';

  const handleAddClause = (e) => {
    if (e) {
      e.preventDefault();
    }
    const newClauses = [
      ...clauses,
      {
        property: properties[0]?.name || '',
        type: properties[0]?.type || 'rich_text',
        operator: 'contains',
        value: '',
      },
    ];
    updateFilter(newClauses, filterType);
  };

  const handleRemoveClause = (index) => {
    const newClauses = [...clauses];
    newClauses.splice(index, 1);
    updateFilter(newClauses, filterType);
  };

  const handleClauseChange = (index, field, value) => {
    const newClauses = [...clauses];

    if (field === 'property') {
      // Find the property type when property changes
      const propertyObj = properties.find((p) => p.name === value);
      const propertyType = propertyObj?.type || 'rich_text';

      // Update property and type, reset operator to a valid one for the new type
      newClauses[index] = {
        ...newClauses[index],
        property: value,
        type: propertyType,
        operator: getOperatorsForType(propertyType)[0].value,
        value: '',
      };
    } else if (field === 'operator') {
      newClauses[index] = {
        ...newClauses[index],
        operator: value,
        // Clear value if the operator doesn't need one
        value: operatorNeedsValue(value) ? newClauses[index].value : '',
      };
    } else {
      newClauses[index][field] = value;
    }

    updateFilter(newClauses, filterType);
  };

  const updateFilter = (clauseList, type) => {
    if (clauseList.length === 0) {
      onChange(null);
      return;
    }

    if (clauseList.length === 1) {
      // Single filter
      const clause = clauseList[0];
      const newFilter = {
        property: clause.property,
        [clause.type]: {
          [clause.operator]: clause.value,
        },
      };

      // For operators that don't need values, use true
      if (!operatorNeedsValue(clause.operator)) {
        newFilter[clause.type][clause.operator] = unaryOperatorValue(clause.operator);
      }

      onChange(newFilter);
    } else {
      // Compound filter
      const newFilter = {
        [type]: clauseList.map((clause) => ({
          property: clause.property,
          [clause.type]: {
            [clause.operator]: operatorNeedsValue(clause.operator) ? clause.value : unaryOperatorValue(clause.operator),
          },
        })),
      };
      onChange(newFilter);
    }
  };

  return (
    <div className="mb-4">
      <Form.Field>
        <Form.Label>Filters</Form.Label>
        <Form.Help>Filter database entries based on property values</Form.Help>

        {clauses.length > 1 && (
          <Form.Field kind="group">
            <Form.Control>
              <Form.Label>Filters are evaluated using AND</Form.Label>
            </Form.Control>
          </Form.Field>
        )}

        {clauses.map((clause, index) => {
          const operators = getOperatorsForType(clause.type);

          return (
            <div key={index} className="mb-2 flex">
              <AddRemoveButtons
                idx={index}
                includeAdd={index === clauses.length - 1}
                removeEntity={handleRemoveClause}
                addEntity={(e) => {
                  e.preventDefault();
                  handleAddClause();
                }}
                isReadOnly={false}
              >
                <Form.Field className="flex-1 grow mr-3">
                  <Form.Control>
                    <MatikSelect
                      options={properties.map((prop) => ({
                        label: prop.name,
                        value: prop.name,
                      }))}
                      value={clause.property}
                      onChange={(option) => handleClauseChange(index, 'property', option.value)}
                    />
                  </Form.Control>
                </Form.Field>

                <Form.Field className="flex-1 grow mr-3">
                  <Form.Control>
                    <MatikSelect
                      options={operators.map((op) => ({
                        label: op.label,
                        value: op.value,
                      }))}
                      value={clause.operator}
                      onChange={(option) => handleClauseChange(index, 'operator', option.value)}
                    />
                  </Form.Control>
                </Form.Field>

                {operatorNeedsValue(clause.operator) && (
                  <Form.Field className="flex-1 grow mr-3">
                    <Form.Control>
                      <InputWithOptionalInputs
                        value={clause.value}
                        onChange={(val) => handleClauseChange(index, 'value', val)}
                        inputs={dynamicContentContext.existingInputs}
                        isDynamicContentSuggested={false}
                        placeholder="Value"
                        theme="input"
                      />
                    </Form.Control>
                  </Form.Field>
                )}
              </AddRemoveButtons>
            </div>
          );
        })}

        {clauses.length === 0 && (
          <a href="#dummy" onClick={handleAddClause}>
            Add Filter
          </a>
        )}
      </Form.Field>
    </div>
  );
};

NotionFilterComponent.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default NotionFilterComponent;
