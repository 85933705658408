import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import HomeTab from './home/HomeTab';
import AnalyticsTab from './analytics/AnalyticsTab';
import ProducerNav from './nav/ProducerNav';
import TemplatesTab from './templates/TemplatesTab';
import DataSourcesTab from './dataSources/DataSourcesTab';
import DynamicContentTab from './dynamicContent/DynamicContentTab';
import { UserContext } from '../UserContext';
import UserProfile from '../shared/UserProfile';
import { Redirect } from 'react-router-dom';
import InputsTab from './inputs/InputsTab';
import EnterpriseSettingsTab from '../enterprise_settings/EnterpriseSettingsTab';
import WithFetchUser from '../shared/WithFetchUser';
import WithPresentationContextProvider from '../shared/presentations/WithPresentationContextProvider';
import Constants from '../Constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import OnboardingWelcome from './onboarding/OnboardingWelcome';
import EmailCSV from './workflow/EmailCSV';
import PresentationsTab from '../shared/presentations/PresentationsTab';
import FlowsTab from '../shared/flows/FlowsTab';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

class ProducerMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.user &&
        this.props.user.enterprise.subscription_status !== Constants.SUBSCRIPTION_STATUSES.active &&
        !(
          this.props.user.enterprise.subscription_status === Constants.SUBSCRIPTION_STATUSES.trialing &&
          this.props.user.enterprise.trial_days_remaining > 0
        ) &&
        this.props.flags.enableFreeTrialOverPage === true) ||
      (this.props.user && this.props.user.enterprise.plan_id == Constants.MATIK_TIERS.matik_chrome_extension.tier_id)
    ) {
      this.props.history.push('/account_inactive');
    }
    if (prevProps.location.pathname.includes('templates') && !this.props.location.pathname.includes('templates')) {
      this.props.closeSidepane();
    }
  }

  render() {
    let body = '';
    let tab = this.props.match.params.tab || Constants.Tabs.HOME;
    if (tab === Constants.Tabs.TEMPLATES) {
      body = <TemplatesTab match={this.props.match} history={this.props.history} />;
    } else if (tab === Constants.Tabs.DYNAMIC_CONTENT) {
      body = <DynamicContentTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.DATA_SOURCES) {
      body = <DataSourcesTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.INPUTS) {
      body = <InputsTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.ANALYTICS) {
      body = <AnalyticsTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.HOME) {
      body = <HomeTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.USER_PROFILE) {
      body = (
        <UserProfile
          id={this.props.user ? this.props.user.id : 0}
          user={this.props.user}
          updateUser={this.props.updateUser}
          match={this.props.match}
        />
      );
    } else if (tab === Constants.Tabs.ENTERPRISE_SETTINGS) {
      body = <EnterpriseSettingsTab match={this.props.match} />;
    } else if (tab === Constants.Tabs.WELCOME) {
      body = <OnboardingWelcome />;
    } else if (tab === Constants.Tabs.EMAIL) {
      body = <EmailCSV />;
    } else if (tab === Constants.Tabs.GENERATED) {
      body = <PresentationsTab match={this.props.match} isProducer={true} />;
    } else if (tab === Constants.Tabs.FLOWS) {
      body = <FlowsTab />;
    } else {
      return <Redirect to="/" />;
    }

    return (
      <>
        <UserContext.Provider
          value={{
            user: this.props.user,
            updateUser: this.props.updateUser,
            refreshOauthToken: this.props.refreshOauthToken,
            isEndUserView: false,
          }}
        >
          <ProducerNav activeTab={tab} />
          {body}
        </UserContext.Provider>
      </>
    );
  }
}

ProducerMain.propTypes = {
  history: PropTypes.object,
  match: PropTypes.any,
  updateUser: PropTypes.func,
  user: PropTypes.object,
  flags: PropTypes.object,
  refreshOauthToken: PropTypes.func,
  location: PropTypes.object,
  closeSidepane: PropTypes.func,
};

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(WithFetchUser(WithPresentationContextProvider(withLDConsumer()(ProducerMain)))));
