import API from 'lib/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useMultiFetch from './useMultiFetch';
import { useInputs } from './useInput';
import presentations from 'lib/presentations';
import Constants from 'components/Constants';
import { useDebugValue } from 'react';

/** Fetch a single presentation */
const usePresentation = (id) => {
  const queryClient = useQueryClient();
  const queryKey = ['presentation', parseInt(id)];

  const checkStatus = (presentationId) => {
    API.get(
      `/presentations/${presentationId}/get_status/`,
      (response) => {
        if (response.data.status === 'success') {
          let presentationStatus = response.data.presentation_status;
          if (presentationStatus === 'non_email_failed_condition') {
            // 'non_email_failed_condition' is an artificial status. the underlying presentation.status is 'failed condition'
            presentationStatus = 'failed condition';
          }

          if (!presentations.isGenerationComplete(presentationStatus)) {
            // Still processing. Update the status and check again
            const currentQueryData = queryClient.getQueryData(queryKey);
            if (currentQueryData) {
              const updatedPresentation = { ...currentQueryData };
              updatedPresentation.status = presentationStatus;
              updatedPresentation.status_message = response.data.presentation_status_message;

              if (updatedPresentation.status !== currentQueryData.status) {
                queryClient.setQueryData(queryKey, updatedPresentation);
              }
            }

            setTimeout(() => checkStatus(presentationId), 1500);
          } else if (response.data.new_entity) {
            // Generation complete (or errored out). Update with the latest data.
            const presentation = response.data.new_entity;
            queryClient.setQueryData(queryKey, presentation);
          }
        }
      },
      () => {
        // nothing to do here, really.
      },
    );
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/presentations/${id}/`,
        (response) => {
          const presentation = response.data;
          // If the presentation is still generating, start polling to update the status and get the final result.
          if (!presentations.isGenerationComplete(presentation.status)) {
            checkStatus(presentation.id);
          }

          return response.data;
        },
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
    cacheTime: 5 * 60 * 1000,
  });

  return {
    isPending: isLoading,
    isError,
    data,
    error,
  };
};

export default usePresentation;

export const usePresentations = (ids) => {
  const getQueryKey = (id) => ['presentation', parseInt(id)];
  const getFetchUri = (ids) => {
    return API.generate_paginated_url('/presentations/', 0, ids.length, null, `{"id":[${ids.join(',')}]}`);
  };
  const getEntityId = (presentation) => presentation.id;
  return useMultiFetch(ids, getQueryKey, getFetchUri, getEntityId, 5 * 60 * 1000);
};

/** Fetch the inputs referenced by the given presentation */
export const usePresentationInputs = (id) => {
  const queryKey = ['presentation', parseInt(id), 'content'];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/presentations/${id}/content/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
  });

  // Get full input data to flesh out the input refs
  const inputIds = data?.parameters?.map((param) => param.id);
  const inputResultsById = useInputs(inputIds || []);
  const hydratedParameters = data?.parameters?.map((ref) => {
    let { data: fullInput } = inputResultsById[ref.id];
    return fullInput || ref;
  });
  const isInputsFetching = Object.values(inputResultsById).findIndex((result) => result.isPending) > -1;

  return {
    isPending: isLoading || isInputsFetching,
    isError,
    data: hydratedParameters,
    error,
  };
};

export const usePresentationMutator = () => {
  const queryClient = useQueryClient();
  const getPresentationStatus = (presentationId) => {
    return API.get(
      `/presentations/${presentationId}/get_status/`,
      (response) => {
        if (
          response.data.presentation_status === 'done' ||
          response.data.presentation_status === 'non_email_failed_condition'
        ) {
          return {
            status: 'done',
            presentationId,
            isNonEmailFailedCondition: response.data.presentation_status === 'non_email_failed_condition',
            presentation: response.data.new_entity,
            attachedPresentation: response.data.new_attachment_entity,
          };
        } else if (response.data.presentation_status === 'unknown') {
          // This may happen if there is a race condition: another user loads the templates page in between status checks
          window.location.reload();
        } else if (response.data.presentation_status === 'error' || response.data.attachment_status === 'error') {
          return {
            status: 'generation_error',
            presentationId,
          };
        } else {
          return {
            status: response.data.presentation_status,
          };
        }
      },
      (err) => {
        throw err;
      },
    );
  };

  const create = (data, attachmentData = {}, onStatusUpdate, onStart) => {
    onStatusUpdate('Processing');
    let cancelled = false;
    const result = new Promise((resolve, reject) => {
      const checkStatus = (presentationId) => {
        if (cancelled) {
          return;
        }
        getPresentationStatus(presentationId)
          .then((result) => {
            if (result.status === 'done' || result.status === 'generation_error') {
              // We've got the new presentation data so let's cache it
              if (result.presentation) {
                queryClient.setQueryData(['presentation', parseInt(result.presentation.id)]);
              }
              if (result.attachedPresentation) {
                queryClient.setQueryData(['presentation', parseInt(result.attachedPresentation.id)]);
              }

              resolve(result);
            } else {
              onStatusUpdate(result.status);
              setTimeout(() => checkStatus(presentationId), 1500);
            }
          })
          .catch(reject);
      };

      const allData = {
        ...data,
        ...attachmentData,
      };
      API.post(
        '/presentations/',
        allData,
        (response) => {
          if (onStart) {
            onStart(response.data.new_entity);
          }
          setTimeout(() => {
            const presentationId = response.data.new_entity.id;
            checkStatus(presentationId);
          }, 1500);
        },
        reject,
      );
    });

    return {
      cancel: () => {
        cancelled = true;
      },
      result,
    };
  };

  const updateNotifyWhenComplete = (presentationId, notify) => {
    return new Promise((resolve, reject) => {
      if (notify) {
        API.post(
          `/presentations/${presentationId}/notify_when_complete/`,
          {},
          (response) => resolve(response.data.updated_entity),
          reject,
        );
      } else {
        API.delete(
          `/presentations/${presentationId}/notify_when_complete/`,
          (response) => resolve(response.data.updated_entity),
          reject,
        );
      }
    }).then((updated) => {
      queryClient.setQueryData(['presentation', parseInt(presentationId)], updated);
      return updated;
    });
  };

  return {
    /** Return a promise that resolves after the presentation has been generated
     * or errors out.
     * @returns
     * `{status: "done|generation_error", presentationId, presentation, attachedPresentation, isNonEmailFailedCondition}`
     */
    create,
    /** Update the notify-when-complete flag for the current user on the given presentation. */
    updateNotifyWhenComplete,
  };
};

/** Get the presentation executive summary and slide talk tracks. */
export const usePresentationSummary = (id) => {
  const queryClient = useQueryClient();

  const queryKey = ['presentation', parseInt(id), 'summary'];

  // If we're generating exec summary or talk tracks, keep polling until we're done.
  const pollUntilComplete = (summary) => {
    const isPending = [summary.executive_summary.status, summary.talk_tracks.status].includes(
      Constants.PresentationSummaryStatus.PENDING,
    );
    if (!isPending) {
      return;
    }

    setTimeout(() => {
      API.get(
        `/presentations/${id}/summary/`,
        (response) => {
          return response.data;
        },
        () => {},
      ).then((summary) => {
        queryClient.setQueryData(queryKey, summary);
        pollUntilComplete(summary);
      });
    }, 1500);
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => {
      return API.get(
        `/presentations/${id}/summary/`,
        (response) => {
          pollUntilComplete(response.data);
          return response.data;
        },
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
  });

  useDebugValue(error || data);

  return {
    isPending: isLoading,
    isError,
    data,
    error,
  };
};

export const usePresentationSummaryMutator = () => {
  const queryClient = useQueryClient();

  const generateSummary = (presentationId, type) => {
    const queryKey = ['presentation', parseInt(presentationId), 'summary'];

    // optimistically update the cache so we get immediate user feedback
    const cachedData = queryClient.getQueryData(queryKey);
    if (cachedData) {
      const optimistic = { ...cachedData };
      if (type === Constants.PresentationSummaryType.EXECUTIVE_SUMMARY) {
        optimistic.executive_summary = {
          ...optimistic.executive_summary,
          status: Constants.PresentationSummaryStatus.PENDING,
        };
      } else if (type === Constants.PresentationSummaryType.TALK_TRACKS) {
        optimistic.talk_tracks = {
          ...optimistic.talk_tracks,
          status: Constants.PresentationSummaryStatus.PENDING,
        };
      }
      queryClient.setQueryData(queryKey, optimistic);
    }

    return new Promise((resolve, reject) => {
      const checkStatus = (taskId) => {
        API.get(
          `/presentations/${presentationId}/summary/${taskId}/get_status/`,
          (response) => {
            if (response.data.status === 'error') {
              reject(response.data);
            } else if (response.data.status === 'done') {
              resolve(response.data.updated_entity);
            } else {
              setTimeout(() => checkStatus(taskId), 1500);
            }
          },
          reject,
        );
      };

      API.track('ui_generate_narrative_summary', { type, presentation: presentationId });

      API.post(
        `/presentations/${presentationId}/summary/`,
        { type },
        (response) => {
          const updatedSummary = response.data.updated_entity;
          queryClient.setQueryData(queryKey, updatedSummary);
          setTimeout(() => checkStatus(response.data.task_id), 1500);
        },
        reject,
      );
    })
      .then((updated) => {
        queryClient.setQueryData(queryKey, updated);
        return updated;
      })
      .catch((err) => {
        // rollback optimistic cache
        queryClient.invalidateQueries({ queryKey });
        throw err;
      });
  };

  return {
    /** Generate new AI executive summary or talk tracks for the presentation. */
    generateSummary,
  };
};
