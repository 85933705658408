import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Heading } from 'react-bulma-components';
import API from 'lib/api';
import dataSources from 'lib/dataSources';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import { UserContext } from 'components/UserContext';
import Button from 'components/lib/Button';
import ButtonGroup from 'components/lib/ButtonGroup';
import GenericOauthForm from 'components/producer/dataSources/forms/GenericOauthForm';
import { MAlert, MConfirm } from 'components/shared/Alerts';
import TestResult from 'components/shared/TestResult';

class DataSourceLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: this.props.dataSource.auth_info ?? {},
      dataSourceId: this.props.dataSource.data_source_id ?? this.props.dataSource.id,
      isLoading: false,
      isOAuth: dataSources.isOAuth(this.props.dataSource.type, this.props.dataSource.auth_info ?? {}),
      testResult: '',
      userDataSourceId: this.props.dataSource.dataSourceId ? this.props.dataSource.id : null,
    };
  }

  render() {
    const isUpdate = !isEmpty(this.state.auth);
    const LoginForm = this.state.isOAuth
      ? GenericOauthForm
      : dataSources.getDataSourceAuthForm(this.props.dataSource.type);
    let actionButtonText;
    let actionButtonAction;
    if (isUpdate) {
      actionButtonText = 'Update';
      actionButtonAction = this.onUpdate;
    } else {
      actionButtonText = 'Log In';
      actionButtonAction = this.onLogIn;
    }
    let buttons = '';
    if (!this.state.isOAuth) {
      buttons = (
        <Form.Field className="data-source-footer">
          {this.props.nextPrevButtons}
          <ButtonGroup align="right">
            {isUpdate ? (
              <Form.Control>
                <Button
                  onClick={this.onDelete}
                  category="tertiary"
                  status={this.state.isLoading ? 'loading' : 'default'}
                >
                  Delete
                </Button>
              </Form.Control>
            ) : (
              <Button
                onClick={this.props.onClose}
                category="tertiary"
                status={this.state.isLoading ? 'loading' : 'default'}
              >
                Cancel
              </Button>
            )}
            <Form.Control>
              <Button onClick={this.onTest} category="secondary" status={this.state.isLoading ? 'loading' : 'default'}>
                Test Credentials
              </Button>
            </Form.Control>
            <Form.Control>
              <Button onClick={actionButtonAction} status={this.state.isLoading ? 'loading' : 'default'}>
                {actionButtonText}
              </Button>
            </Form.Control>
          </ButtonGroup>
        </Form.Field>
      );
    }
    return (
      <div className="data-source-body">
        <Form.Field>
          {!this.props.hideHeading && (
            <Heading size={5}>
              {this.props.dataSource.name} ({utils.capitalizeString(this.props.dataSource.type)})
            </Heading>
          )}
          {LoginForm && (
            <LoginForm
              auth={this.state.auth}
              clearSSHKey={this.clearSSHKey}
              connectToDataSource={this.connectToDataSource}
              deleteDataSource={this.deleteOAuthDataSource}
              onAuthChange={this.onChange}
              setIsOAuth={this.setIsOAuth}
              reconnectToDataSource={this.connectToDataSource}
              selectedDataSource={this.props.dataSource}
              type={this.props.dataSource.type}
              userDataSourceId={this.state.userDataSourceId}
            />
          )}
          {buttons}
          <TestResult testResult={this.state.testResult} />
        </Form.Field>
      </div>
    );
  }

  onChange = (updatedAuth) => {
    this.setState({
      auth: {
        ...this.state.auth,
        [updatedAuth.target.name]: updatedAuth.target.value,
      },
    });
  };

  setIsOAuth = (val) => {
    this.setState({ isOAuth: val });
  };

  clearSSHKey = () => {
    this.setState({
      auth: {
        ...this.state.auth,
        ssh_key: '',
      },
    });
  };

  onUpdate = (e) => {
    e.preventDefault();
    if (this.validateFormData()) {
      this.setState({ isLoading: true });
      const dataSourceData = this._getDataFromState();
      API.put(
        `/user_data_sources/${this.state.userDataSourceId}/`,
        dataSourceData,
        (response) => {
          this.setState({ isLoading: false, testResult: null, auth: response.data.updated_entity.auth_info });
          this.props.onUserDataSourceUpdate(response.data.updated_entity);
        },
        this.requestError,
      );
    }
  };

  onLogIn = (e) => {
    e.preventDefault();
    if (this.validateFormData()) {
      this.setState({ isLoading: true });
      const dataSourceData = this._getDataFromState();
      API.post(
        '/user_data_sources/',
        dataSourceData,
        (response) => {
          this.setState({ isLoading: false, testResult: null });
          this.props.onUserDataSourceAdd(response.data.new_entity);
        },
        this.requestError,
      );
    }
  };

  onTest = (e) => {
    e.preventDefault();
    if (this.validateFormData()) {
      this.setState({ isLoading: true });
      const dataSourceData = this._getDataFromState();
      API.post(
        '/user_data_sources/test/',
        dataSourceData,
        (response) => {
          this.setState({
            isLoading: false,
            testResult: response.data,
          });
        },
        this.requestError,
      );
    }
  };

  onDelete = (e) => {
    e.preventDefault();
    MConfirm('Delete', 'Are you sure you want to delete this data source?', 'warning', (confirmed) => {
      if (confirmed) {
        this.setState({ isLoading: true });
        API.delete(
          `/user_data_sources/${this.state.userDataSourceId}/`,
          () => {
            this.setState({ isLoading: false });
            this.props.onUserDataSourceDelete(this.state.userDataSourceId);
          },
          this.requestError,
        );
      }
    });
  };

  _getDataFromState = () => {
    const isGoogleSheet = this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet;
    const data = {
      data_source_id: this.state.dataSourceId,
    };
    if (this.state.userDataSourceId) {
      data['user_data_source_id'] = this.state.userDataSourceId;
    }
    if (!this.state.isOAuth || isGoogleSheet) {
      return Object.assign({}, data, { auth: this.state.auth });
    }
  };

  validateFormData = () => {
    if (!this.state.isOAuth) {
      if (!this.state.auth || Object.keys(this.state.auth).length === 0) {
        MAlert('Credentials are required', 'Error', 'error');
        return false;
      }
    }

    return true;
  };

  requestError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };

  connectToDataSource = (e, dataSourceType) => {
    const onUpdate = (newDataSource) => {
      if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet) {
        this.setState({ auth: newDataSource });
        this.onLogIn(e);
      } else {
        this.props.onUserDataSourceAdd(newDataSource);
      }
    };

    if (dataSourceType === 'salesforce') {
      utils.openSalesforceOauthPopup(
        this.context.user.nonce,
        onUpdate,
        this.props.dataSource.name,
        'prompt=consent',
        true,
        true,
      );
    } else if (dataSourceType === 'googlebq') {
      utils.openGoogleBQOauthPopup(
        this.context.user.nonce,
        onUpdate,
        this.props.dataSource.name,
        this.props.dataSource.database,
        'prompt=consent',
        true,
      );
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.google_sheet) {
      utils.openGoogleOauthPopup(this.context.user.nonce, onUpdate, 'prompt=consent', 'google_sheet');
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.snowflake) {
      utils.openSnowflakeOAuthPopup(
        this.context.user.nonce,
        onUpdate,
        this.props.dataSource.name,
        this.props.dataSource.host,
        this.props.dataSource.database,
        this.state.auth,
        true,
      );
    } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.api) {
      utils.openRestAPIOAuthPopup(
        this.context.user.nonce,
        onUpdate,
        this.props.dataSource.name,
        this.state.auth,
        `prompt=${this.state.auth.prompt ?? 'consent'}`,
      );
    } else {
      MAlert('Data source type not supported', 'Error', 'error');
    }
  };

  deleteOAuthDataSource = (e, selectedDataSource) => {
    e.preventDefault();
    MConfirm('Delete', 'Are you sure you want to delete this data source?', 'warning', (confirmed) => {
      if (confirmed) {
        API.delete(
          `/user_data_sources/${selectedDataSource.id}/`,
          () => {
            this.props.onUserDataSourceDelete(selectedDataSource.id);
          },
          this.submitError,
        );
      }
    });
  };
}

DataSourceLogin.propTypes = {
  dataSource: PropTypes.object.isRequired,
  hideHeading: PropTypes.bool,
  nextPrevButtons: PropTypes.any,
  onClose: PropTypes.func,
  onUserDataSourceAdd: PropTypes.func,
  onUserDataSourceDelete: PropTypes.func,
  onUserDataSourceUpdate: PropTypes.func,
  user: PropTypes.object,
};

DataSourceLogin.contextType = UserContext;

export default DataSourceLogin;
