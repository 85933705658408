import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { Select } from '../../../shared/FormSelect';
import { connect } from 'react-redux';
import { mapApiInfoStateToProps } from '../../../../redux/dataSources/stateMappers';
import { mapDispatchToProps } from '../../../../redux/dataSources/dispatchers';
import { ReactComponent as Sync } from '../../../../images/sync.svg';
import { find, forEach, isEmpty, isEqual, remove } from 'lodash';
import Pluralize from 'pluralize';
import Constants from '../../../Constants';
import InputMapping from '../InputMapping';
import info_icon from '../../../../images/info_icon.svg';
import dataSources from '../../../../lib/dataSources';
import API from '../../../../lib/api';
import Button from '../../../lib/Button';
import { MAlert } from '../../../shared/Alerts';
import SenderApiReturnFields from './SenderApiReturnFields';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { components } from 'react-select';
class SalesforceReportsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupingOption: null,
      returnFields: [],
      showFormFields: false,
      matrixReport: false,
      searchReportOnly: false,
      searchValue: this.props.queryObj.selectedReport || '',
    };
  }

  componentDidMount() {
    if (
      this.props.queryObj.selectedReport &&
      this.props.apiInfo.reports &&
      this.props.apiInfo.reports[this.props.queryObj.selectedReport] &&
      this.props.apiInfo.reports[this.props.queryObj.selectedReport].metadata
    ) {
      if (this.props.apiInfo.reports[this.props.queryObj.selectedReport].metadata.reportFormat !== 'MATRIX') {
        this.setState({ showFormFields: true, matrixReport: false });
      } else {
        this.setState({ showFormFields: false, matrixReport: true });
      }
      const returnFields = dataSources.buildCRMReturnFields(
        this.props.apiInfo,
        'reports',
        this.props.queryObj.selectedReport,
        'columns',
      );
      this.setState({ returnFields });

      this.fixSelectedReportMetadata(this.props.queryObj);
      this.addExistingFiltersToQuery(
        this.props.queryObj,
        this.props.apiInfo.reports[this.props.queryObj.selectedReport].metadata.reportFilters,
      );
      this.fixMissingReturnFieldMapping(this.props.queryObj);
    }

    if (this.props.reportCount && this.props.reportCount > Constants.MAX_SALESFORCE_REPORTS) {
      this.setState({ searchReportOnly: true });
      if (this.props.queryObj.selectedReport && !this.props?.apiInfo?.reports?.[this.props.queryObj.selectedReport]) {
        this.getReportDetailsFromSalesforceSearch(this.props.queryObj.selectedReport);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.queryObj, this.props.queryObj) || !isEqual(prevProps.apiInfo, this.props.apiInfo)) {
      if (
        this.props.queryObj.selectedReport &&
        this.props.apiInfo.reports &&
        this.props.apiInfo.reports[this.props.queryObj.selectedReport]
      ) {
        if (
          this.props.queryObj.selectedReportMetadata &&
          !this.props.apiInfo.reports[this.props.queryObj.selectedReport].columns
        ) {
          this.getReportColumns(this.props.queryObj.selectedReportMetadata.id);
        }

        if (
          this.props.apiInfo.reports[this.props.queryObj.selectedReport].metadata &&
          this.props.apiInfo.reports[this.props.queryObj.selectedReport].metadata.reportFormat !== 'MATRIX'
        ) {
          this.setState({ showFormFields: true, matrixReport: false });
        } else {
          this.setState({ showFormFields: false, matrixReport: true });
        }

        const reportFilters = this.props.apiInfo.reports[this.props.queryObj.selectedReport]?.metadata?.reportFilters;
        if (!isEqual(this.props.queryObj.existingReportFilters, reportFilters)) {
          this.addExistingFiltersToQuery(this.props.queryObj, reportFilters);
        }

        const reportMetadata = this.props.apiInfo.reports[this.props.queryObj.selectedReport].metadata;
        if (
          !isEqual(this.props.queryObj.selectedReportMetadata?.hasDetailRows, reportMetadata?.hasDetailRows) ||
          !this.props.queryObj.selectedReportExtendedMetadata
        ) {
          this.fixSelectedReportMetadata(this.props.queryObj);
        }
        const returnFields = dataSources.buildCRMReturnFields(
          this.props.apiInfo,
          'reports',
          this.props.queryObj.selectedReport,
          'columns',
        );
        this.setState({ returnFields });
      }

      if (
        this.state.groupingOption &&
        this.props.queryObj.selectedReportMetadata &&
        isEmpty(this.props.queryObj.selectedReportMetadata.groupingsDown)
      ) {
        this.fixSelectedReportMetadata(this.props.queryObj);
      }
    }
  }

  render() {
    const reportOptions = this.props.apiInfo.reports
      ? Object.keys(this.props.apiInfo.reports).map((reportName) => ({ value: reportName, label: reportName }))
      : [];

    let reportValue = null;
    if (this.props.queryObj.selectedReport) {
      reportValue = { label: this.props.queryObj.selectedReport, value: this.props.queryObj.selectedReport };
    }

    return (
      <div>
        {this.props.source === 'reports' && (
          <React.Fragment>
            <Form.Field className="mbl">
              <Form.Label>Report</Form.Label>
              <Form.Help>
                {this.state.searchReportOnly
                  ? 'Enter Salesforce report name. The name of the report must be an exact match.'
                  : 'Select Salesforce report'}
                {this.props.queryObj.selectedReport && (
                  <Button
                    category="tertiary"
                    size="small"
                    onClick={(e) => this.refreshReport(e, this.props.queryObj.selectedReport)}
                    data-tooltip-id="matik-tooltip"
                    data-tooltip-content={`Refresh metadata for ${this.props.queryObj.selectedReport}`}
                    id="salesforce-report-refresh"
                  >
                    <Sync />
                  </Button>
                )}
              </Form.Help>
              <Form.Control>
                {this.state.searchReportOnly ? (
                  <div className="salesforce-search-container">
                    <Form.Input
                      type="text"
                      className="salesforce-search"
                      value={this.state.searchValue}
                      onChange={(e) => this.handleSearchInputChange(e)}
                      name="selectedReport"
                      placeholder="Enter Salesforce Report Name"
                      aria-label="Salesforce Report Name"
                    />
                    <Button
                      id="search-salesforce-report"
                      onClick={this.handleSearchButtonClick}
                      status={!this.state.searchValue ? 'disabled' : 'default'}
                    >
                      <span className="whitespace-nowrap">Find Report</span>
                    </Button>
                  </div>
                ) : (
                  <Select
                    aria-label="Select Salesforce Report"
                    classNamePrefix="matik-select"
                    className="matik-select-container salesforce-report-select"
                    isDisabled={this.context.isReadOnly}
                    value={reportValue}
                    name="selectedReport"
                    onChange={(obj, action) => {
                      this.clearGroupingOption();
                      this.selectSalesforceReport(obj, action);
                    }}
                    placeholder="Select Salesforce Report"
                    options={reportOptions}
                    isLoading={!reportOptions.length}
                  ></Select>
                )}
              </Form.Control>
              {this.props.queryObj.selectedReport &&
                this.props.apiInfo.reports &&
                Object.keys(this.props.apiInfo.reports).length > 0 &&
                !this.props.apiInfo.reports[this.props.queryObj.selectedReport] && (
                  <Form.Help color="danger">
                    Selected Report &quot;{this.props.queryObj.selectedReport}&quot; missing in Salesforce
                  </Form.Help>
                )}
              {this.state.matrixReport && (
                <Form.Help color="danger">
                  This is a matrix report, selecting fields and filters is not supported
                </Form.Help>
              )}
            </Form.Field>
            {this.state.showFormFields && (
              <React.Fragment>
                {this.renderReturnColumns(this.props.queryObj)}
                {this.renderFilters(this.props.queryObj)}
                {this.renderOrderLimit(this.props.queryObj)}
                {this.props.renderUniqueValuesFilter()}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  handleSearchInputChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleSearchButtonClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.getReportDetailsFromSalesforceSearch(this.state.searchValue);
  };

  getReportDetailsFromSalesforceSearch = (queryText) => {
    if (typeof queryText !== 'string' || queryText.length > Constants.SALESFORCE_REPORT_CHARACTER_LIMIT) {
      throw new Error('Invalid input');
    }

    this.props.setIsFetching(this.props.dataSource.id, true);
    API.get(
      `/data_sources/${this.props.dataSource.id}/search/Report/${encodeURIComponent(queryText)}/`,
      (response) => {
        const reportDetails = response.data;

        if (!this.props?.queryObj?.selectedReport || this.props.queryObj.selectedReportId !== reportDetails.id) {
          const queryObj = Object.assign({}, this.props.queryObj);
          queryObj.selectedReport = reportDetails.metadata.name;
          queryObj.selectedObject = null;
          queryObj.selectedReportId = reportDetails.id;
          queryObj.selectedReportMetadata = reportDetails.metadata;
          queryObj.selectedReportExtendedMetadata = reportDetails.extended_metadata;
          queryObj.returnColumnsByName = {};

          queryObj.filters = [];
          queryObj.orderByField = null;
          this.context.onQueryStringUpdate(JSON.stringify(queryObj));
        }
        this.props.refreshReport(reportDetails.id, reportDetails.metadata.name);
      },
      (error) => {
        this.props.setIsFetching(this.props.dataSource.id, false);
        if (!window.location.pathname.includes('new')) {
          const queryObj = Object.assign({}, this.props.queryObj);
          queryObj.selectedReport = null;
          queryObj.selectedReportId = null;
          queryObj.selectedReportMetadata = null;
          queryObj.selectedReportExtendedMetadata = null;
          queryObj.returnColumnsByName = {};
          queryObj.filters = [];
          queryObj.orderByField = null;
          this.context.onQueryStringUpdate(JSON.stringify(queryObj));
          MAlert('The selected report was not found in Salesforce.  Resetting fields.', 'Report Not Found');
          return null;
        } else {
          API.defaultError(error);
        }
      },
    );
  };

  selectSalesforceReport = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;

      const queryObj = Object.assign({}, this.props.queryObj);
      queryObj.selectedReport = value;
      queryObj.selectedObject = null;
      queryObj.selectedReportId = this.props.apiInfo.reports[value].Id;
      queryObj.selectedReportMetadata = this.props.apiInfo.reports[value].metadata;
      queryObj.selectedReportExtendedMetadata = this.props.apiInfo.reports[value].extended_metadata;
      queryObj.returnColumnsByName = {};

      queryObj.filters = [];
      queryObj.orderByField = null;
      this.context.onQueryStringUpdate(JSON.stringify(queryObj));
      if (!this.props.apiInfo.reports[queryObj.selectedReport]['columns']) {
        this.getReportColumns(queryObj.selectedReportId);
      }
    }
  };

  getReportColumns = (selectedReportId) => {
    this.props.fetchApiInfoIfNeeded(this.props.dataSource.id, 'reports', selectedReportId, 'columns');
  };

  colIsRequired = (col, metadata = null) => {
    let isAggregate = false;
    if (metadata) {
      // See https://developer.salesforce.com/docs/atlas.en-us.api_analytics.meta/api_analytics/sforce_analytics_rest_api_getbasic_reportmetadata.htm#analyticsapi_basicmetadata
      // for information on aggregates formatting.
      isAggregate =
        metadata.aggregates.filter((fieldName) => new RegExp(`[asmxu]!${col.name}`).test(fieldName)).length > 0;
    }
    return isAggregate;
  };

  renderReturnColumns = (queryObj) => {
    if (!queryObj.selectedReport) {
      return false;
    }
    const returnColumnsByName = queryObj.returnColumnsByName || {};
    const returnColumns = this.props.apiInfo.reports[queryObj.selectedReport].columns || {};
    let returnColumnsArray = [];
    let returnColumnsOptions = [];
    let detailColumnsOptions = [];

    // Add grouping and aggregate columns to options/returned, hold detail columns until we check for
    // aggregates which override any detail columns
    returnColumns.forEach((column) => {
      // some columns do not have the type set to 'aggregate' but are still required - they need to be manually added
      const isRequired = this.colIsRequired(column, queryObj.selectedReportMetadata);
      const isAggregate = column.type === 'aggregate';
      const isGrouping = column.type === 'grouping';

      if (isRequired || isGrouping || isAggregate) {
        if (!this.state.groupingOption && column.type === 'grouping') {
          this.setState({ groupingOption: true });
        }
        const columnOption = dataSources.createReturnFieldLabel(
          queryObj,
          column.name,
          this.state.returnFields,
          this.props.toggleRenameModal,
          true,
        );
        returnColumnsOptions.push(columnOption);
        returnColumnsArray.push(columnOption);

        isRequired
          ? this.onReturnColumnChange(columnOption, { action: 'select-option', option: columnOption }, queryObj)
          : '';
      } else {
        detailColumnsOptions.push(
          dataSources.createReturnFieldLabel(
            queryObj,
            column.name,
            this.state.returnFields,
            this.props.toggleRenameModal,
            false,
          ),
        );
      }
    });

    // If the column is not aggregate or grouping, we can add it now
    returnColumnsOptions.push(...detailColumnsOptions);
    const returnDetailColumns = [];
    for (const columnName of Object.keys(returnColumnsByName)) {
      const column = find(this.props.apiInfo.reports[queryObj.selectedReport].columns, { name: columnName });
      if (
        returnColumnsByName[columnName] &&
        column &&
        column.type !== 'grouping' &&
        !this.colIsRequired(column, queryObj.selectedReportMetadata)
      ) {
        returnDetailColumns.push({
          label: column.label,
          value: column.name,
          isFixed: false,
          displayName: queryObj?.returnFieldMapping?.[column.name]?.displayName,
          toggleRenameModal: this.props.toggleRenameModal,
        });
      }
    }
    returnColumnsArray.push(...returnDetailColumns);

    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      const formattedValues = {};
      Object.entries(this.context.multiFieldMapping).map(([senderField, columnName]) => {
        formattedValues[senderField] = dataSources.createReturnFieldLabel(
          queryObj,
          columnName,
          this.state.returnFields,
          this.props.toggleRenameModal,
          true,
        );
      });
      return (
        <SenderApiReturnFields
          formRef={this.props.formRef}
          options={returnColumnsOptions}
          onReturnFieldChange={this.onReturnColumnChange}
          queryObj={queryObj}
          formattedValues={formattedValues}
        />
      );
    } else {
      return (
        <>
          <Form.Field className="mbl">
            <Form.Label>Columns To Return</Form.Label>
            <Form.Help className="mbs">
              Select columns to return{' '}
              {queryObj.source === 'reports' ? '(Groupings and aggregate columns cannot be removed)' : ''}
            </Form.Help>
            <Form.Control>
              <Select
                aria-label="Select Return Columns"
                classNamePrefix="matik-select"
                value={returnColumnsArray}
                isMulti={true}
                isRenamable={true}
                options={returnColumnsOptions}
                onChange={(obj, action) => this.onReturnColumnChange(obj, action, queryObj)}
                isDisabled={this.context.isReadOnly}
                componentsToAdd={{ Option: components.Option, ValueContainer: components.ValueContainer }}
              />
            </Form.Control>
          </Form.Field>
          {this.props.entityType && this.props.entityType === 'input' && (
            <Form.Field className="mbl">
              <InputMapping
                queryObj={queryObj}
                onInputMappingUpdate={this.props.onInputMappingUpdate}
                input={this.context.existingInputs}
                inputMapping={this.props.inputMapping}
                returnFieldsArray={returnColumnsArray}
                mapOnLabel={true}
              />
            </Form.Field>
          )}
        </>
      );
    }
  };

  renderFilters(queryObj) {
    const queryFilters = queryObj.filters || [];
    let body = (
      <a href="#dummy" onClick={(e) => this.props.addFilter(e, queryObj, 'equals')}>
        Add Filter
      </a>
    );
    if (queryFilters.length > 0) {
      body = queryFilters.map((filter, idx) => {
        if (!filter['operator']) {
          filter['operator'] = 'equals';
        }
        return this.props.renderFilter(
          idx,
          queryObj,
          'columns',
          filter,
          Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.salesforce_reports,
          null,
          null,
          this.filterOptions(queryObj),
          this.context.isReadOnly,
          this.context.existingInputs,
        );
      });
    }
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Filters</Form.Label>
          <Form.Help>
            Select columns and inputs to filter by.
            <span
              className="mls filter-hint-tooltip"
              data-tooltip-id="matik-tooltip"
              data-tooltip-html="Hint: Create & insert inputs using &:&lt;input_name&gt;"
              data-tooltip-place="right"
            >
              <img src={info_icon} alt="Filter Info" width="14px" className="icon-pull-down" />
            </span>
          </Form.Help>
          <Form.Control>{body}</Form.Control>
          <div className="mts">{this.renderSalesforceReportStandardFilters(queryObj)}</div>
        </Form.Field>
      </React.Fragment>
    );
  }

  renderSalesforceReportStandardFilters = (queryObj) => {
    // TODO(Matt): Figure out a way to display salesforce filters info
    // For now, we'll return the count of filters applied on the sfdc side
    let filterCount = 0;
    let message = '';
    if (!queryObj.selectedReportMetadata) {
      return message;
    }
    const dateFilter = queryObj.selectedReportMetadata.standardDateFilter;
    const standardFilters = queryObj.selectedReportMetadata.standardFilters;
    const reportFilters = queryObj.selectedReportMetadata.existingReportFilters;
    if (dateFilter) {
      filterCount += 1;
    }
    if (standardFilters) {
      filterCount += standardFilters.length;
    }
    if (reportFilters) {
      filterCount += reportFilters.length;
    }
    if (filterCount > 0) {
      message += `${filterCount} additional ${Pluralize('filter', filterCount)} being applied by Salesforce`;
    }
    return message;
  };

  filterOptions = (queryObj) => {
    return this.props.apiInfo.reports[queryObj.selectedReport]['columns']
      .filter((column) => column.type !== 'grouping' && column.type !== 'aggregate')
      .map((column) => ({
        value: `${column.name}_${column.type}`,
        label: column.label,
      }));
  };

  renderOrderLimit = (queryObj) => {
    const report =
      this.props.apiInfo.reports[queryObj.orderByReport ? queryObj.orderByReport[1][0] : queryObj.selectedReport];
    if (!report) {
      return;
    }
    const orderOptions = report['columns'].map((column) => ({ label: column.label, value: column.name }));

    const helpText = 'Select column to order by (Grouped reports will be ordered by the groupings).';

    return this.props.renderOrderLimit(
      queryObj,
      orderOptions,
      null,
      this.state.groupingOption,
      helpText,
      null,
      this.context.isReadOnly,
      this.onSortChange,
    );
  };

  onReturnColumnChange = async (obj, action, queryObj) => {
    let updatedQuery = Object.assign({}, queryObj);
    let returnColumnsByName = updatedQuery.returnColumnsByName || {};
    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      const column = find(this.props.apiInfo.reports[this.props.queryObj.selectedReport].columns, {
        name: obj.value,
      });
      this.updateReturnFieldMapping(updatedQuery, column);
      await this.context.onSenderFieldUpdate(action.name, obj.value);
      const senderReturnFields = new Set(Object.values(this.context.multiFieldMapping));
      returnColumnsByName = {};
      senderReturnFields.forEach((field) => (returnColumnsByName[field] = true));
    } else {
      if (action.action === 'select-option') {
        if (action.option.value === '_all_') {
          forEach(this.props.apiInfo.reports[this.props.queryObj.selectedReport].columns, (column) => {
            if (column.type !== 'grouping') {
              returnColumnsByName[column.name] = true;
              this.updateReturnFieldMapping(updatedQuery, column);
            }
          });
        } else {
          const column = find(this.props.apiInfo.reports[this.props.queryObj.selectedReport].columns, {
            name: action.option.value,
          });
          returnColumnsByName[column.name] = true;
          this.updateReturnFieldMapping(updatedQuery, column);
        }
      } else if (action.action === 'select-all-options') {
        const entries = action.option.map((option) => {
          const column = find(this.props.apiInfo.reports[this.props.queryObj.selectedReport].columns, {
            name: option.value,
          });
          this.updateReturnFieldMapping(updatedQuery, column);
          return [option.value, true];
        });
        returnColumnsByName = Object.fromEntries(entries);
      } else if (action.action === 'remove-value') {
        // Javascript is weird and while objects properties don't have order they kinda do
        // By deleting the key and re-adding it with the value false we reorder the property in the object
        if (action.removedValue.isFixed) {
          return;
        }
        delete returnColumnsByName[action.removedValue.value];
        returnColumnsByName[action.removedValue.value] = false;
        if (updatedQuery.returnFieldMapping && action.removedValue.value in updatedQuery.returnFieldMapping) {
          updatedQuery.returnFieldMapping[action.removedValue.value].displayName = '';
        }
      } else if (action.action === 'clear') {
        returnColumnsByName = {};
      }
    }
    updatedQuery.returnColumnsByName = returnColumnsByName;
    let returnColumnsForMetadata = [];
    if (updatedQuery.selectedReportMetadata && !isEmpty(updatedQuery.selectedReportMetadata)) {
      for (let key in returnColumnsByName) {
        if (returnColumnsByName[key]) {
          returnColumnsForMetadata.push(key);
        }
      }
    }
    updatedQuery.selectedReportMetadata.detailColumns = returnColumnsForMetadata;
    if (this.state.groupingOption) {
      // Remove the grouping from the columns to request
      updatedQuery = this.removeGroupingColumn(updatedQuery);
    }
    this.context.onQueryStringUpdate(JSON.stringify(updatedQuery));
  };

  removeGroupingColumn = (updatedQuery) => {
    if (updatedQuery.selectedReportMetadata.detailColumns.includes(this.state.groupingOption.value)) {
      remove(updatedQuery.selectedReportMetadata.detailColumns, (col) => col === this.state.groupingOption.value);
    }
    return updatedQuery;
  };

  onSortChange = (obj, action, name) => {
    if (action.action === 'select-option') {
      const updatedQuery = Object.assign({}, this.props.queryObj);
      if (isEmpty(updatedQuery.selectedReportMetadata.sortBy)) {
        updatedQuery.selectedReportMetadata.sortBy.push({});
      }
      if (name === 'orderByField') {
        updatedQuery[name] = obj.label;
        updatedQuery.selectedReportMetadata.sortBy[0].sortColumn = obj.value;
      } else {
        let sortDirection = 'Asc';
        if (obj.value === 'DESC') {
          sortDirection = 'Desc';
        }
        updatedQuery[name] = obj.value;
        updatedQuery.selectedReportMetadata.sortBy[0].sortOrder = sortDirection;
      }

      this.context.onQueryStringUpdate(JSON.stringify(updatedQuery));
    }
  };

  clearGroupingOption = () => {
    this.setState({ groupingOption: null });
  };

  refreshReport = (e, reportName) => {
    e.preventDefault();
    const selectedReport = this.props.apiInfo.reports[reportName];
    if (selectedReport) {
      this.props.refreshReport(selectedReport.id, reportName);
    }
  };

  fixSelectedReportMetadata = (queryObj) => {
    let updatedQuery = Object.assign({}, queryObj);
    if (!updatedQuery.selectedReportExtendedMetadata) {
      updatedQuery.selectedReportMetadata = this.props.apiInfo.reports[queryObj.selectedReport]?.metadata;
    }
    // Cannot change entire metadata object because will override selected report columns
    if (updatedQuery.selectedReportMetadata) {
      updatedQuery.selectedReportMetadata.groupingsDown =
        this.props.apiInfo.reports[queryObj.selectedReport]?.metadata?.groupingsDown;
      updatedQuery.selectedReportMetadata.hasDetailRows =
        this.props.apiInfo.reports[queryObj.selectedReport]?.metadata?.hasDetailRows;
    }
    updatedQuery.selectedReportExtendedMetadata =
      this.props.apiInfo.reports[queryObj.selectedReport]?.extended_metadata;
    this.context.onQueryStringUpdate(JSON.stringify(updatedQuery));
  };

  addExistingFiltersToQuery(queryObj, filters) {
    let updatedQuery = Object.assign({}, queryObj);
    if (!updatedQuery.selectedReportExtendedMetadata) {
      updatedQuery.selectedReportMetadata = this.props.apiInfo.reports[queryObj.selectedReport].metadata;
    }
    updatedQuery.selectedReportMetadata['existingReportFilters'] = filters;
    if (Object.keys(updatedQuery).includes('existingReportFilters')) {
      delete updatedQuery.existingReportFilters;
    }
    this.context.onQueryStringUpdate(JSON.stringify(updatedQuery));
  }

  updateReturnFieldMapping(queryObj, column) {
    try {
      if (!queryObj.returnFieldMapping) {
        queryObj.returnFieldMapping = {};
      }
      queryObj.returnFieldMapping[column.name] = {
        name: column.name,
        label: column.label,
        displayName: queryObj?.returnFieldMapping[column.name]?.displayName,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error updating return field mapping\n', error, '\n', queryObj);
    }
  }

  fixMissingReturnFieldMapping(queryObj) {
    if (!isEmpty(queryObj.returnColumnsByName)) {
      const updatedQueryObj = Object.assign({}, queryObj);
      for (let key in updatedQueryObj.returnColumnsByName) {
        if (updatedQueryObj.returnColumnsByName[key]) {
          const column = find(this.props.apiInfo.reports[this.props.queryObj.selectedReport].columns, { name: key });
          this.updateReturnFieldMapping(updatedQueryObj, column);
        }
      }
      this.context.onQueryStringUpdate(JSON.stringify(updatedQueryObj));
    }
  }
}

SalesforceReportsForm.contextType = DynamicContentContext;

SalesforceReportsForm.propTypes = {
  apiInfo: PropTypes.object,
  selectedObject: PropTypes.string,
  queryObj: PropTypes.object,
  selectSalesforceSourceApi: PropTypes.func,
  clearGroupingOption: PropTypes.func,
  refreshReport: PropTypes.func,
  renderOrderLimit: PropTypes.func,
  source: PropTypes.any,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  renderFilter: PropTypes.func,
  onFilterFieldChange: PropTypes.func,
  onFilterInputChange: PropTypes.func,
  toggleRenameModal: PropTypes.func,
  onLimitChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onOperatorChange: PropTypes.func,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  fetchApiInfoIfNeeded: PropTypes.func,
  dataSource: PropTypes.object,
  reportCount: PropTypes.number,
  setIsFetching: PropTypes.func,
  formRef: PropTypes.object,
  renderUniqueValuesFilter: PropTypes.func,
};

export default connect(mapApiInfoStateToProps, mapDispatchToProps)(SalesforceReportsForm);
