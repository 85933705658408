import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

// https://www.figma.com/file/2gIchNNqB7uCs7oT51pL7v/Design-System?node-id=2382%3A1880&mode=dev
const InputText = forwardRef(
  (
    { placeholder, iconName, icon, status, value, onChange, rightIcons, isReadOnly, onFocus, onBlur, onKeyUp, onClick },
    ref,
  ) => {
    if (iconName) {
      icon = <Icon name={iconName} size={16} />;
    }
    return (
      <div className="relative">
        {icon && (
          <div
            className={`absolute inset-y-0 w-[40px] flex items-center justify-center ${
              status === 'disabled' ? 'text-grey-500' : ''
            } pointer-events-none`}
          >
            {icon}
          </div>
        )}
        <div
          className={`absolute inset-y-0 right-3 flex gap-1 items-center ${
            status === 'disabled' ? 'text-grey-500' : ''
          }`}
        >
          {rightIcons}
        </div>
        <input
          ref={ref}
          className={`rounded border border-${status === 'error' ? 'red-500' : 'grey-300'} ${
            icon ? 'pl-[40px]' : 'pl-4'
          } py-2 w-full bg-matik-white
          placeholder:text-grey-600 placeholder-shown:text-ellipsis
          focus:border-matik-green focus:outline-none focus:shadow-focus focus:placeholder:text-grey-400
          disabled:text-grey-600 disabled:bg-grey-100 disabled:placeholder:text-grey-400`}
          style={{ paddingRight: rightIcons?.length > 0 ? `${rightIcons.length * 30 - 4 + 12}px` : '12px' }}
          placeholder={placeholder}
          disabled={status === 'disabled'}
          type="text"
          value={value ?? ''}
          onChange={(e) => onChange(e.target.value)}
          readOnly={isReadOnly}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onClick={onClick}
        />
      </div>
    );
  },
);
InputText.propTypes = {
  /** The name of the icon positioned to the left. Leaving it (and 'icon') empty means no icon. */
  iconName: PropTypes.string,
  /** The element to use as an icon positioned to the left. It should be 16px wide. */
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  status: PropTypes.oneOf(['error', 'disabled', 'default']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  /** Each of these elements should be approximately 26px wide. The right-side input padding
   * calculation assumes this, so if they don't average 26px wide the input padding won't align
   * perfectly. */
  rightIcons: PropTypes.arrayOf(PropTypes.element),
  isReadOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
};
InputText.displayName = 'InputText';

export default InputText;
