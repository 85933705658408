import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import LookerQueryForm from './LookerQueryForm';
import { Select } from '../../../shared/FormSelect';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { initializeQuery, getDynamicFieldName, updateV2QueryWithLookerQueryData } from '../../../../lib/looker';
import { union } from 'lodash';

class LookerDashboardForm extends Component {
  getApiInfo() {
    if (this.props.queryObj.dashboard_id) {
      this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'dashboard', this.props.queryObj.dashboard_id);
      let slug =
        this.props.apiInfo?.dashboard?.[this.props.queryObj.dashboard_id]?.[this.props.queryObj.dashboard_element_id]
          ?.result_maker?.query?.slug;
      if (slug) {
        this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'query', slug);
      }
    }
  }
  componentDidMount() {
    this.getApiInfo();
  }
  componentDidUpdate() {
    this.getApiInfo();
  }

  render() {
    const showFormFields =
      this.props.queryObj.dashboard_element_id && this.props.apiInfo.query && this.props.apiInfo.dashboard;
    const dashboardObjFromApi = this.props.apiInfo?.['dashboard']?.[this.props.queryObj.dashboard_id] || {};
    const queryId =
      (showFormFields &&
        this.props.queryObj.dashboard_element_id &&
        dashboardObjFromApi?.[this.props.queryObj.dashboard_element_id]?.['result_maker']?.['query']?.['slug']) ||
      null;
    const dashboardQuery = (showFormFields && this.props.apiInfo?.['query']?.[queryId]) || {};

    const dynamicFieldNames = dashboardQuery['query']?.['dynamic_fields']?.map(getDynamicFieldName) || [];
    const fieldsUnionDynamicFields = union(dashboardQuery?.['fields'], dynamicFieldNames);

    const dashboardOptions = this.props.apiInfo['dashboards']
      ? Object.keys(this.props.apiInfo['dashboards']).map((dashboardId) => {
          const dashboard = this.props.apiInfo['dashboards'][dashboardId];
          return { label: dashboard.title, value: dashboard.id };
        })
      : [];

    let selectedDashboardValue = null;
    let dashboardErrorMessage = '';
    if (this.props.queryObj.dashboard_id) {
      selectedDashboardValue = dashboardOptions.find(
        (dashboardOption) => this.props.queryObj.dashboard_id === dashboardOption.value,
      );
      if (!selectedDashboardValue) {
        dashboardErrorMessage = `The dashboard with id ${this.props.queryObj.dashboard_id} cannot be found in Looker. Please select a valid dashboard.`;
        selectedDashboardValue = null;
      }
    }

    const apiDashboardErrorMessage =
      this.props.apiErrors &&
      this.props.apiErrors.dashboard &&
      selectedDashboardValue &&
      this.props.apiErrors.dashboard[selectedDashboardValue?.value] &&
      'There appears to be a problem with the selected dashboard. Please check the configuration in Looker.';

    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Dashboard</Form.Label>
          <Form.Help>Select Looker Dashboard</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Dashboard"
              placeholder="Select a Dashboard"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={selectedDashboardValue}
              onChange={(obj) => this.selectDashboard(obj.value)}
              options={dashboardOptions}
              classNames={{
                control: () => (dashboardErrorMessage || apiDashboardErrorMessage ? 'select-error' : ''),
              }}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
          {<Form.Help color="danger">{dashboardErrorMessage || apiDashboardErrorMessage}</Form.Help>}
        </Form.Field>
        {this.props.queryObj.dashboard_id && this.props.apiInfo['dashboard'] && this.renderDashboardElements()}
        {showFormFields && (
          <LookerQueryForm
            queryObj={this.props.queryObj}
            fields={fieldsUnionDynamicFields}
            dynamicFields={dashboardQuery['query']?.['dynamic_fields'] || []}
            entityType={this.props.entityType}
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
          />
        )}
      </React.Fragment>
    );
  }

  renderDashboardElements() {
    const dashboardElements = this.props.apiInfo?.['dashboard']?.[this.props.queryObj.dashboard_id] || {};

    const queryOptions = Object.keys(dashboardElements).map((elementId) => {
      const element = dashboardElements[elementId];
      return { label: this._getElementTitle(element), value: element.id };
    });

    let selectedQueryOption = null;
    let queryErrorMessage = null;
    if (this.props.queryObj.dashboard_element_id) {
      selectedQueryOption = queryOptions.find((option) => this.props.queryObj.dashboard_element_id === option.value);
      if (!selectedQueryOption) {
        queryErrorMessage = `The dashboard query with id ${this.props.queryObj.dashboard_element_id} cannot be found in Looker. Please select a valid query.`;
        selectedQueryOption = null;
      }
    }

    let apiQueryErrorMessage;
    const query = dashboardElements?.[this.props.queryObj.dashboard_element_id]?.['result_maker']?.['query'];
    if (this.props.queryObj.dashboard_element_id && !query) {
      apiQueryErrorMessage =
        'The selected dashboard element cannot be used because it does not contain a query. Matik does not support merged queries.';
    }
    apiQueryErrorMessage =
      (this.props.queryObj.dashboard_element_id && !query) || this.props.apiErrors?.query?.[query?.slug]
        ? 'There appears to be a problem with the selected query. Please check the configuration in Looker.'
        : null;
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Dashboard Query</Form.Label>
          <Form.Help>Select Looker Query</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Query"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={selectedQueryOption}
              onChange={(obj) => this.selectDashboardElement(obj.value)}
              options={queryOptions}
              classNames={{
                control: () => (queryErrorMessage || apiQueryErrorMessage ? 'select-error' : ''),
              }}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
          {<Form.Help color={'danger'}>{queryErrorMessage || apiQueryErrorMessage}</Form.Help>}
        </Form.Field>
      </React.Fragment>
    );
  }

  _getElementTitle = (element) => {
    let elementTitle = element.title;
    if (!elementTitle && element.look && element.look.title) {
      elementTitle = element.look.title;
    }
    return elementTitle;
  };

  selectDashboardElement = (id) => {
    if (this.props.queryObj.dashboard_element_id === id) {
      return;
    }

    const dashboardId = this.props.queryObj.dashboard_id || 0;
    const dashboardElement = this.props.apiInfo['dashboard'][dashboardId][id];
    const queryId = dashboardElement?.['result_maker']?.['query']?.['slug'] || '';

    if (queryId) {
      this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'query', queryId);
    }

    const updatedQueryObj = initializeQuery({
      source: 'dashboard',
      dashboard_id: dashboardId,
      dashboard_element_id: dashboardElement?.id || null,
    });

    if (!dashboardElement?.['result_maker']?.['query']) {
      this.context.onQueryObjectUpdate(updatedQueryObj);
      return;
    }
    const v2QueryObj = updateV2QueryWithLookerQueryData(updatedQueryObj, dashboardElement['result_maker']['query']);
    this.context.onQueryObjectUpdate(v2QueryObj);
    this.props.updateTestResult(null);
  };

  selectDashboard = (id) => {
    if (this.props.queryObj.dashboard_id === id) {
      return;
    }
    const updatedQueryObj = initializeQuery({ source: 'dashboard', dashboard_id: id });
    this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'dashboard', id);
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };
}

LookerDashboardForm.contextType = DynamicContentContext;

LookerDashboardForm.propTypes = {
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  dataSourceId: PropTypes.number,
  fetchApiInfoIfNeeded: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  updateTestResult: PropTypes.func,
  formRef: PropTypes.object,
  queryObj: PropTypes.object,
};

export default LookerDashboardForm;
