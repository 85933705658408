import React, { useEffect, useState, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState, convertToRaw, Modifier, convertFromHTML, ContentState } from 'draft-js';
import PropTypes from 'prop-types';
import bold_icon from '../../images/wysiwyg/bold.svg';
import italic_icon from '../../images/wysiwyg/italic.svg';
import underline_icon from '../../images/wysiwyg/underline.svg';
import list_unordered_icon from '../../images/wysiwyg/list-unordered.svg';
import list_ordered_icon from '../../images/wysiwyg/list-ordered.svg';
import align_left_icon from '../../images/wysiwyg/align-left.svg';
import align_center_icon from '../../images/wysiwyg/align-center.svg';
import align_right_icon from '../../images/wysiwyg/align-right.svg';
import link_icon from '../../images/wysiwyg/link.svg';
import unlink_icon from '../../images/wysiwyg/unlink.svg';
import emoji_icon from '../../images/wysiwyg/emoji.svg';
import utils from '../../lib/utils';
import draftToHtml from 'draftjs-to-html';
import AutoCompleteSuggestions from './AutoCompleteSuggestions';
import API from 'lib/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LinkPopup from 'components/shared/htmlBuilder/LinkPopup';

const defaultToolOptions = {
  options: ['inline', 'list', 'textAlign', 'link', 'emoji'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: bold_icon, className: 'wysiwyg-button' },
    italic: { icon: italic_icon, className: 'wysiwyg-button' },
    underline: { icon: underline_icon, className: 'wysiwyg-button' },
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: { icon: list_unordered_icon, className: 'wysiwyg-button' },
    ordered: { icon: list_ordered_icon, className: 'wysiwyg-button' },
  },
  textAlign: {
    options: ['left', 'center', 'right'],
    left: { icon: align_left_icon, className: 'wysiwyg-button' },
    center: { icon: align_center_icon, className: 'wysiwyg-button' },
    right: { icon: align_right_icon, className: 'wysiwyg-button' },
  },
  link: {
    link: { icon: link_icon, className: 'wysiwyg-button' },
    unlink: { icon: unlink_icon, className: 'wysiwyg-button' },
  },
  emoji: {
    icon: emoji_icon,
    className: 'wysiwyg-button',
  },
};

const content = {
  entityMap: {},
  blocks: [{ key: '637gr', text: '', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }],
};

function WYSIWYGEditor({
  placeholder,
  ariaLabel,
  updateEditor,
  updateTextHTML,
  value,
  readOnly,
  toolbarHidden,
  floatingToolbar,
  autoResize,
  suggestions,
  triggerPattern,
  closingPattern,
  toolOptions,
  showAutoComplete,
  paddingOffset,
  isPreviewed,
  inVisualBuilder = false,
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [toolbarOffset, setToolbarOffset] = useState(45);
  const [caretPosition, setCaretPosition] = useState({ x: 0, y: 0 });
  const [attachmentType, setAttachmentType] = useState({ label: 'URL', value: 'url' });
  const [url, setUrl] = useState('');
  const [attachmentTemplate, setAttachmentTemplate] = useState(null);
  const [linkTarget, setLinkTarget] = useState();
  const editorRef = useRef(null);
  const { enableAttachmentLinks } = useFlags();

  useEffect(() => {
    const descJSON = convertToRaw(editorState.getCurrentContent());
    if (value !== JSON.stringify(descJSON)) {
      if (typeof value !== 'undefined' && value.length > 0) {
        let editorValue;
        try {
          editorValue = JSON.parse(value);
          if (typeof editorValue === 'number') {
            content['blocks'][0]['text'] = value;
            editorValue = content;
          }
        } catch {
          content['blocks'][0]['text'] = value;
          editorValue = content;
        }
        if (editorValue) {
          setEditorState(EditorState.createWithContent(convertFromRaw(editorValue)));
        }
      } else {
        // Clear the editor content.
        const emptyContentState = ContentState.createFromText('');
        const emptyEditorState = EditorState.push(editorState, emptyContentState, 'remove-range');

        // There is a bug in Draft.js that erroneously updates the block key
        // after typing the first character in an empty text buffer, which
        // puts the selection in a bad state and causes the cursor to jump
        // to the beginning of the buffer after typing a character. Moving
        // focus to the end after clearing the editor content fixes this issue.
        //
        // See https://github.com/facebookarchive/draft-js/issues/1198.
        const emptyEditorStateWithUpdatedFocus = EditorState.moveFocusToEnd(emptyEditorState);

        setEditorState(emptyEditorStateWithUpdatedFocus);
      }
    }
  }, [value]);

  const handlePaste = (event) => {
    try {
      event.preventDefault();
      const selectionState = editorState.getSelection();
      const contentState = editorState.getCurrentContent();

      let paste = event.clipboardData.getData('text/html');
      let newContentState;

      if (paste) {
        // Prevent pasting images into editor
        const imgTagRegex = /<img[^>]*>/g; // regex to match and remove img tags
        const cleanedHtml = paste.replace(imgTagRegex, '');
        const pastedTextFormatted = ContentState.createFromBlockArray(convertFromHTML(cleanedHtml)).getBlockMap();
        newContentState = Modifier.replaceWithFragment(contentState, selectionState, pastedTextFormatted);
      } else {
        paste = event.clipboardData.getData('text/plain');
        newContentState = Modifier.replaceText(contentState, selectionState, paste);
      }

      if (!newContentState) {
        API.defaultError('Failed to create content state from paste content');
        return;
      }

      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
      setEditorState(newEditorState);
    } catch (error) {
      API.defaultError('Error handling paste event:', error);
    }
  };

  const setEditorReference = (ref) => {
    if (ref) {
      editorRef.current = ref;
      const contentElem = editorRef.current?.querySelector?.("[data-contents='true']");
      let additionalPadding = 0;
      if (contentElem) {
        contentElem.addEventListener('paste', handlePaste);

        // if the first and/or last element in the editor is a list of some kind, add additional padding
        const firstChild = contentElem.firstElementChild;
        if (firstChild && (firstChild.tagName === 'UL' || firstChild.tagName === 'OL')) {
          additionalPadding += 15.75; // Amount of padding on the top of the list the editor does not count in the ref's height
        }

        const lastChild = contentElem.lastElementChild;
        if (lastChild && (lastChild.tagName === 'UL' || lastChild.tagName === 'OL')) {
          additionalPadding += 15.75; // Amount of padding on the bottom of the list the editor does not count in the ref's height
        }
      }

      setToolbarOffset(editorRef.current.offsetHeight + paddingOffset + additionalPadding);
    }
  };

  useEffect(() => {
    return () => {
      if (editorRef.current && typeof editorRef.current.removeEventListener === 'function') {
        editorRef.current.removeEventListener('paste', handlePaste);
      }
    };
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      setToolbarOffset(editorRef.current.offsetHeight + paddingOffset);
    }
  }, [paddingOffset]);

  // For snapshotting the editor state prior to triggering autocomplete.
  const [selectionStateSnapshot, setSelectionStateSnapshot] = useState(null);
  const [contentStateSnapshot, setContentStateSnapshot] = useState(null);

  // Snapshot editor state, then show suggestions.
  const triggerAutoComplete = (selectionState, contentState) => {
    setSelectionStateSnapshot(selectionState);
    setContentStateSnapshot(contentState);
    setShowSuggestions(true);
    setSearchQuery('');
  };

  // Hide suggestions and clear editor state snapshot.
  const hideAutoComplete = () => {
    setSelectionStateSnapshot(null);
    setContentStateSnapshot(null);
    setShowSuggestions(false);
    setSearchQuery('');
  };

  // Calculate the difference between two strings. This is used to determine
  // the search query based on the difference between the current editor state
  // and the editor state snapshot.
  const diffText = (snapshot, current) => {
    let diff = '';
    let i = 0;
    let j = 0;

    while (i < snapshot.length || j < current.length) {
      if (snapshot[i] !== current[j]) {
        while (current[j] && snapshot[i] !== current[j]) {
          diff += current[j];
          j++;
        }
      } else {
        i++;
        j++;
      }
    }

    return diff;
  };

  // Decorate editor state updates with autocomplete behavior.
  const onEditorStateChangeAutocomplete = (editorState) => {
    if (showSuggestions) {
      switch (editorState.getLastChangeType()) {
        // Ignore in favor of onAutoCompleteSelect behavior.
        case 'split-block': {
          return;
        }

        // Update search query based on editor state.
        case 'insert-characters': {
          const snapshotText = contentStateSnapshot.getPlainText();
          const currentText = editorState.getCurrentContent().getPlainText();
          setSearchQuery(diffText(snapshotText, currentText));
          break;
        }

        // Cancel autocomplete if any characters are removed.
        case 'backspace-character':
        case 'delete-character': {
          hideAutoComplete();
          break;
        }
      }
    }

    // Apply default behavior.
    setEditorState(editorState);

    if (!showSuggestions) {
      // Extract relevant editor state.
      const selection = editorState.getSelection();
      const anchorOffset = selection.getAnchorOffset();
      const anchorKey = selection.getAnchorKey();
      const contentState = editorState.getCurrentContent();
      const contentBlock = contentState.getBlockForKey(anchorKey);
      const contentBlockText = contentBlock.getText();

      // Check trigger conditions.
      const isCursorAfterTrigger =
        contentBlockText.substring(anchorOffset - triggerPattern.length, anchorOffset) === triggerPattern;
      const nextTriggerIndex = contentBlockText.indexOf(triggerPattern, anchorOffset);
      const closingTagIndex = contentBlockText.indexOf(closingPattern, anchorOffset);
      const hasNextTrigger = nextTriggerIndex !== -1;
      const hasClosingTag = closingTagIndex !== -1;
      const isClosingTagAfterNextTrigger = hasNextTrigger && nextTriggerIndex < closingTagIndex;

      if (isCursorAfterTrigger && (!hasClosingTag || isClosingTagAfterNextTrigger)) {
        triggerAutoComplete(editorState.getSelection(), contentState);
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const rect = range.getClientRects()[0];
          const divRect = editorRef.current.getBoundingClientRect();
          const lineHeight = rect.bottom - rect.top + 2; // 2px padding
          if (rect) {
            setCaretPosition({
              x: rect.x - divRect.x,
              y: rect.y - divRect.y - divRect.height + lineHeight,
            });
          }
        }
      }
    }

    const descJSON = convertToRaw(editorState.getCurrentContent());
    updateEditor(JSON.stringify(descJSON));
    !isPreviewed && updateTextHTML?.(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const onUpdateLink = (action = 'add') => {
    const descJSON = convertToRaw(editorState.getCurrentContent());
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const offset = selection.focusOffset;
    const anchorOffset = selection.anchorOffset;
    const block = descJSON.blocks.filter((textBlock) => textBlock.key === anchorKey)?.[0];
    if ((anchorOffset !== 0 && !anchorOffset) || (offset !== 0 && !offset)) {
      API.defaultError('Please select the text you would like to link.');
    }
    let selectedEntityRange = block.entityRanges.filter(
      (range) => range.offset <= offset && range.offset + range.length >= anchorOffset,
    )?.[0];
    if (!selectedEntityRange) {
      const newEntityRange = {
        offset: anchorOffset,
        length: offset - anchorOffset,
        key: block.entityRanges.length,
      };
      block.entityRanges.push(newEntityRange);
      selectedEntityRange = newEntityRange;
    }

    if (action === 'clear') {
      delete descJSON.entityMap[selectedEntityRange.key];
    } else {
      if (attachmentType.value === 'url') {
        let formattedUrl = url;
        if (url.indexOf('http') === -1 && url.indexOf('{{') === -1) {
          formattedUrl = 'http://' + formattedUrl;
        }
        descJSON.entityMap[selectedEntityRange.key] = {
          type: 'LINK',
          mutability: 'MUTABLE',
          data: { url: formattedUrl, targetOption: linkTarget },
        };
      } else if (attachmentType.value === 'template') {
        descJSON.entityMap[selectedEntityRange.key] = {
          type: 'LINK',
          mutability: 'MUTABLE',
          data: {
            url: `template_${attachmentTemplate.value}`,
            targetOption: linkTarget,
            template: attachmentTemplate.value,
          },
        };
      }
    }

    updateEditor(JSON.stringify(descJSON));
    !isPreviewed && updateTextHTML?.(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    popupMenuRef.current.close();
  };

  const onEditorStateChange = (editorState) => {
    const descJSON = utils.removeCopiedFontStylesFromWYSIWYGOutput(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
    updateEditor(JSON.stringify(descJSON));
    !isPreviewed && updateTextHTML?.(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Insert selected suggestion into editor.
  const onAutoCompleteSelect = (value) => {
    if (value) {
      const autoCompletionText = value.substring(2);
      const contentState = Modifier.insertText(
        contentStateSnapshot,
        selectionStateSnapshot,
        autoCompletionText,
        editorState.getCurrentInlineStyle(),
      );
      setEditorState(EditorState.push(editorState, contentState, 'insert-characters'));
    }
    hideAutoComplete();
  };

  if (!editorState) {
    return <div>Loading...</div>;
  }

  const editorStyle = {
    disabled: {},
    toolbar: {
      borderColor: '#e0e5ee',
    },
  };

  const editorStyleFloatingToolbar = {
    toolbar: {
      background: '#ffffff',
      borderColor: '#e0e5ee',
      position: 'absolute',
      bottom: `${toolbarOffset}px`,
      left: '-2px',
      zIndex: '100',
    },
  };

  const editorStyleAutoResize = {
    editor: {
      border: '0',
      overflow: 'hidden',
      resize: 'none',
      height: 'auto',
      minHeight: '0px',
    },
  };

  if (readOnly) {
    editorStyle.disabled = {
      backgroundColor: 'whitesmoke',
      borderColor: 'whitesmoke',
      boxShadow: 'none',
      color: '#7a7a7a',
      cursor: 'not-allowed',
    };
  }

  const popupMenuRef = useRef();
  const popupMenuAnchorRef = useRef();
  const linkButton = (
    <LinkButton
      key={'link'}
      ref={popupMenuAnchorRef}
      onClick={() => {
        popupMenuRef.current.toggle();
      }}
      action={'add'}
    />
  );
  const clearLinkButton = (
    <LinkButton
      key={'unlink'}
      ref={popupMenuAnchorRef}
      onClick={() => {
        onUpdateLink('clear');
      }}
      action={'clear'}
    />
  );
  let customButtons = [];
  if (enableAttachmentLinks && inVisualBuilder) {
    toolOptions.options = toolOptions.options.filter((option) => option !== 'link');
    customButtons = [linkButton, clearLinkButton];
  }

  return (
    <>
      <Editor
        editorKey={'editor'}
        toolbarHidden={showSuggestions || toolbarHidden}
        editorClassName={autoResize ? 'wysiwyg-textarea' : 'textarea'}
        editorRef={setEditorReference}
        editorStyle={autoResize ? editorStyleAutoResize.editor : editorStyle.disabled}
        toolbarStyle={floatingToolbar ? editorStyleFloatingToolbar.toolbar : editorStyle.toolbar}
        onEditorStateChange={suggestions ? onEditorStateChangeAutocomplete : onEditorStateChange}
        editorState={editorState}
        toolbar={toolOptions}
        placeholder={placeholder}
        ariaLabel={ariaLabel}
        readOnly={readOnly}
        toolbarCustomButtons={customButtons}
      />
      {showAutoComplete && suggestions && showSuggestions && (
        <AutoCompleteSuggestions
          suggestions={suggestions}
          searchQuery={searchQuery}
          onClose={hideAutoComplete}
          onSelect={onAutoCompleteSelect}
          coordinates={caretPosition}
        />
      )}
      {inVisualBuilder && (
        <LinkPopup
          setAttachmentType={setAttachmentType}
          popupMenuRef={popupMenuRef}
          popupMenuAnchorRef={popupMenuAnchorRef}
          attachmentType={attachmentType}
          attachmentTemplate={attachmentTemplate}
          setAttachmentTemplate={setAttachmentTemplate}
          url={url}
          setUrl={setUrl}
          onUpdateLink={onUpdateLink}
          editorState={editorState}
          linkTarget={linkTarget}
          setLinkTarget={setLinkTarget}
        />
      )}
    </>
  );
}

WYSIWYGEditor.propTypes = {
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  updateEditor: PropTypes.func,
  updateTextHTML: PropTypes.func,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  toolbarHidden: PropTypes.bool,
  floatingToolbar: PropTypes.bool,
  autoResize: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  triggerPattern: PropTypes.string,
  closingPattern: PropTypes.string,
  toolOptions: PropTypes.object,
  showAutoComplete: PropTypes.bool,
  paddingOffset: PropTypes.number,
  isPreviewed: PropTypes.bool,
  inVisualBuilder: PropTypes.bool,
};

WYSIWYGEditor.defaultProps = {
  toolOptions: defaultToolOptions,
};

export default WYSIWYGEditor;

const LinkButton = React.forwardRef(({ onClick, action }, ref) => {
  return (
    <div className="rdw-link-wrapper" ref={ref} onClick={onClick}>
      <div className="rdw-option-wrapper wysiwyg-button">
        <img src={action === 'add' ? link_icon : unlink_icon} />
      </div>
    </div>
  );
});

LinkButton.displayName = 'LinkButton';
LinkButton.propTypes = {
  onClick: PropTypes.func,
  action: PropTypes.string,
};
