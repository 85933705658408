import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { UserContext } from 'components/UserContext';
import Constants from 'components/Constants';
import useAccesses from 'lib/hooks/useAccess';
import AccessManager from 'lib/AccessManager';

function isGenerationComplete(presentationStatus) {
  return ['done', 'error', 'failed condition'].includes(presentationStatus);
}

const presentations = {
  getRegenerationTooltipMessage: (presentation, canRead) => {
    let regenerationTooltipMessage = '';
    if (!presentation.template || isEmpty(presentation.template) || presentation.template.deleted) {
      regenerationTooltipMessage = 'Template no longer exists, cannot regenerate';
    } else if (!canRead) {
      regenerationTooltipMessage = 'You do not have access to this presentation';
    } else if (!isGenerationComplete(presentation.status)) {
      regenerationTooltipMessage = 'This presentation is still generating';
    }
    return regenerationTooltipMessage;
  },
  isGenerationComplete,
};

export default presentations;

export function useCanEditPresentation(presentationId) {
  const { isPending, data } = useAccesses(Constants.ItemTypes.PRESENTATION, [presentationId]);
  const { user } = useContext(UserContext);

  let canEdit = false;
  if (!isPending && data) {
    const accessManager = new AccessManager(presentationId, data, user);
    canEdit = accessManager.can(Constants.PERMISSIONS.edit.value);
  }
  return {
    isPending,
    canEdit,
  };
}
