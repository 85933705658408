import React, { Component } from 'react';
import { Heading } from 'react-bulma-components';

class MatikAuthPlug extends Component {
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const flow = urlParams.get('flow');
    let headline;
    let backgroundImageClassName = 'default-signup-background';
    switch (flow) {
      case 'eoy_email':
        headline = <>Generate an EOY Email Template in Minutes</>;
        break;
      case 'qbr_one_pager':
        headline = <>Generate a QBR One-Pager Template in Minutes</>;
        backgroundImageClassName = 'qbr-one-pager-signup-background';
        break;
      case 'qbr_multi':
        headline = <>Generate a QBR Deck Template in Minutes</>;
        break;
      case 'renewal_multi':
        headline = <>Generate a Renewal Deck Template in Minutes</>;
        break;
      case 'expansion_multi':
        headline = <>Generate an Expansion Deck Template in Minutes</>;
        break;
      default:
        headline = (
          <>
            Automate the
            <br /> creation of
            <br /> data-driven content
          </>
        );
    }
    return (
      <div className={`matik-auth-plug ${backgroundImageClassName}`}>
        <Heading size={2} className="matik-signup-text">
          {headline}
        </Heading>
        <div className="footer-links">
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://matik.io/privacy/">
            Privacy
          </a>
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://matik.io/product/">
            About
          </a>
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://blog.matik.io/">
            Blog
          </a>
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://matik.io/terms/">
            Terms & Conditions
          </a>
        </div>
      </div>
    );
  }
}

export default MatikAuthPlug;
