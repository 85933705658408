import React, { useState } from 'react';
import { Form, Box } from 'react-bulma-components';
import PropTypes from 'prop-types';

import { ReactComponent as Key } from 'images/key.svg';
import { ReactComponent as KeyMultiple } from 'images/key_multiple.svg';
import AuthTabs from '../AuthTabs';

/**
 * CertificateAuth is an authentication form that allows admins to upload a
 * PEM-encoded X.509 certificate as a method of authenticating an Entra ID
 * service principal to access Power BI resources.
 */
function CertificateAuth(props) {
  const certificate = props.auth?.x509_certificate ?? '';
  const privateKey = props.auth?.private_key ?? '';
  const privateKeyPassword = props.auth?.private_key_password ?? '';

  const placeholder = (keyType) => {
    return [
      `-----BEGIN ${keyType}-----`,
      'VW5mb3J0dW5hdGVseSwgdGhlcmUgYXJlIG5vIHNlY3JldHMgdG8gc2VlIGhlcmUu',
      'ClRoaXMgaXMgbm90IGEgcmVhbCBwcml2YXRlIGtleS4=',
      `-----END ${keyType}-----`,
    ].join('\n');
  };

  const handleClearCertificate = (e) => {
    e.preventDefault();
    props.clearCertificate();
  };

  const handleClearPrivateKey = (e) => {
    e.preventDefault();
    props.clearPrivateKey();
  };

  return (
    <Box className="data-source-box">
      <Form.Field>
        <Form.Label htmlFor="x509-certificate">X.509 Certificate</Form.Label>
        <Form.Help>A PEM-encoded X.509 certificate used to authenticate the service principal.</Form.Help>
        {certificate && (
          <>
            <span className="has-text-success prs">Certificate Added</span>
            <a href="/#" onClick={handleClearCertificate} className="is-text">
              Change
            </a>
          </>
        )}
        <Form.Control>
          {!certificate && (
            <Form.Textarea
              id="x509-certificate"
              data-testid="x509-certificate"
              name="x509_certificate"
              className="font-mono"
              value={certificate}
              onChange={props.onAuthChange}
              placeholder={placeholder('CERTIFICATE')}
              autoComplete="off"
            />
          )}
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="private-key">Private Key</Form.Label>
        <Form.Help>
          A PEM-encoded private key associated with the X.509 certificate. We will never expose your private key once it
          has been added.
        </Form.Help>
        {privateKey && (
          <>
            <span className="has-text-success prs">Private Key Added</span>
            <a href="/#" onClick={handleClearPrivateKey} className="is-text">
              Change
            </a>
          </>
        )}
        <Form.Control>
          {!privateKey && (
            <>
              <Form.Textarea
                id="private-key"
                data-testid="private-key"
                name="private_key"
                className="font-mono"
                value={privateKey}
                onChange={props.onAuthChange}
                placeholder={placeholder('PRIVATE KEY')}
                autoComplete="off"
              />
            </>
          )}
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="private-key-password">Private Key Password (Optional)</Form.Label>
        <Form.Help>
          The password used to decrypt the PEM-encoded private key. This is only needed if the private key is encrypted.
        </Form.Help>
        <Form.Control>
          <Form.Input
            id="private-key-password"
            data-testid="private-key-password"
            name="private_key_password"
            value={privateKeyPassword}
            onChange={props.onAuthChange}
            type="password"
            autoComplete="off"
          />
        </Form.Control>
      </Form.Field>
    </Box>
  );
}

CertificateAuth.propTypes = {
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
  clearCertificate: PropTypes.func,
  clearPrivateKey: PropTypes.func,
};

/**
 * ApplicationSecretAuth is an authentication form that allows admins to specify
 * an application secret (password) as a method of authenticating an Entra ID
 * service principal to access Power BI resources.
 */
function ApplicationSecretAuth(props) {
  const applicationSecret = props.auth?.application_secret ?? '';

  return (
    <Box className="data-source-box">
      <Form.Field>
        <Form.Label htmlFor="application-secret">Application Secret</Form.Label>
        <Form.Help>An application secret used to authenticate the service principal.</Form.Help>
        <Form.Control>
          <Form.Input
            id="application-secret"
            data-testid="application-secret"
            name="application_secret"
            value={applicationSecret}
            onChange={props.onAuthChange}
            placeholder="Created in Microsoft Entra → App registrations → Certificates & secrets → Client secrets"
            type="password"
            autoComplete="off"
          />
        </Form.Control>
      </Form.Field>
    </Box>
  );
}

ApplicationSecretAuth.propTypes = {
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
};

/**
 * PowerBIAuth is an authentication form that allows admins to specify either
 * an X.509 certificate or an application secret (password) as a method of
 * authenticating an Entra ID service principal to access Power BI resources.
 */
function PowerBIAuth(props) {
  const { auth, onAuthChange } = props;
  const [activeAuthTab, setActiveAuthTab] = useState('certificate-tab');

  const authTabs = [
    {
      name: 'certificate-tab',
      title: 'Access via X.509 Certificate',
      TabIcon: KeyMultiple,
    },
    {
      name: 'application-secret-tab',
      title: 'Access via Application Secret',
      isOptional: true,
      TabIcon: Key,
    },
  ];

  const clearCertificate = () => {
    const updatedAuth = { ...auth };
    updatedAuth.x509_certificate = '';
    onAuthChange({ target: { name: 'x509_certificate', value: '' } });
  };

  const clearPrivateKey = () => {
    const updatedAuth = { ...auth };
    updatedAuth.private_key = '';
    onAuthChange({ target: { name: 'private_key', value: '' } });
  };

  return (
    <React.Fragment>
      <AuthTabs
        tabs={authTabs}
        activeAuthTab={activeAuthTab}
        setActiveAuthTab={setActiveAuthTab}
        centered={props.centered}
      />
      {activeAuthTab === 'certificate-tab' && (
        <CertificateAuth
          auth={auth}
          onAuthChange={onAuthChange}
          clearCertificate={clearCertificate}
          clearPrivateKey={clearPrivateKey}
        />
      )}
      {activeAuthTab === 'application-secret-tab' && <ApplicationSecretAuth auth={auth} onAuthChange={onAuthChange} />}
    </React.Fragment>
  );
}

PowerBIAuth.propTypes = {
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
  centered: PropTypes.bool,
};

export { CertificateAuth, ApplicationSecretAuth, PowerBIAuth };

export default PowerBIAuth;
