import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';

import animationData1 from 'lottie/preloader-01';
import animationData2 from 'lottie/preloader-02';
import animationData3 from 'lottie/preloader-03';
import animationData4 from 'lottie/preloader-04';

import animationData1Dark from 'lottie/preloader-01-dark';
import animationData2Dark from 'lottie/preloader-02-dark';
import animationData3Dark from 'lottie/preloader-03-dark';
import animationData4Dark from 'lottie/preloader-04-dark';

function GenerationStatusAnimation({ status, theme = 'light' }) {
  let animation = null;
  if (status) {
    switch (status) {
      case 'processing':
        animation = theme == 'dark' ? animationData1Dark : animationData1;
        break;
      case 'copy':
        animation = theme == 'dark' ? animationData2Dark : animationData2;
        break;
      case 'custom':
        animation = theme == 'dark' ? animationData3Dark : animationData3;
        break;
      case 'google':
        animation = theme == 'dark' ? animationData4Dark : animationData4;
        break;
      default:
        animation = theme == 'dark' ? animationData1Dark : animationData1;
        break;
    }
  }
  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMin',
      runExpressions: false,
    },
  };

  return <Lottie options={defaultOptions} height={400} width={400} />;
}
GenerationStatusAnimation.propTypes = {
  status: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default GenerationStatusAnimation;
