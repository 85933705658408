import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';
import { UserContext } from 'components/UserContext';
import Banner, { toastBanner } from 'components/shared/Banner';
import Constants from 'components/Constants';
import { usePresentationMutator } from 'lib/hooks/usePresentation';
import GenerationStatusAnimation from 'components/shared/presentations/GenerationStatusAnimation';

function getDisplayableNarrativeType(type) {
  if (Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(type)) {
    return 'document';
  }
  if (Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(type)) {
    return 'spreadsheet';
  }
  if (Constants.TEMPLATE_SOURCE_TYPES.PRESENTATION_TYPES.includes(type)) {
    return 'presentation';
  }
  if (type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
    return 'presentation';
  }
  return 'item';
}

function GenerationStatusViewer({ presentation }) {
  const { user } = useContext(UserContext);

  const isNotifyUser = !!presentation.notify_when_complete?.includes(user.id);
  const [checked, setChecked] = useState(isNotifyUser);
  const { updateNotifyWhenComplete } = usePresentationMutator();

  // If the presentation data changes, use that as the canonical value
  useEffect(() => {
    setChecked(isNotifyUser);
  }, [isNotifyUser]);

  const handleNotifyChange = (evt) => {
    const doNotify = evt.target.checked;

    // Optimistically set the checked state
    setChecked(doNotify);

    // update the presentation notify_when_complete
    updateNotifyWhenComplete(presentation.id, doNotify)
      .then(() => {
        if (doNotify) {
          message = `You will notified by email when your ${getDisplayableNarrativeType(
            presentation.presentation_type,
          )} is ready. Feel free to navigate away.`;
        } else {
          message = `We will no longer notify you when your ${getDisplayableNarrativeType(
            presentation.presentation_type,
          )} is ready.`;
        }
        toastBanner(<Banner bannerType="success" text={message} />);
      })
      .catch((err) => {
        setChecked(checked);
        toastBanner(
          <Banner
            bannerType="error"
            text="There was a problem updating your notification preference."
            sublineText={err.response.data?.message || err.response.status}
          />,
        );
      });
    let message;
  };

  return (
    <div className="!pt-6 flex flex-col items-center">
      <GenerationStatusAnimation status={presentation.status} theme="dark" />
      <div>
        <CheckboxWithLabel
          id="notify"
          checked={checked}
          onChange={handleNotifyChange}
          label="Notify me when it's ready."
        />
      </div>
    </div>
  );
}

GenerationStatusViewer.propTypes = {
  presentation: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    notify_when_complete: PropTypes.arrayOf(PropTypes.number),
    presentation_type: PropTypes.string,
  }).isRequired,
};

export default GenerationStatusViewer;
