import API from 'lib/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Constants from 'components/Constants';
import LongRequest from 'lib/longRequest';

export const useApiInfo = (dataSource = {}, resourceName = null, resourceId = null) => {
  // TODO(Matt): Add subResourceName to params for searching

  const queryClient = useQueryClient();

  const queryKey = ['api_info', dataSource.id, { resource_name: resourceName, resource_id: resourceId }];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      let doFetchPromise;
      if (dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce) {
        doFetchPromise = getSalesforceDataCount(resourceName, dataSource.id).then(
          (salesforceDataCount) => salesforceDataCount < Constants.MAX_SALESFORCE_REPORTS,
        );
      } else {
        doFetchPromise = Promise.resolve(true);
      }
      return doFetchPromise.then((doIt) => {
        if (doIt) {
          return API.get(
            buildFetchUrl(dataSource.id, resourceName, resourceId),
            (result) => result.data,
            (err) => {
              API.defaultError(err);
            },
          );
        } else {
          return {};
        }
      });
    },
    enabled: !!dataSource.id,
  });

  const invalidate = (id) => {
    const queryKey = ['api_info'];
    if (id ?? dataSource.id) {
      queryKey.push(id ?? dataSource.id);
    }
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    isPending: isLoading,
    isError,
    data,
    error,
    invalidate,
  };
};

const buildFetchUrl = (dataSourceId, resourceName, resourceId) => {
  let url = `/data_sources/${dataSourceId}/info/`;
  if (resourceName) {
    url += `?resource_name=${resourceName}`;
    if (resourceId) {
      url += `&resource_id=${resourceId}`;
    }
  }

  return url;
};

const getSalesforceDataCount = (resourceName, dataSourceId) => {
  return API.get(
    `/data_sources/${dataSourceId}/count/${resourceName}/`,
    (result) => result.data,
    (err) => {
      API.defaultError(err);
    },
  );
};

export const useTableauSheetFields = (dataSourceId, queryObj, enableRestApiTableauReturnFields = false) => {
  const workSheetId = queryObj?.selectedSheet?.value;

  const getSheetFields = async (dataSourceId, queryObj) => {
    if (enableRestApiTableauReturnFields) {
      const updatedQueryObj = Object.assign({}, queryObj);
      updatedQueryObj.query = { fields: [], filters: [], limit: '0' };
      updatedQueryObj.returnFieldsByName = {};
      const query = JSON.stringify(updatedQueryObj);
      const queryData = {
        data_source: { id: dataSourceId },
        dynamic_content_type: 'text',
        query_string: query,
      };
      return await new Promise((resolve, reject) => {
        const onResponse = (response, onComplete) => {
          if (response.data.status === 'success' && response.data.result.length) {
            onComplete();
            resolve(response.data.result[0]);
          } else if (response.data.status === 'error') {
            onComplete();
            reject();
          }
        };
        const onError = (e) => reject(e);
        const longRequest = new LongRequest('/queries');
        longRequest.post(queryData, onResponse, onError, undefined, undefined, 'test/tableau');
      });
    } else {
      return API.get(
        `/data_sources/${dataSourceId}/tableau/view_fields/${workSheetId}/`,
        (result) => {
          const sheetFieldInstances =
            result.data.sheets.find((sheet) => sheet.luid === workSheetId)?.sheetFieldInstances ?? [];
          return sheetFieldInstances.map((field) => field.name);
        },
        API.defaultError,
      );
    }
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['sheet_fields', parseInt(dataSourceId), workSheetId, enableRestApiTableauReturnFields],
    queryFn: () => {
      return getSheetFields(dataSourceId, queryObj);
    },
    enabled: !!workSheetId && !isNaN(dataSourceId),
  });

  return {
    isPending: isLoading,
    isError,
    error,
    data,
  };
};
