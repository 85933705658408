import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TemplateSlide from 'components/consumer/TemplateSlide';
import { useTemplatesThumbnails } from 'lib/hooks/useTemplate';
import SlideViewToggle from 'components/shared/templates/SlideViewToggle';

const useThumbnails = (template, slides) => {
  const templateIds = new Set();
  if (template?.id) {
    templateIds.add(template.id);
  }
  // We also need the thumbnails for any ad-hoc library slide additions
  slides?.filter((s) => s.isAdded)?.forEach((s) => templateIds.add(s.template.id));

  const resultsByTemplateId = useTemplatesThumbnails([...templateIds]);
  const thumbnails = {};
  Object.values(resultsByTemplateId)
    .map((result) => result.data)
    .filter((data) => !!data)
    .forEach((data) => {
      for (const slideId in data) {
        thumbnails[slideId] = data[slideId];
      }
    });
  return thumbnails;
};

function TemplateSlides({ currentTemplate, slides, status, inputs }) {
  const thumbnails = useThumbnails(currentTemplate, slides);
  const [view, setView] = useState('grid');
  const [currentSlideId, setCurrentSlideId] = useState(null);

  const handleShowSingleSlide = (slide) => {
    setCurrentSlideId(slide.id);
    setView('single');
  };

  return (
    <div className="h-full">
      {view === 'grid' ? (
        <SlideGrid
          slides={slides}
          thumbnails={thumbnails}
          currentTemplate={currentTemplate}
          inputs={inputs}
          status={status}
          onShowSingleSlide={handleShowSingleSlide}
        />
      ) : (
        <SingleSlide
          currentSlideId={currentSlideId}
          onChangeCurrentSlide={(slide) => setCurrentSlideId(slide.id)}
          slides={slides}
          thumbnails={thumbnails}
          currentTemplate={currentTemplate}
          status={status}
          inputs={inputs}
        />
      )}
      <SlideViewToggle view={view} onChange={setView} />
    </div>
  );
}
TemplateSlides.propTypes = {
  status: PropTypes.object,
  currentTemplate: PropTypes.object,
  slides: PropTypes.array,
  inputs: PropTypes.object,
};

export default TemplateSlides;

function SlideGrid({ slides, thumbnails, currentTemplate, inputs, status, onShowSingleSlide }) {
  return (
    <div className="slides-wrapper pbxl">
      <div className="template-grid">
        {slides?.map((slide, idx) => {
          const thumbData = thumbnails?.[parseInt(slide.id)];
          const slideFetchStatus = thumbData && {
            data: thumbData,
            fetchState: 'fetched',
            errorMessage: '',
          };

          return (
            <div key={`${slide.id}_${idx}`} onDoubleClick={() => onShowSingleSlide(slide)}>
              <TemplateSlide
                currentTemplate={currentTemplate}
                isLast={idx === slides.length - 1}
                slideFetchStatus={slideFetchStatus}
                slideNum={idx + 1}
                slide={slide}
                status={status}
                inputs={inputs}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
SlideGrid.displayName = 'TemplateSlides.SlideGrid';
SlideGrid.propTypes = {
  status: PropTypes.object,
  currentTemplate: PropTypes.object,
  slides: PropTypes.array,
  inputs: PropTypes.object,
  thumbnails: PropTypes.object,
  onShowSingleSlide: PropTypes.func,
};

function SingleSlide({ slides, thumbnails, currentTemplate, currentSlideId, onChangeCurrentSlide, status, inputs }) {
  const viewingSlideIndex = slides?.findIndex((slide) => slide.id === currentSlideId);
  const viewingSlide = viewingSlideIndex > -1 ? slides[viewingSlideIndex] : slides?.[0];
  const thumbData = thumbnails?.[parseInt(viewingSlide.id)];

  return (
    <div className="flex flex-col h-full gap-6">
      <div className="grow relative">
        <img
          draggable="false"
          className="!pt-6 px-12 w-full h-full object-contain absolute"
          src={thumbData?.url}
          alt={'Slide'}
        />
      </div>
      <ScrollingContainer
        itemWidth={210}
        scrollToItemIndex={viewingSlideIndex}
        className="flex h-[164px] bg-matik-white pt-1.5 pr-4 mr-[34px] border-t border-grey-300"
      >
        {slides?.map((slide, idx) => {
          const thumbData = thumbnails?.[parseInt(slide.id)];
          const slideFetchStatus = thumbData && {
            data: thumbData,
            fetchState: 'fetched',
            errorMessage: '',
          };

          return (
            <div
              key={`${slide.id}_${idx}`}
              onClick={() => onChangeCurrentSlide(slide)}
              className="flex flex-col items-center shrink-0 pt-2.5"
            >
              <div className="w-[218px] h-[110px] shrink-0 overflow-hidden -ml-2">
                <div className="transform origin-top-left scale-[.7]">
                  <div className="w-[300px]">
                    <TemplateSlide
                      currentTemplate={currentTemplate}
                      isLast={idx === slides.length - 1}
                      slideFetchStatus={slideFetchStatus}
                      slideNum={idx + 1}
                      slide={slide}
                      status={status}
                      inputs={inputs}
                    />
                  </div>
                </div>
              </div>
              <div>{idx + 1}</div>
            </div>
          );
        })}
      </ScrollingContainer>
    </div>
  );
}
SingleSlide.displayName = 'TemplateSlides.SingleSlide';
SingleSlide.propTypes = {
  currentTemplate: PropTypes.object,
  slides: PropTypes.array,
  status: PropTypes.object,
  inputs: PropTypes.object,
  thumbnails: PropTypes.object,
  currentSlideId: PropTypes.number,
  onChangeCurrentSlide: PropTypes.func,
};

function ScrollingContainer({ className, itemWidth, scrollToItemIndex, children }) {
  const dragStartRef = useRef();
  const sliderRef = useRef();

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider && scrollToItemIndex > -1) {
      const newLeft = itemWidth * scrollToItemIndex - itemWidth * 2;
      slider.scrollTo({ left: newLeft, behavior: Math.abs(slider.scrollLeft - newLeft) > 700 ? 'instant' : 'smooth' });
    }
  }, [scrollToItemIndex]);

  const startDrag = (e) => {
    dragStartRef.current = { pageX: e.pageX, scrollLeft: sliderRef.current.scrollLeft };
  };

  const stopDrag = () => {
    dragStartRef.current = null;
  };

  const onMouseMove = (e) => {
    const dragStart = dragStartRef.current;
    if (dragStart) {
      sliderRef.current.scrollLeft = dragStart.scrollLeft - (e.pageX - dragStart.pageX);
    }
  };

  return (
    <div
      className={`${className || ''} overflow-x-scroll`}
      ref={sliderRef}
      onMouseDown={startDrag}
      onMouseUp={stopDrag}
      onMouseLeave={stopDrag}
      onMouseMove={onMouseMove}
    >
      {children}
    </div>
  );
}
ScrollingContainer.displayName = 'TemplateSlides.ScrollingContainer';
ScrollingContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  itemWidth: PropTypes.number,
  scrollToItemIndex: PropTypes.number,
};
