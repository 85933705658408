import React from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/lib/ListItem';
import Constants from 'components/Constants';
import IconPill from 'components/lib/IconPill';

function AttachedTemplatesList({ attachedTemplate, emptyContent }) {
  if (!attachedTemplate) {
    return <div className="component-params">{emptyContent}</div>;
  }

  const icon = (
    <div className="flex items-center gap-1">
      <>
        <SourceIcon sourceType={attachedTemplate.source_type} />
        <TypeIcon sourceType={attachedTemplate.source_type} />
      </>
    </div>
  );

  return (
    <div className="space-y-2 dynamic-content-sidebar">
      <div className="py-3 px-4 bg-matik-white hover:bg-grey-50 border border-grey-300 rounded flex flex-col gap-2.5">
        <ListItem icon={icon} title={attachedTemplate.name} />
      </div>
    </div>
  );
}

AttachedTemplatesList.propTypes = {
  attachedTemplate: PropTypes.object,
  emptyContent: PropTypes.node,
};

export default AttachedTemplatesList;

export function SourceIcon({ sourceType }) {
  return (
    <IconPill
      iconName={Constants.TemplateSourceTypesDisplay[sourceType.toUpperCase()].iconName}
      size="s"
      theme="square"
      luminosity="medium"
      color="white"
      text={Constants.TemplateSourceTypesDisplay[sourceType.toUpperCase()].text}
    />
  );
}

SourceIcon.propTypes = {
  sourceType: PropTypes.string,
};

export function TypeIcon({ sourceType }) {
  const typeIcon = Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(sourceType)
    ? 'document'
    : Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(sourceType)
    ? 'table'
    : 'template';

  const typeText = Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(sourceType)
    ? 'Document'
    : Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(sourceType)
    ? 'Spreadsheet'
    : 'Presentation';

  if (typeIcon) {
    return <IconPill size="s" theme="square" luminosity="medium" color="white" iconName={typeIcon} text={typeText} />;
  }
}

TypeIcon.propTypes = {
  sourceType: PropTypes.string,
};
