import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../lib/api';
import default_profile_icon from '../../images/default_profile.svg';
import Button from '../lib/Button';

class PhotoWithUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
    };
  }

  render() {
    let photoUrl;
    if (!this.props.photoUrl) {
      photoUrl = default_profile_icon;
    } else if (this.props.photoUrl.startsWith('http')) {
      photoUrl = this.props.photoUrl;
    } else if (this.props.photoUrl) {
      photoUrl = `data:image;base64,${this.props.photoUrl}`;
    }
    return (
      <div className="photo-wrapper">
        <img src={photoUrl} alt="Enterprise logo" width="80px" />
        <div className="add-logo">
          <Button category="tertiary" noPadding={true} status={this.state.isUploading ? 'loading' : 'default'}>
            <label htmlFor="single" className="underline">
              {this.props.uploadText}
            </label>
            <input
              type="file"
              id="single"
              onChange={this.onSelectFile}
              style={{ display: 'none' }}
              accept="image/png, image/jpeg, image/gif, image/tiff"
            />
          </Button>
        </div>
      </div>
    );
  }

  onSelectFile = (e) => {
    const file = e.target.files[0];
    this.setState({ isUploading: true });
    API.post(this.props.uploadUrl, {}, this.onSuccess, this.onError, file);
  };

  onSuccess = (response) => {
    this.setState({ isUploading: false });
    this.props.onUpload(response.data);
  };

  onError = (err) => {
    this.setState({ isUploading: false });
    API.defaultError(err);
  };
}

PhotoWithUpload.propTypes = {
  onUpload: PropTypes.func,
  photoUrl: PropTypes.string,
  uploadText: PropTypes.string,
  uploadUrl: PropTypes.string,
};

export default PhotoWithUpload;
