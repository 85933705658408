import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableauForm from './TableauForm';

class TableauDynamicContentForm extends Component {
  render() {
    return (
      <TableauForm
        queryObj={this.props.queryObj}
        inputsInQueryString={this.props.inputsInQueryString}
        dataSource={this.props.dataSource}
        apiInfo={this.props.apiInfo}
      />
    );
  }
}

TableauDynamicContentForm.propTypes = {
  apiInfo: PropTypes.object,
  dataSource: PropTypes.object,
  fetchApiInfoIfNeeded: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  queryObj: PropTypes.object,
};

export default TableauDynamicContentForm;
